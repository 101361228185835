import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesSnackbar = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function ProjectRequests(props) {
  const mess1 = (
    <FormattedMessage
      id="admin.projectRequest.error1"
      defaultMessage="Successfully Submitted Decision"
    />
  );
  const mess2 = (
    <FormattedMessage
      id="admin.projectRequest.error2"
      defaultMessage="Internal Server Error."
    />
  );
  const mess3 = (
    <FormattedMessage
      id="admin.projectRequest.error3"
      defaultMessage="Unknown Error Occurred, Try again."
    />
  );
  const mess4 = (
    <FormattedMessage
      id="admin.projectRequest.mess4"
      defaultMessage="success"
    />
  );

  const classesSnackbar = useStylesSnackbar();
  let [isSaving, setIsSaving] = useState();
  let [isSaving1, setIsSaving1] = useState();

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  let [projects, setProjects] = useState([]);
  let getMingaProjectRequests = () => {
    axios
      .get(`/api/v1/mingaproject/pendingapprovalprojects`, {
        withCredentials: true,
      })
      .then((response) => {
        setProjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    props.setActiveTab({
      projectRequests: "active",
    });
    getMingaProjectRequests();
  }, []);

  // let selectedProject;
  // let projectDecision = (decision) => {
  //   if (decision === "Approved") {

  //   } else {
  //     handleShowSuccessModal();
  //   }
  // };

  let [selectedProject, setSelectedProject] = useState();

  let projectApprovalDecision = (id, decision) => {
    submitDecision(id, decision);
  };

  let projectRejectionDecision = (id, decision) => {
    setSelectedProject(id);
    handleShowSuccessModal();
  };

  let [snackbarController, setSnackbarController] = useState(false);
  let [snackbarSeverity, setSnackbarSeverity] = useState();
  let [snackbarMsg, setSnackbarMsg] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarController(false);
  };

  let [rejectedDescription, setRejectedDescription] = useState();
  let submitDecision = (id, decision) => {
    let data = {
      adminApproval: decision,
      rejectedDescription: rejectedDescription,
    };
    axios
      .put(`/api/v1/mingaproject/admindecision/${id}`, data, {
        withCredentials: true,
      })
      .then((response) => {
        getMingaProjectRequests();
        handleCloseSuccessModal();
        setSnackbarSeverity("success");
        // setSnackbarSeverity(mess4);

        // setSnackbarMsg("Successfully Submitted Decision");
        console.log(mess1);
        setSnackbarMsg(mess1);

        setSnackbarController(true);
      })
      .catch((error) => {
        setSnackbarSeverity("error");
        if (error.response !== undefined) {
          if (error.response.status === 500) {
            // setSnackbarMsg("Internal Server Error.");
            setSnackbarMsg(mess2);
          } else {
            // setSnackbarMsg("Unknown Error Occurred, Try again.");
            setSnackbarMsg(mess3);
          }
        } else {
          // setSnackbarMsg("Unknown Error Occurred, Try again.");
          setSnackbarMsg(mess3);
        }
        setSnackbarController(true);
      });
  };

  let locale = Cookies.get("Locale");
  return (
    <>
      <div className="card">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="index.html">
              <FormattedMessage
                id="admin.projectRequests.dashboardBreadcrumb"
                defaultMessage="Dashboard"
              />
            </a>
          </li>
          <li className="breadcrumb-item active">
            <FormattedMessage
              id="admin.projectRequests.projectRequestsBreadcrumb"
              defaultMessage="Project Requests"
            />
          </li>
        </ul>
        <div className="card-body">
          <h3>
            <FormattedMessage
              id="admin.projectRequests.heading"
              defaultMessage="Project Requests"
            />
          </h3>

          <div className="table-responsive" style={{ paddingTop: "20px" }}>
            <table className="table table-hover table-center mb-0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    <FormattedMessage
                      id="admin.projectRequests.projectName"
                      defaultMessage="Minga Name"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="admin.projectRequests.mingaCategory"
                      defaultMessage="Minga Category"
                    />
                  </th>

                  {/* <th>
                      <FormattedMessage
                        id="admin.projectRequests.gallery"
                        defaultMessage="Gallery"
                      />
                    </th> */}
                  {/* <th>
                      <FormattedMessage
                        id="admin.projectRequests.location"
                        defaultMessage="Location"
                      />
                    </th> */}
                  <th>
                    <FormattedMessage
                      id="admin.projectRequests.description"
                      defaultMessage="Description"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="admin.projectRequests.communityleader"
                      defaultMessage="Community Leader"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="admin.projectRequests.signatory1"
                      defaultMessage="Signatory 1"
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      id="admin.projectRequests.signatory2"
                      defaultMessage="Signatory 2"
                    />
                  </th>
                  <th className="text-right">
                    <FormattedMessage
                      id="admin.projectRequests.actions"
                      defaultMessage="Actions"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {projects.map((i, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{i.projectName}</td>
                    <td>
                      {i.category
                        ? locale === "en-US" ||
                          locale === "undefined" ||
                          locale === null
                          ? i.category.englishName
                          : i.category.spanishName
                        : "null"}
                    </td>
                    <td>{i.description}</td>
                    <td>
                      <div>
                        {i.communityleader.firstName}{" "}
                        {i.communityleader.lastName}
                        <br />
                        {i.communityleader.mobile}
                      </div>
                    </td>
                    <td>
                      <div>
                        {i.signatory1.firstName}
                        {""}
                        {i.signatory1.lastName}
                        <br />
                        {i.signatory1.mobile}
                      </div>
                    </td>
                    <td>
                      <div>
                        {i.signatory2.firstName}
                        {""}
                        {i.signatory2.lastName}
                        <br />
                        {i.signatory2.mobile}
                      </div>
                    </td>
                    <td className="text-right">
                      {isSaving ? (
                        <Spinner
                          animation="grow"
                          size="sm"
                          role="status"
                          style={{
                            color: "#a1c57e",
                            marginRight: "10px",
                          }}
                        >
                          <span className="sr-only">
                            <FormattedMessage
                              id="loading"
                              defaultMessage="Loading..."
                            />
                          </span>
                        </Spinner>
                      ) : (
                          <a
                            href="home.html"
                            onClick={(e) => {
                              e.preventDefault();
                              projectApprovalDecision(i._id, "Approved");
                            }}
                            className="btn btn-sm bg-success-light"
                          >
                            <i className="fe fe-trash"></i>
                            <FormattedMessage
                              id="mingaCoinRequest.approveButton"
                              defaultMessage="Approve"
                            />
                          </a>
                        )}
                      {isSaving1 ? (
                        <Spinner
                          animation="grow"
                          size="sm"
                          role="status"
                          style={{
                            color: "#e75157",
                            marginRight: "10px",
                            marginLeft: "10px",
                          }}
                        >
                          <span className="sr-only">
                          <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                        </Spinner>
                      ) : (
                          <a
                            href="home.html"
                            onClick={(e) => {
                              e.preventDefault();
                              projectRejectionDecision(i._id, "Rejected");
                            }}
                            className="btn btn-sm bg-danger-light"
                          >
                            <i className="fe fe-trash"></i>
                            <FormattedMessage
                              id="mimgaCoinRequest.rejectButton"
                              defaultMessage="Reject"
                            />
                          </a>
                        )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <br />
        </div>
      </div>
      <Snackbar
        open={snackbarController}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackbarSeverity}>
          {snackbarMsg}
        </Alert>
      </Snackbar>

      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage
              id="admin.projectRequests.rejectionDetails"
              defaultMessage="Rejection Details"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              submitDecision(selectedProject, "Rejected");
            }}
          >
            <label>
              <FormattedMessage
                id="admin.projectRequests.rejectionDescription"
                defaultMessage="Rejection Description"
              />
            </label>
            <textarea
              rows="5"
              required
              className="form-control"
              onChange={(e) => setRejectedDescription(e.target.value)}
            />
            <button type="submit" className="btn mt-5">
              <FormattedMessage
                id="admin.projectRequests.submitButton"
                defaultMessage="Submit"
              />
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectRequests;
