import React from "react";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import HomeCarousel from "./HomeCarousel";

function WhatIsMingaBlock2(props) {
  return (
    <section id="whatisminga" className="section home-block2-section">
      <div className="row no-gutters">
        <div className="col-12 col-md-12 col-lg-12 col-xl-6 home-block2-section-half1">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p className=" home-block1-title">
                  <FormattedMessage
                    id="home.block2.whatisaminga"
                    defaultMessage="What is a Minga ?"
                  />
                </p>
              </div>
            </div>
            <div className="row ">
              <div className="col-12">
                <p className="home-block2-description">
                  <FormattedMessage
                    id="homelock2.whatisamingadescription"
                    defaultMessage="A Minga is an ancient indigenous tradition that is a collaborative community project. Many communities rely almost exclusively on Minga projects to maintain infrastructure and social stability. With the Minga Project platform, we can use a digital Minga system to create digital bartering units called Mingas. With the use of Mingas Units, revolutionary transactions between people become possible without the use of money."
                  />
                </p>
                <br />
                <a href="#map-section">
                  <p
                    className="home-block1-description-signup"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    <FormattedMessage
                      id="home.block2.findminganearyou"
                      defaultMessage="What is a Minga ?"
                    />
                  </p>
                </a>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-12 col-lg-12 col-xl-6 home-block2-section-half2">
          <div className="container">
            <HomeCarousel
              carouselimages={props.carouselimages}
              local={props.local}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatIsMingaBlock2;
