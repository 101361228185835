import * as ActionTypes from '../ActionTypes/UserActionTypes';
import axios from 'axios';
// import { baseUrl } from '../shared/basedUrl';

export const initialState = {
  userLogoutLoading: false,
  userLogoutError: '',
}
// axios.get(baseUrl+'/admin/1/students/')
export const UserReducer = (state = initialState, action) => {
  // console.log("IN REDUCER ",action);
  if (action.type === ActionTypes.LOGOUT_USER_REQUEST) {
    return {
      ...state,
      userLogoutLoading: true
    }
  }
  else if (action.type === ActionTypes.LOGOUT_USER_SUCCESS) {
    return {
      ...state,
      userLogoutLoading: false,
      userLogoutError:""
    }
  }
  else if (action.type === ActionTypes.LOGOUT_USER_FAILURE) {
    // console.log("PAAYLOAD",action.payload);
    return {
      ...state,
      userLogoutLoading: false,
      userLogoutError: action.payload
    }
  }
  return state;
}