
import React from 'react';
import { Link } from 'react-router-dom';
// import onlineIcon from '../../icons/onlineIcon.png';
// import closeIcon from '../../icons/closeIcon.png';
import photo from "../../assets/img/MINGAS_LOGO1.png";

const InfoBar = ({ room }) => (
  <div className="infoBar">
    <div className="leftInnerContainer">
      <Link to="/dashboard/messages" >
        <i className="material-icons" style={{ color: 'white' }}>chevron_left</i>
      </Link>
      <div className="avatar avatar-online">
        <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
      </div>
      <div className="media-body">
        <div ><strong> Community Leader</strong></div>
        <div ><i>online</i></div>
      </div>
    </div>
  </div>
);

export default InfoBar;