import React from "react";
import DocumentMeta from "react-document-meta";

function MetaTag() {
  let meta = {};
  if (window.location.href.includes("proyectominga")) {
    meta = {
      title: `PROYECTO MINGA-Plataforma de trueque y servicio público`,
      description:
        "¡Regístrese hoy! El Proyecto Minga es una herramienta utilizada como intercambio de bienes y servicios cuando no hay dinero disponible. Gratis por un mes y luego $2/año",
      meta: {
        charset: "utf-8",
        name: {
          keywords: "react,meta,document,html,tags",
        },
      },
    };
  } else {
    meta = {
      title: `MINGA PROJECT-Bartering and public service platform`,
      description:
        "REGISTER Today! The Minga Project is a tool used as an exchange of goods and services when money is not available. Free for one month and then $2/year",
      meta: {
        charset: "utf-8",
        name: {
          keywords: "react,meta,document,html,tags",
        },
      },
    };
  }

  return <DocumentMeta {...meta}></DocumentMeta>;
}

export default MetaTag;
