import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Container, Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, useHistory, useParams } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Header from "../../Header/Header";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function ProjectInvitation(props) {
  let history = useHistory();
  const { token } = useParams();
  const classes = useStyles();

  let [invitationData, setInvitationData] = useState();
  let [isInvitationData, setIsInvitationData] = useState(false)
  let [isConfirming, setIsConfirming] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  let [isLoading, setIsLoading] = useState(false);
  let [email, setEmail] = useState();
  let [password, setPassword] = useState();
  let [msg, setMsg] = useState("");
  let [isError, setIsError] = useState();

  let handleMarketPlaceLoginCheck = (e) => {
    e.preventDefault();
    handleShowSuccessModal();
  };

  let handleUserInvitation = () => {
    setIsConfirming(true);
    axios
      .get(`/api/v1/users/invitation_data/${token}`)
      .then((response) => {

        setIsConfirming(false);
        setInvitationData(response.data);
        setIsInvitationData(true);

      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [twoFACheck, setTwoFACheck] = useState(false);
  let [pinEmail, setPinEmail] = useState();
  let [pin, setPin] = useState("");
  let [isSendingPin, setIsSendingPin] = useState(false);
  let [pinError, setPinError] = useState("");


  let handleFormSubmit = (e) => {
    setMsg("");
    setIsError(false);
    setIsLoading(true);
    e.preventDefault();
    axios
      .post(
        "/api/v1/auth/user/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data === "2FA") {
          setPinEmail(email);
          setEmail("");
          setPassword("");
          setTwoFACheck(true);
        } else {
          console.log("Adrees: ", response.data);
          axios
            .post(`/api/v1/users/acceptInvitation/${token}`, { withCredentials: true })
            .then((response) => {
              console.log("RESPONSE", response.data);
            })
            .catch((error) => {
              console.log(error);
            });

          props.setUser(response.data.user);
          props.setIsLoggedIn(response.data.loggedIn);

          history.push("/dashboard");

        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setMsg("Incorrect email or password entered");
          } else if (error.response.status === 501) {
            setMsg("Cannot send 2FA email, try again.");
          } else {
            setMsg("Unknown Error Occured, try again.");
          }
        } else {
          setMsg("Unknown Error Occured, try again.");
        }
        console.log(error);
        console.log(error.response);

        setIsError(true);
        setIsLoading(false);
      });
  };

  let handlePinSubmitEvent = (e) => {
    e.preventDefault();
    setPinError("");
    setIsSendingPin(true);
    let data = {
      email: pinEmail,
      pin: pin,
    };
    axios
      .post("/api/v1/auth/user/2FAlogin", data, { withCredentials: true })
      .then((response) => {
        console.log("Adrees: ", response.data);
        if (response.data.role === "participant") {
          setMsg("Individual users must login using Mobile Application.");
        } else {
          props.setUser(response.data.user);
          props.setIsLoggedIn(response.data.loggedIn);
          history.push("/dashboard");

        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setPinError("Try login again");
          } else if (error.response.status === 401) {
            setPinError("Incorrect Pin Entered");
          } else {
            setPinError("Unknown Error Occured, try again.");
          }
        } else {
          setPinError("Unknown Error Occured, try again.");
        }
        console.log(error);
        console.log(error.response);
        setIsSendingPin(false);
      });
  };

  useEffect(() => {
    handleUserInvitation();
  }, []);
  console.log("response.data.project.title", invitationData);
  return (
    <div className="home-main-wrapper">
      <div className="home-section home-full-height">
        <Header setlocal={props.setlocal} selectedNav={"home"} />
        {/* {isConfirming ? (
          <>
            <Backdrop className={classes.backdrop} open={isConfirming}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ) : null} */}
        {/* {isSuccess === true ? ( */}
        <Container>
          <Card style={{ marginTop: "10%" }}>
            <Card.Header className="text-center">
              <h2>
                <strong>
                  <FormattedMessage
                    id="emailVerification.projectInvitation"
                    defaultMessage="Project Invitation" />
                </strong>
              </h2>
            </Card.Header>
            <Card.Body>
              <Card.Title className="text-center">
                <h3>
                  <u>
                    <FormattedMessage
                      id="emailVerification.aboutProject"
                      defaultMessage="About Project :" />
                  </u>
                </h3>
              </Card.Title>
              <Card.Text>
                <div className="row">
                  <div className="col-12 ">
                    <strong>
                      <FormattedMessage
                        id="emailVerification.communityLeader"
                        defaultMessage="Community Leader :" />

                      {/*  */}
                    </strong>
                    {invitationData !== undefined ? (invitationData.data.communityleader.firstName) : (<p></p>)}
                    {' '}
                    {invitationData !== undefined ? (invitationData.data.communityleader.lastName) : (<p></p>)}

                  </div>
                  <div className="col-12 ">
                    <strong><FormattedMessage
                      id="emailVerification.projectTitle"
                      defaultMessage="Project Title :" /></strong>
                    {invitationData !== undefined ? (invitationData.data.project.title) : (<p></p>)}
                  </div>
                  <div className="col-12 ">
                    <strong><FormattedMessage
                      id="emailVerification.projectDescription"
                      defaultMessage="Project Description :" /></strong>
                    {invitationData !== undefined ? (invitationData.data.project.description) : (<p></p>)}
                  </div>
                </div>
              </Card.Text>

            </Card.Body>
            <Card.Footer className="text-muted">
              <div className="col-12 text-center">
                {/* onClick={handelViewInvitation} */}
                <Button variant="secondary" onClick={handleMarketPlaceLoginCheck}>
                  {/* <Link href="/userInvitation" onClick={handleMarketPlaceLoginCheck}> */}
                  <FormattedMessage
                    id="projectVerification.viewVerification"
                    defaultMessage="Accept" />
                  {/* </Link> */}
                </Button>
              </div>
            </Card.Footer>
            <Modal centered show={showSuccessModal} onHide={handleCloseSuccessModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="header.modal.projectinvitation.title"
                    defaultMessage="Login to Accept Project Invitation"
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {twoFACheck ? (
                  <>
                    <div className="login-header">
                      <h3>
                        <FormattedMessage
                          id="loginScreen.enterPin"
                          defaultMessage="Enter Pin"
                        />
                      </h3>
                      <p className="small text-muted">
                        <FormattedMessage
                          id="loginScreen.pinMessage"
                          defaultMessage=" Enter pin you received in your email"
                        />
                      </p>
                    </div>
                    <form onSubmit={handlePinSubmitEvent}>
                      <div className="form-group form-focus focused">
                        <input
                          name="pin"
                          required
                          type="text"
                          className="form-control floating"
                          defaultValue={pin}
                          onChange={(e) => {
                            setPin(e.target.value);
                          }}
                        />
                        <label className="focus-label">
                          <FormattedMessage
                            id="loginScreen.pin"
                            defaultMessage="Pin"
                          />
                        </label>
                      </div>
                      <div className="text-center">
                        <p style={{ color: "red" }}>{pinError}</p>
                      </div>

                      {isSendingPin ? (
                        <div className="text-center">
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "00d0f1" }}
                          >
                            <span className="sr-only">
                              <FormattedMessage
                                id="loading"
                                defaultMessage="Loading..."
                              />
                            </span>
                          </Spinner>
                        </div>
                      ) : (
                          <button
                            className="btn btn-primary btn-block btn-lg login-btn"
                            type="submit"
                          >
                            <FormattedMessage
                              id="loginScreen.submitButton1"
                              defaultMessage=" Submit"
                            />
                          </button>
                        )}
                    </form>
                  </>
                ) : (
                    <>
                      <div className="login-header">
                        <h2 style={{ textAlign: "center" }}>
                          <FormattedMessage
                            id="loginScreen.signInLabel"
                            defaultMessage="Sign In"
                          />
                        </h2>
                      </div>
                      <form onSubmit={handleFormSubmit}>
                        <div className="form-group form-focus focused">
                          <input
                            type="email"
                            className="form-control floating"
                            defaultValue={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                          <label className="focus-label">
                            <FormattedMessage
                              id="loginScreen.emailLabel"
                              defaultMessage="Email"
                            />
                          </label>
                        </div>
                        <div className="form-group form-focus focused">
                          <input
                            type="password"
                            className="form-control floating"
                            defaultValue={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <label className="focus-label">
                            <FormattedMessage
                              id="loginScreen.passwordLabel"
                              defaultMessage="Password"
                            />
                          </label>
                        </div>
                        <div className="text-center">
                          <p style={{ color: "red" }}>{msg}</p>
                        </div>
                        <div className="text-right">
                          <Link to="/forgotPassword" className="forgot-link">
                            <FormattedMessage
                              id="loginScreen.forgotPassword"
                              defaultMessage="Forgot Password ?"
                            />
                          </Link>
                        </div>

                        {isLoading ? (
                          <div className="text-center">
                            <Spinner
                              animation="border"
                              role="status"
                              style={{ color: "#00d0f1" }}
                            >
                              <span className="sr-only">
                                <FormattedMessage
                                  id="loading"
                                  defaultMessage="Loading..."
                                />
                              </span>
                            </Spinner>
                          </div>
                        ) : (
                            <button
                              // className="btn btn-primary btn-block btn-lg login-btn"
                              className="btn btn-block btn-lg login-btn"
                              type="submit"
                            >
                              <FormattedMessage
                                id="loginScreen.signinButton1"
                                defaultMessage=" Sign In"
                              />{" "}
                            </button>
                          )}
                        <div className="text-center dont-have">
                          <FormattedMessage
                            id="loginScreen.noAccount"
                            defaultMessage="   Don’t have an account?"
                          />{" "}
                          <Link to="/signup">
                            <FormattedMessage
                              id="loginScreen.registerLink"
                              defaultMessage="Register"
                            />
                          </Link>
                        </div>
                      </form>
                    </>
                  )}
              </Modal.Body>
            </Modal>
          </Card>
        </Container>

        )
      </div>
    </div>
  );
}

export default ProjectInvitation;
