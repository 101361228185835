import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
// import AccountApprovalDetails from "./AccountApprovalDetails";
import { FormattedMessage } from "react-intl";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function ChangeHomeImages(props) {

  const mess1 = (<FormattedMessage
    id="admin.uploadHomeImage.error1"
    defaultMessage="Couldn't Upload File, try again" />);

  const mess2 = (<FormattedMessage
    id="admin.uploadHomeImage.error2"
    defaultMessage="Couldn't Get Signed URL " />);

  const mess3 = (<FormattedMessage
    id="admin.uploadHomeImage.mess3"
    defaultMessage="Successfully Deleted." />);

  const mess4 = (<FormattedMessage
    id="admin.uploadHomeImage.error4"
    defaultMessage="Unknown error occured, Try again." />);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  let [msg, setMsg] = useState("");

  let [imageData, setImageData] = useState();
  let [fileType, setFileType] = useState();
  let [fileName, setFileName] = useState();
  let onChangePictureHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      let fileParts = event.target.files[0].name.split(".");
      setFileName(`${Date.now()}.${fileParts[1]}`);
      setFileType(fileParts[1]);
      setImageData(event.target.files[0]);
    }
  };

  let [imageData2, setImageData2] = useState();
  let [fileType2, setFileType2] = useState();
  let [fileName2, setFileName2] = useState();
  let onChangePictureHandlerAfter = (event) => {
    if (event.target.files[0] !== undefined) {
      let fileParts = event.target.files[0].name.split(".");
      setFileName2(`${Date.now()}.${fileParts[1]}`);
      setFileType2(fileParts[1]);
      setImageData2(event.target.files[0]);
    }
  };

  let [data, setData] = useState([]);
  let [isGettingData, setIsGettingData] = useState(false);
  let getData = () => {
    setIsGettingData(true);
    axios
      .get(`/api/v1/admin/addhomepagepicture`, { withCredentials: true })
      .then((response) => {
        setData(response.data);
        setIsGettingData(false);
        console.log(response.data);
      })
      .catch((error) => {
        setIsGettingData(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getData();
    props.setActiveTab({
      uploadHomeImages: "active",
    });
  }, []);

  let [isSaving, setIsSaving] = useState(false);
  let handleUploadImage = (event) => {
    setIsSaving(true);
    event.preventDefault();

    axios
      .post(
        "/api/v1/users/sign_s3",
        {
          fileName: fileName,
          fileType: fileType,
        },
        { withCredentials: true }
      )
      .then((response) => {
        var returnData = response.data.data.returnData;
        var signedRequest = returnData.signedRequest;
        var beforeURL = returnData.url;

        delete axios.defaults.headers.common["Authorization"];
        // Put the fileType in the headers for the upload
        var options = {
          headers: {
            "Content-Type": fileType,
          },
          withCredentials: false,
        };
        axios
          .put(signedRequest, imageData, options)
          .then((result) => {
            axios
              .post("/api/v1/users/sign_s3", {
                fileName: fileName2,
                fileType: fileType2,
              })
              .then((response) => {
                var returnData = response.data.data.returnData;
                var signedRequest = returnData.signedRequest;
                var afterURL = returnData.url;

                // delete axios.defaults.headers.common["Authorization"];
                // Put the fileType in the headers for the upload
                var options = {
                  headers: {
                    "Content-Type": fileType2,
                  },
                  withCredentials: false,
                };
                axios
                  .put(signedRequest, imageData2, options)
                  .then((result) => {
                    // axios.defaults.headers.common[
                    //   "Authorization"
                    // ] = `Bearer ${Cookies.get("Authorization")}`;
                    axios
                      .post(
                        `/api/v1/admin/addhomepagepicture`,
                        {
                          beforePictureURL: beforeURL,
                          beforePictureName: fileName,
                          afterPictureURL: afterURL,
                          afterPictureName: fileName2,
                          englishDescription: englishDescription,
                          spanishDescription: spanishDescription,
                        },
                        { withCredentials: true }
                      )
                      .then((response) => {
                        getData();
                        setIsSaving(false);
                        setMsg("Successfully Uploaded");
                        handleShowSuccessModal();
                        props.handleDataUpdated();
                      })
                      .catch((error) => {
                        setIsSaving(false);
                        if (process.env.NODE_ENV === "development") {
                          console.log(error);
                        }
                      });
                  })
                  .catch((error) => {
                    if (process.env.NODE_ENV === "development") {
                      alert("ERROR " + JSON.stringify(error));
                      console.log(error);
                    }

                    setIsSaving(false);

                    // axios.defaults.headers.common[
                    //   "Authorization"
                    // ] = `Bearer ${Cookies.get("Authorization")}`;

                    // setMsg("Couldn't Upload File, try again");
                    setMsg(mess1);
                    handleShowSuccessModal();
                  });
              })
              .catch((error) => {
                if (process.env.NODE_ENV === "development") {
                  alert(JSON.stringify(error));
                }
                setIsSaving(false);
                setMsg("Couldn't Get Signed URL ");
                handleShowSuccessModal();
              });
          })
          .catch((error) => {
            if (process.env.NODE_ENV === "development") {
              alert("ERROR " + JSON.stringify(error));
              console.log(error);
            }

            setIsSaving(false);

            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${Cookies.get("Authorization")}`;

            // setMsg("Couldn't Upload File, try again");
            setMsg(mess1);
            handleShowSuccessModal();
          });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          alert(JSON.stringify(error));
        }
        setIsSaving(false);
        // setMsg("Couldn't Get Signed URL ");
        setMsg(mess2);
        handleShowSuccessModal();
      });
  };

  let handleDeleteImage = (id) => {
    axios
      .delete(`/api/v1/admin/addhomepagepicture/${id}`, {
        withCredentials: true,
      })
      .then((response) => {
        getData();
        // setMsg("Successfully Deleted.");
        setMsg(mess3);
        handleShowSuccessModal();
      })
      .catch((error) => {
        // setMsg("Unknown error occured, Try again.");
        setMsg(mess4);
        handleShowSuccessModal();
      });
  };

  let [englishDescription, setEnglishDescription] = useState();
  let [spanishDescription, setSpanishDescription] = useState();

  return (
    <>
      <div className="card">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="index.html">
              <FormattedMessage
                id="admin.changeHomeImages.dashboardBreadcrumb"
                defaultMessage="Dashboard"
              />
            </a>
          </li>
          <li className="breadcrumb-item active">
            <FormattedMessage
              id="admin.changeHomeImages.uploadImagesBreadcrumb"
              defaultMessage="Upload Home Images"
            />
          </li>
        </ul>
        <div className="card-body">
          <form onSubmit={handleUploadImage}>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.changeHomeImages.addMingaImagesBefore"
                      defaultMessage="Add Minga Before Image"
                    />
                  </label>
                  <input
                    required
                    type="file"
                    name="sampleFile"
                    accept=".jpg,.png,.jpeg,.jfif"
                    className="form-control"
                    onChange={onChangePictureHandler}
                  />
                  <small className="form-text text-muted">
                  <FormattedMessage
                      id="user.placead.allowedFormat"
                      defaultMessage="Allowed .jpg and .png"
                    />  
                  </small>
                  
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.changeHomeImages.addMingaImagesAfter"
                      defaultMessage="Add Minga After Images"
                    />
                  </label>
                  <input
                    required
                    type="file"
                    name="sampleFile"
                    accept=".jpg,.png,.jpeg,.jfif"
                    className="form-control"
                    onChange={onChangePictureHandlerAfter}
                  />
                  <small className="form-text text-muted">
                  <FormattedMessage
                      id="user.placead.allowedFormat"
                      defaultMessage="Allowed .jpg and .png"
                    />  
                  </small>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.changeHomeImages.addMingaEnglishDescription"
                      defaultMessage="Add Minga English Description"
                    />
                  </label>
                  <textarea
                    required
                    rows="2"
                    type="text"
                    className="form-control"
                    onChange={(e) => setEnglishDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.changeHomeImages.addMingaSpanishDescription"
                      defaultMessage="Add Minga Spanish Description"
                    />
                  </label>
                  <textarea
                    required
                    rows="2"
                    type="text"
                    className="form-control"
                    onChange={(e) => setSpanishDescription(e.target.value)}
                  />
                </div>
              </div>
              {isSaving ? (
                <div className="col-12 ">
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#00d0f1" }}
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id="loading"
                          defaultMessage="Loading..."
                        />
                      </span>
                    </Spinner>
                  </div>
                </div>
              ) : (
                  <div className="col-12 text-center">
                    <button
                      type="submit"
                      // className="btn btn-primary"
                      className="btn"
                      style={{ width: "fit-content" }}
                    >
                      <FormattedMessage
                        id="admin.changeHomeImages.saveChangesButton1"
                        defaultMessage="Upload Images"
                      />
                    </button>
                  </div>
                )}
            </div>
          </form>
          <br />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="datatable table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th><FormattedMessage
                        id="communityLeader.mymingas.table.number"
                        defaultMessage="#" /></th>
                      <th>
                        <FormattedMessage
                          id="admin.changeHomeImages.table.imageLableBefore"
                          defaultMessage="Before Image"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="admin.changeHomeImages.table.imageLableAfter"
                          defaultMessage="After Image"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="admin.changeHomeImages.table.englishDescription"
                          defaultMessage="English Description"
                        />
                      </th>
                      <th>
                        {" "}
                        <FormattedMessage
                          id="admin.changeHomeImages.table.spanishDescription"
                          defaultMessage="Spanish Description"
                        />
                      </th>
                      <th className="text-right">
                        <FormattedMessage
                          id="admin.changeHomeImages.table.actionsLable"
                          defaultMessage="Actions"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((i, index) => (
                      <tr key={i._id}>
                        <td>{index + 1}</td>

                        {/* <td>{i.name}</td> */}
                        <td>
                          <div className="change-avatar">
                            <div className="home-adminimage">
                              <img
                                src={i.beforePictureURL}
                                alt="Before Image"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="change-avatar">
                            <div className="home-adminimage">
                              <img src={i.afterPictureURL} alt="After Image" />
                            </div>
                          </div>
                        </td>
                        <td>{i.englishDescription}</td>
                        <td>{i.spanishDescription}</td>
                        <td className="text-right">
                          <div className="actions">
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                // setSelectedIndex(i._id);
                                handleDeleteImage(i._id);
                              }}
                              className="btn btn-sm bg-danger-light"
                            >
                              <i className="fe fe-trash"></i>
                              <FormattedMessage
                                id="admin.changeHomeImages.table.deleteButton1"
                                defaultMessage="Delete"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>{msg}</Modal.Title>
        </Modal.Header>
      </Modal>
    </>
  );
}

export default ChangeHomeImages;
