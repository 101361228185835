import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import English from "../languages/en-US.json";
import Spanish from "../languages/es-SV.json";
// import AdminLogin from "./AdminLogin";
import AdminDashboard from "./Dashboards/AdminDashboard";
import CommunityLeaderDashboard from "./Dashboards/CommunityLeaderDashboard";
import UserDashboard from "./Dashboards/UserDashboardScreen";
import AgentDashboard from "./Dashboards/AgentDashBoardScreen";
import MarketPlace from "./MarketPlace/MarketPlace";
// import PrivacyPolicyScreen from "./PrivacyPolicyScreen";
// import SupportScreen from "./SupportScreen";
import MetaTag from "./Pages/MetaTags/MetaTagsComponent";
import AboutScreen from "./Pages/Screens/AboutScreen";
import EmailVerification from "./Pages/Screens/EmailVerification";
import ErrorScreen from "./Pages/Screens/ErrorScreen";
import HomeScreen from "./Pages/Screens/HomeScreen";
import LoginScreen from "./Pages/Screens/LoginScreen";
import NoMatch from "./Pages/Screens/NoMatchScreen";
import RegisterScreen from "./Pages/Screens/RegisterScreen";
import ForgotPassword from "./Password/ForgotPassword";
import ProjectInvitation from "./Pages/ProjectInvitation/ProjectInvitation";
import UserProjectInvitation from "./User/Projects/UserProjects";
import { ConfigureStore } from "../redux/configurStore"
import { Provider } from "react-redux";


// import { useRouteMatch } from "react-router-dom";

function App() {
  // let { path, url } = useRouteMatch();

  // if (
  //   process.env.REACT_APP_BACKEND_SERVER_ADDRESS === null ||
  //   process.env.REACT_APP_BACKEND_SERVER_ADDRESS === undefined
  // ) {
  //   axios.defaults.baseURL = `http://localhost:3000`;
  // } else {
  //   axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}`;
  // }

  if (window.location.href.includes("mingaproject.com")) {
    axios.defaults.baseURL = `https://backend.mingaproject.com`;
    // axios.defaults.baseURL = `http://localhost:3000`;
  } else if (window.location.href.includes("proyectominga.com")) {
    axios.defaults.baseURL = `https://backend.proyectominga.com`;
    // axios.defaults.baseURL = `${process.env.REACT_APP_BACKEND_SERVER_ADDRESS}`;
  } else {
    axios.defaults.baseURL = `http://localhost:3000`;
  }

  const [local, setlocal] = useState(navigator.language);

  let [lang, setLang] = useState();
  // let lang = English;

  useEffect(() => {
    if (local === "en-US") {
      // lang = English;
      setLang(English);
    } else {
      // lang = Spanish;
      setLang(Spanish);
    }
  }, [local]);

  useEffect(() => {
    let domain = window.location.href;
    if (domain.includes("proyectominga.com")) {
      if (Cookies.get("Locale") === undefined || Cookies.get("Locale") === null) {
        Cookies.set("Locale", "en-SV");
        setlocal("en-SV");
      } else {
        if (Cookies.get("Locale") === "en-US") {
          // lang = English;
          setlocal("en-US");
        } else {
          setlocal("en-SV");
          // lang = Spanish;
        }
      }
    } else {
      if (Cookies.get("Locale") === undefined || Cookies.get("Locale") === null) {
        Cookies.set("Locale", "en-US");
        setlocal("en-US");
      } else {
        if (Cookies.get("Locale") === "en-SV") {
          // lang = English;
          setlocal("en-SV");
        } else {
          // lang = Spanish;
          setlocal("en-US");
        }
      }
    }
  }, []);

  let [isLoggedIn, setIsLoggedIn] = useState();
  let [user, setUser] = useState();
  let checkLoginStatus = () => {
    axios
      .get("/api/v1/auth/user/isloggedin", { withCredentials: true })
      .then((response) => {
        setIsLoggedIn(response.data.loggedIn);
        setUser(response.data.user);
      })
      .catch((error) => {
        setIsLoggedIn(false);
        console.log(error);
      });
  };

  useEffect(() => {
    checkLoginStatus();
    // setlocal(Cookies.get("Locale"));
  }, []);

  const PrivateRoute = ({ path: path, ...rest }) => {
    // console.log(path);
    if (user && isLoggedIn) {
      if (user.role === "communityleader") {
        return (
          <Route
            {...rest}
            render={(props) =>
              isLoggedIn ? (

                <CommunityLeaderDashboard
                  {...props}
                  user={user}
                  checkLoginStatus={checkLoginStatus}
                />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />
        );
      }
      if (user.role === "agent") {
        return (
          <Route
            {...rest}
            render={(props) =>
              isLoggedIn ? (
                <AgentDashboard
                  {...props}
                  user={user}
                  checkLoginStatus={checkLoginStatus}
                />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />
        );
      }
      if (user.role === "user") {
        return (
          <Route
            {...rest}
            render={(props) =>
              isLoggedIn ? (
                <UserDashboard
                  {...props}
                  user={user}
                  checkLoginStatus={checkLoginStatus}
                />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />
        );
      } else if (user.role === "admin") {
        return (
          <Route
            {...rest}
            render={(props) =>
              isLoggedIn ? (
                <AdminDashboard
                  {...props}
                  user={user}
                  checkLoginStatus={checkLoginStatus}
                />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />
        );
      } else {
        return <Redirect to="/" />;
      }
    } else {
      return <Redirect to="/" />;
    }
  };

  const PrivateMarketPlace = ({ path: path, ...rest }) => {
    // console.log(path);
    if (user && isLoggedIn) {
      if (user.role === "user") {
        return (
          <Route
            {...rest}
            render={(props) =>
              isLoggedIn ? (
                // <CommunityLeaderDashboard {...props} jwtDecoded={jwtDecoded} />
                <MarketPlace
                  {...props}
                  user={user}
                  checkLoginStatus={checkLoginStatus}
                />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />
        );
      } else {
        return <Redirect to="/dashboard" />;
      }
    } else {
      return <Redirect to="/" />;
    }
  };

  const PrivateUserInvitation = ({ path: path, ...rest }) => {
    // console.log(path);
    if (user && isLoggedIn) {
      if (user.role === "user") {
        return (
          <Route
            {...rest}
            render={(props) =>
              isLoggedIn ? (
                // <CommunityLeaderDashboard {...props} jwtDecoded={jwtDecoded} />
                <UserProjectInvitation
                  {...props}
                  user={user}
                  checkLoginStatus={checkLoginStatus}
                />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />
        );
      } else {
        return <Redirect to="/userInvitation" />;
      }
    } else {
      return <Redirect to="/" />;
    }
  };
  const LoginRegisterRedirectCheck = ({ path: path, ...rest }) => {
    if (user && isLoggedIn) {
      return <Redirect to="/dashboard" />;
    } else if (path === "/login") {
      return <Route render={(props) => <LoginScreen {...rest} />} />;
    } else if (path === "/signup") {
      return <Route render={(props) => <RegisterScreen {...rest} />} />;
    } else if (path === "/about") {
      return <Route render={(props) => <AboutScreen {...rest} />} />;
    } else {
      return <Route render={(props) => <HomeScreen {...rest} />} />;
    }
  };
  const store = ConfigureStore();
  return (
    <Provider store={store}>
      <MetaTag />
      <IntlProvider locale={local} messages={lang} key={local}>
        <BrowserRouter>
          <Switch>
            <LoginRegisterRedirectCheck
              exact
              path="/"
              setlocal={setlocal}
              local={local}
              setIsLoggedIn={setIsLoggedIn}
              setUser={setUser}
            />
            <LoginRegisterRedirectCheck
              exact
              path="/login"
              setlocal={setlocal}
              setIsLoggedIn={setIsLoggedIn}
              setUser={setUser}
            />
            <LoginRegisterRedirectCheck
              exact
              path="/signup"
              setlocal={setlocal}
              setIsLoggedIn={setIsLoggedIn}
              setUser={setUser}
            />
            {/* <Route exact path="/adminLogin" component={AdminLogin} /> */}
            <Route exact path="/about" component={AboutScreen} />
            <Route exact path='/training' component={() => {
              window.location.href = 'https://proyectominga774683741.wordpress.com/centro-de-capacitacion-para-la-plataforma-del-proyecto-minga/';
            }} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <PrivateRoute path="/dashboard" />
            {/* <Route exact path="/privacypolicy" component={PrivacyPolicyScreen} /> */}
            {/* <Route exact path="/support" component={SupportScreen} /> */}
            <Route exact path="/error" component={ErrorScreen} />
            {/* <Route
              path="/marketplace"
              render={(routeProps) => (
                <MarketPlace {...routeProps}  />
              )}
            /> */}
            <PrivateMarketPlace path="/marketplace" setlocal={setlocal} />
            <Route
              path="/emailverification/:email/:token"
              render={(routeProps) => (
                <EmailVerification {...routeProps} setlocal={setlocal} />
              )}
            />
            {/* <PrivateUserInvitation path="/userInvitation" setlocal={setlocal} /> */}
            <Route
              path="/projectinvitation/:token"
              render={(routeProps) => (
                <ProjectInvitation
                  {...routeProps}
                  setIsLoggedIn={setIsLoggedIn}
                  setUser={setUser}
                  setlocal={setlocal}
                />
              )}
            />

            <Route component={NoMatch} />
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    </Provider>
  );
}

export default App;
