// import AccountApprovalDetails from "./AccountApprovalDetails";
import { FormControlLabel, Switch } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Route, useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function TotalCommunityLeaders(props) {
  var rows = [], i = 0, len = 10;
  while (++i <= len) rows.push(i);
  let { path, url } = useRouteMatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const handleClose = () => setShow(false);
  let [userId, setUserId] = useState();
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  let [isSuspending, setIsSuspending] = useState(false);
  let [textareaData, settextareaData] = useState();

  let [isSaving, setIsSaving] = useState(false);
  let [isError, setIsError] = useState(false);
  let [errorMsg, setErrorMsg] = useState();

  let [data, setData] = useState([]);
  let [isGettingData, setIsGettingData] = useState(false);
  let getData = () => {
    setIsGettingData(true);
    axios
      .get(`/api/v1/communityleaders/approvedaccounts`, {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data);
        setIsGettingData(false);
        console.log("RESSS", response.data);
      })
      .catch((error) => {
        setIsGettingData(false);
        console.log(error);
      });
  };
  useEffect(() => {
    props.setActiveTab({
      totalCommunityLeaders: "active",
    });
    getData();
  }, []);

  // const toggleChecked = (e) => {
  //   console.log("HELLO",e._id);
  //   console.log( "/api/v1/auth/user/suspend/"+e._id);
  //   if(e.suspended===false)
  //   {
  //       axios.put("/api/v1/auth/user/suspend/"+e._id,{ withCredentials: true})
  //       .then((response) => {
  //           console.log("Res",response);
  //           getData();
  //       })
  //       .catch((error) => {
  //           console.log(error);
  //       });

  //   }
  //   else
  //   {
  //       axios.put("/api/v1/auth/user/unsuspend/"+e._id,{ withCredentials: true})
  //       .then((response) => {
  //           console.log("Res",response);
  //           getData();
  //       })
  //       .catch((error) => {
  //           console.log(error);
  //       });

  //   }
  // };
  const suspendUser = (e) => {
    console.log("USER ID", userId);
    // console.log("E._ID",e._id);
    setIsSuspending(true);
    let data = {
      reason: textareaData,
    };
    axios
      .put("/api/v1/suspension/suspend/" + userId, data, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Res", response);
        handleClose();
        setIsSuspending(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const toggleChecked = (e) => {
    console.log("HELLO", e);
    setUserId(e._id);
    if (e.suspended === true) {
      let data = {
        reason: "",
      };
      axios
        .put("/api/v1/suspension/unsuspend/" + e._id, data, {
          withCredentials: true,
        })
        .then((response) => {
          console.log("Res", response);
          getData();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  const handleChange = (event) => {
    settextareaData(event.target.value);
  };
  const suspendandSetLeader = (e) => {
    console.log("Adrees", e);
    setUserId(e._id);
    handleShow(true);
  };
  const exportCSV = () => {
    // console.log("EXPORING",data);
    var csvRow = [];
    var array = [
      [
        "Sr. No.",
        "First_Name",
        "Last_Name",
        "Email",
        "Phone Number",
        "Identification_Card_Number",
        "Description",
      ],
    ];
    var dataForCSV = data;
    for (var i = 0; i < dataForCSV.length; i++) {
      array.push([
        i + 1,
        dataForCSV[i].firstName,
        dataForCSV[i].lastName,
        dataForCSV[i].email,
        dataForCSV[i].mobile,
        dataForCSV[i].idCard,
        dataForCSV[i].description,
      ]);
    }
    // console.warn(array);
    for (var i = 0; i < array.length; ++i) {
      csvRow.push(array[i].join(","));
    }
    var csvString = csvRow.join("%0A");
    // console.warn("csvString",csvString);
    var a = document.createElement("a");
    a.href = "data:attachment/csv," + csvString;
    a.target = "_Blank";
    a.download = "CommunityLeader.csv";
    document.body.appendChild(a);
    a.click();
  };

  return (
    <>
      <Route exact path={`${path}`}>
        <div className="card">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <FormattedMessage
                  id="admin.totalCommunityLeaders.dashboardBreadcrumb"
                  defaultMessage="Dashboard"
                />
              </a>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage
                id="admin.totalCommunityLeaders.totalLeaderBreadcrumb"
                defaultMessage="Total Community Leaders"
              />
            </li>
          </ul>
          <div className="card-body">
            <button
              style={{ float: "right", marginBottom: "10px" }}
              className="btn"
              onClick={exportCSV}
            >
              <FormattedMessage
                id="admin.communityLeaders.downloadCSV"
                defaultMessage="Download CSV"
              />
            </button>
            <div className="table-responsive" style={{ paddingTop: "20px" }}>
              <table className="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.number"
                        defaultMessage="#"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.name"
                        defaultMessage="Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.email"
                        defaultMessage="Email"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.phone"
                        defaultMessage="Phone"
                      />
                    </th>

                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.id"
                        defaultMessage="Identification Card Number"
                      />
                    </th>

                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.status"
                        defaultMessage="Status"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.description"
                        defaultMessage="Description"
                      />
                    </th>
                    <th className="text-right">
                      <FormattedMessage
                        id="admin.pendingRequests.actions"
                        defaultMessage="Actions"
                      />
                    </th>
                  </tr>
                </thead>
                {
                  data.length === 0 ? (
                    <tbody>
                      {rows.map(function (i) {
                        return (
                          <tr >
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                            <td><Skeleton variant="text" height={80} /></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  ) : (
                      <tbody>
                        {data.map((i, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {i.firstName} {i.lastName}
                            </td>
                            <td>{i.email}</td>
                            <td>{i.mobile}</td>
                            <td>{i.idCard}</td>
                            <td>
                              <Row>
                                <Col>
                                  {i.suspended ? (
                                    <i>
                                      <FormattedMessage
                                        id="admin.users.suspended"
                                        defaultMessage="Suspended"
                                      />
                                    </i>
                                  ) : (
                                      <i>
                                        <FormattedMessage
                                          id="admin.users.alive"
                                          defaultMessage="Alive"
                                        />
                                      </i>
                                    )}
                                </Col>
                                <Col>
                                  {i.suspended ? (
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={i.suspended}
                                          color="primary"
                                          onChange={() => toggleChecked(i)}
                                        />
                                      }
                                    />
                                  ) : (
                                      <>
                                        <FormControlLabel
                                          control={
                                            <Switch
                                              checked={i.suspended}
                                              color="primary"
                                              onClick={suspendandSetLeader}
                                              onChange={() => toggleChecked(i)}
                                            />
                                          }
                                        />
                                      </>
                                    )}
                                </Col>
                              </Row>
                            </td>
                            <td>{i.description}</td>
                            <td className="text-right"> </td>
                          </tr>
                        ))}
                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              <FormattedMessage
                                id="admin.users.suspend.user"
                                defaultMessage="Suspend User"
                              />
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <Form>
                              <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>
                                  <FormattedMessage
                                    id="admin.users.reason"
                                    defaultMessage="Reason"
                                  />
                                </Form.Label>
                                <Form.Control
                                  name="textarea"
                                  onChange={handleChange}
                                  as="textarea"
                                  rows="3"
                                />
                              </Form.Group>
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                              <FormattedMessage
                                id="admin.users.cancel.button"
                                defaultMessage="Cancel"
                              />
                            </Button>
                            {!isSuspending ? (
                              <Button
                                variant="danger"
                                onClick={() => suspendUser(userId)}
                              >
                                <FormattedMessage
                                  id="admin.users.suspend.button"
                                  defaultMessage="Suspend"
                                />
                              </Button>
                            ) : (
                                <Button variant="danger">
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  <FormattedMessage
                                    id="admin.users.suspending.button"
                                    defaultMessage="Suspending"
                                  />
                                </Button>
                              )}
                          </Modal.Footer>
                        </Modal>
                      </tbody>
                    )
                }
              </table>
            </div>
          </div>
        </div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id="communityLeader.profileSetting.modalTitle"
                defaultMessage="Successfully Updated"
              />
            </Modal.Title>
          </Modal.Header>
        </Modal>
      </Route>
      {/* <Route path={`${path}/:companyId`}>
                <AccountApprovalDetails />
            </Route> */}
    </>
  );
}

export default TotalCommunityLeaders;
