import React from "react";
// import Slider from "react-slick";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
// import PricingBanner from "./Home/PricingBanner";
import AboutUs from "../../AboutUs/About";
import Footer from "../../Footer/Footer";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import Header from "../../Header/Header";

var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false
};

function AboutScreen() {
    return (
        <>
            {/* <div className="main-wrapper">
                <div className="home-section home-full-height"> */}
            <div className="main-wrapper" style={{ height: "inherit" }}>
                <div
                    className="home-section home-full-height"
                    style={{ height: "inherit" }}
                >
                    <Header selectedNav={"about"} />
                    <AboutUs />

                    <div >

                    </div>
                    <Footer position="absolute" />
                </div>
            </div>
            {/* </div> */}
        </>
    );
}
export default AboutScreen;
