import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function MingaCoinRequests(props) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  const mess1 = (
    <FormattedMessage
      id="mingaCoinRequest.mess1"
      defaultMessage="Decision Successfuly Submitted"
    />
  );

  const mess2 = (
    <FormattedMessage
      id="mingaCoinRequest.error1"
      defaultMessage="Unknown error occurred, try later"
    />
  );

  useEffect(() => {
    props.setActiveTab({
      coinRequests: "active",
    });
    getPendingCoinRequests();
  }, []);

  let [coinRequests, setCoinRequests] = useState([]);
  let getPendingCoinRequests = () => {
    axios
      .get(`/api/v1/mingacoin/mingacoinrequest/pending/${props.userData._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        setCoinRequests(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [isSaving, setIsSaving] = useState();
  let [msg, setMsg] = useState();
  let handleDecision = (decision, id) => {
    let data = {
      signatoryId: props.userData._id,
      decision: decision,
      requestId: id,
    };

    setIsSaving(true);
    axios
      .post(`/api/v1/mingacoin/mingacoinrequest/decision`, data, {
        withCredentials: true,
      })
      .then((response) => {
        // setMsg("Decision Successfuly Submitted");
        setMsg(mess1);
        setIsSaving(false);
        getPendingCoinRequests();
        handleShowSuccessModal();
      })
      .catch((error) => {
        // setMsg("Unknown error occurred, try later");
        setMsg(mess2);
        setIsSaving(false);
        handleShowSuccessModal();
        console.log(error);
      });
  };

  return (
    <>
      <div className="card">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="index.html">
              <FormattedMessage
                id="communityLeader.coinRequests.dashboardBreadcrumb"
                defaultMessage="Dashboard"
              />
            </a>
          </li>
          <li className="breadcrumb-item active">
            <FormattedMessage
              id="communityLeader.coinRequests.coinRequests"
              defaultMessage="Minga Unit Requests"
            />
          </li>
        </ul>

        <div className="card-body">
          <div className="col-12 col-md-12">
            <div className="table-responsive" style={{ paddingTop: "20px" }}>
              <table className="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.number"
                        defaultMessage="#"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.coinRequests.table.dateLabel"
                        defaultMessage="Date"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.coinRequests.table.project"
                        defaultMessage="Project"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.coinRequests.table.communitLeaderLabel"
                        defaultMessage="Community Leader"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.coinRequests.table.from"
                        defaultMessage="User"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.coinRequests.table.amountLabel"
                        defaultMessage="Amount"
                      />
                    </th>
                    <th className="text-right">
                      <FormattedMessage
                        id="communityLeader.coinRequests.table.actionLabel"
                        defaultMessage="Action"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {coinRequests.map((i, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{moment(i.requestDate).format("LLL")}</td>
                      <td>{i.projectId.projectName}</td>
                      <td>
                        {i.communityleader.firstName}{" "}
                        {i.communityleader.lastName}
                      </td>
                      <td>
                        {i.userId.firstName} {i.userId.lastName}
                      </td>
                      <td>{i.amount}</td>
                      <td className="text-right">
                        {isSaving ? (
                          <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            style={{
                              color: "#00d0f1",
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <span className="sr-only">
                              <FormattedMessage
                                id="loading"
                                defaultMessage="Loading..."
                              />
                            </span>
                          </Spinner>
                        ) : (
                          <>
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDecision("Approved", i._id);
                              }}
                              className="btn btn-sm bg-success-light"
                            >
                              <FormattedMessage
                                id="mingaCoinRequest.approveButton"
                                defaultMessage="Approve"
                              />
                            </a>
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                handleDecision("Rejected", i._id);
                              }}
                              className="btn btn-sm bg-danger-light"
                            >
                              <FormattedMessage
                                id="mimgaCoinRequest.rejectButton"
                                defaultMessage="Reject"
                              />
                            </a>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>{msg}</Modal.Title>
          </Modal.Header>
        </Modal>
      </div>
    </>
  );
}

export default MingaCoinRequests;
