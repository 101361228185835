import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { Modal, Nav, Navbar, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import facebook from "../../assets/img/facebook.png";
import logo from "../../assets/img/MINGAS_LOGO1.png";
import spainFlag from "../../assets/img/spain-flag-round-icon-32.png";
import ukFlag from "../../assets/img/united-kingdom-flag-round-icon-32.png";
import youtube from "../../assets/img/youtube.png";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function Header(props) {
  let history = useHistory();
  let [menuOpenedClass, setMenuOpenedClass] = useState();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  const selectedStyling = {
    border: "2px solid #638F3B",
    padding: "5px",
    borderRadius: "5px",
    // height: "30%",
    // marginTop: "40%"
  };
  // const defaultStyling = {
  //   padding: "7px",
  // };
  const selectedNavStyle = {
    home: props.selectedNav === "home" ? selectedStyling : null,
    about: props.selectedNav === "about" ? selectedStyling : null,
    publicInfo: props.selectedNav === "public" ? selectedStyling : null,
    contact: props.selectedNav === "contact" ? selectedStyling : null,
    market: props.selectedNav === "market" ? selectedStyling : null,
    training: props.selectedNav === "training" ? selectedStyling : null,
  };

  let handleMarketPlaceLoginCheck = (e) => {
    e.preventDefault();
    handleShowSuccessModal();
  };

  let [email, setEmail] = useState();
  let [password, setPassword] = useState();
  let [msg, setMsg] = useState("");
  let [isError, setIsError] = useState();
  let [isLoading, setIsLoading] = useState(false);
  let handleFormSubmit = (e) => {
    setMsg("");
    setIsError(false);
    setIsLoading(true);
    e.preventDefault();
    axios
      .post(
        "/api/v1/auth/user/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (response.data === "2FA") {
          setPinEmail(email);
          setEmail("");
          setPassword("");
          setTwoFACheck(true);
        } else {
          console.log("zohaib: ", response.data);
          // Cookies.set("Authorization", response.data.token, {
          //   sameSite: "Strict",
          //   secure: true,
          // });
          //
          props.setUser(response.data.user);
          props.setIsLoggedIn(response.data.loggedIn);
          // setData(response.data.token);
          // checkPermission(response.data.id, response.data.role);
          // setIsLoading(false);
          // window.location.reload();
          history.push("/marketplace");
          // setTimeout(() => {
          //   history.push("/dashboard");
          // }, 1000);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setMsg("Incorrect email or password entered");
          } else if (error.response.status === 501) {
            setMsg("Cannot send 2FA email, try again.");
          } else {
            setMsg("Unknown Error Occured, try again.");
          }
        } else {
          setMsg("Unknown Error Occured, try again.");
        }
        console.log(error);
        console.log(error.response);

        setIsError(true);
        setIsLoading(false);
      });
  };
  let [twoFACheck, setTwoFACheck] = useState(false);
  let [pinEmail, setPinEmail] = useState();
  let [pin, setPin] = useState("");
  let [isSendingPin, setIsSendingPin] = useState(false);
  let [pinError, setPinError] = useState("");
  let handlePinSubmitEvent = (e) => {
    e.preventDefault();
    setPinError("");
    setIsSendingPin(true);
    let data = {
      email: pinEmail,
      pin: pin,
    };
    axios
      .post("/api/v1/auth/user/2FAlogin", data, { withCredentials: true })
      .then((response) => {
        console.log("zohaib: ", response.data);
        if (response.data.role === "participant") {
          setMsg("Individual users must login using Mobile Application.");
        } else {
          // Cookies.set("Authorization", response.data.token, {
          //   sameSite: "Strict",
          //   secure: true,
          // });
          props.setUser(response.data.user);
          props.setIsLoggedIn(response.data.loggedIn);
          // setData(response.data.token);
          // checkPermission(response.data.id, response.data.role);
          // setIsLoading(false);
          // window.location.reload();
          history.push("/dashboard");
          // setTimeout(() => {
          //   history.push("/dashboard");
          // }, 1000);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setPinError("Try login again");
          } else if (error.response.status === 401) {
            setPinError("Incorrect Pin Entered");
          } else {
            setPinError("Unknown Error Occured, try again.");
          }
        } else {
          setPinError("Unknown Error Occured, try again.");
        }
        console.log(error);
        console.log(error.response);
        // setPinError(error.response.data);
        setIsSendingPin(false);
      });
  };

  return (
    <header className="header">
      <Navbar collapseOnSelect expand="lg" className="header-background">
        <Navbar.Brand href="/">
          <img src={logo} className="navbar-brand-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/">
              <span style={selectedNavStyle.home}>
                <FormattedMessage
                  id="header.logo"
                  defaultMessage="MINGA PROJECT"
                />
              </span>
            </Nav.Link>
            {/* <Nav.Link href="/about">
              <span style={selectedNavStyle.about}>
                <FormattedMessage id="header.about" defaultMessage="ABOUT US" />
              </span>
            </Nav.Link>
            <Nav.Link href="/">
              <span style={selectedNavStyle.publicInfo}>
                <FormattedMessage
                  id="header.public"
                  defaultMessage="PUBLIC INFO"
                />
              </span>
            </Nav.Link>
            <Nav.Link href="/">
              <span style={selectedNavStyle.contact}>
                <FormattedMessage
                  id="header.contact"
                  defaultMessage="CONTACT"
                />
              </span>
            </Nav.Link> */}
            <Nav.Link href="/marketplace" onClick={handleMarketPlaceLoginCheck}>
              <span style={selectedNavStyle.market}>
                <FormattedMessage
                  id="header.market"
                  defaultMessage="MARKET PLACE"
                />
              </span>
            </Nav.Link>
            <Nav.Link target="_blank" href="/training" rel="nofollow">
              <span style={selectedNavStyle.training}>
                <FormattedMessage
                  id="header.training"
                  defaultMessage="TRAINING"
                />
              </span>
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link href="/login" style={{ paddingTop: "15px" }}>
              <span>
                <FormattedMessage id="header.login" defaultMessage="LOGIN" />
              </span>
            </Nav.Link>
            <Nav.Link href="/signup" style={{ paddingTop: "15px" }}>
              <span>
                <FormattedMessage id="header.signup" defaultMessage="SIGN UP" />
              </span>
            </Nav.Link>
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/truequeminga/"
            >
              <img src={facebook} alt="Facebook Link" />
            </Nav.Link>
            <Nav.Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCMOX5yZIAi6UYcaHn93MJag"
            >
              <img src={youtube} alt="Youtube Link" />
            </Nav.Link>
            <Nav.Link
              href="/"
              onClick={(e) => {
                e.preventDefault();
                props.setlocal("en-SV");
                Cookies.set("Locale", "en-SV", { expires: 365 });
                setMenuOpenedClass("");
              }}
            >
              <img src={spainFlag} alt="Spanish Language" />
            </Nav.Link>
            <Nav.Link
              href="/"
              onClick={(e) => {
                e.preventDefault();
                props.setlocal("en-US");
                Cookies.set("Locale", "en-US", { expires: 365 });
                setMenuOpenedClass("");
              }}
            >
              <img src={ukFlag} alt="English Language" />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Modal centered show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="header.modal.title"
              defaultMessage="Login to Access Market Place"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {twoFACheck ? (
            <>
              <div className="login-header">
                <h3>
                  <FormattedMessage
                    id="loginScreen.enterPin"
                    defaultMessage="Enter Pin"
                  />
                </h3>
                <p className="small text-muted">
                  <FormattedMessage
                    id="loginScreen.pinMessage"
                    defaultMessage=" Enter pin you received in your email"
                  />
                </p>
              </div>
              <form onSubmit={handlePinSubmitEvent}>
                <div className="form-group form-focus focused">
                  <input
                    name="pin"
                    required
                    type="text"
                    className="form-control floating"
                    defaultValue={pin}
                    onChange={(e) => {
                      setPin(e.target.value);
                    }}
                  />
                  <label className="focus-label">
                    <FormattedMessage
                      id="loginScreen.pin"
                      defaultMessage="Pin"
                    />
                  </label>
                </div>
                <div className="text-center">
                  <p style={{ color: "red" }}>{pinError}</p>
                </div>

                {isSendingPin ? (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "00d0f1" }}
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id="loading"
                          defaultMessage="Loading..."
                        />
                      </span>
                    </Spinner>
                  </div>
                ) : (
                    <button
                      className="btn btn-primary btn-block btn-lg login-btn"
                      type="submit"
                    >
                      <FormattedMessage
                        id="loginScreen.submitButton1"
                        defaultMessage=" Submit"
                      />
                    </button>
                  )}
              </form>
            </>
          ) : (
              <>
                <div className="login-header">
                  <h2 style={{ textAlign: "center" }}>
                    <FormattedMessage
                      id="loginScreen.signInLabel"
                      defaultMessage="Sign In"
                    />
                  </h2>
                </div>
                <form onSubmit={handleFormSubmit}>
                  <div className="form-group form-focus focused">
                    <input
                      type="email"
                      className="form-control floating"
                      defaultValue={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    <label className="focus-label">
                      <FormattedMessage
                        id="loginScreen.emailLabel"
                        defaultMessage="Email"
                      />
                    </label>
                  </div>
                  <div className="form-group form-focus focused">
                    <input
                      type="password"
                      className="form-control floating"
                      defaultValue={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label className="focus-label">
                      <FormattedMessage
                        id="loginScreen.passwordLabel"
                        defaultMessage="Password"
                      />
                    </label>
                  </div>
                  <div className="text-center">
                    <p style={{ color: "red" }}>{msg}</p>
                  </div>
                  <div className="text-right">
                    <Link to="/forgotPassword" className="forgot-link">
                      <FormattedMessage
                        id="loginScreen.forgotPassword"
                        defaultMessage="Forgot Password ?"
                      />
                    </Link>
                  </div>

                  {isLoading ? (
                    <div className="text-center">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#00d0f1" }}
                      >
                        <span className="sr-only">
                          <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                      </Spinner>
                    </div>
                  ) : (
                      <button
                        // className="btn btn-primary btn-block btn-lg login-btn"
                        className="btn btn-block btn-lg login-btn"
                        type="submit"
                      >
                        <FormattedMessage
                          id="loginScreen.signinButton1"
                          defaultMessage=" Sign In
"
                        />{" "}
                      </button>
                    )}

                  {/* Incorrect Email or Password. */}

                  <div className="text-center dont-have">
                    <FormattedMessage
                      id="loginScreen.noAccount"
                      defaultMessage="   Don’t have an account?"
                    />{" "}
                    <Link to="/signup">
                      <FormattedMessage
                        id="loginScreen.registerLink"
                        defaultMessage="Register"
                      />
                    </Link>
                  </div>
                </form>
              </>
            )}
        </Modal.Body>
      </Modal>
    </header>
  );
}

export default Header;
