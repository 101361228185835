// import "../assets/css/adminStyle.css";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import "../../assets/css/adminStyle.css";
// import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function UserDashboardDefaultScreen(props) {
  useEffect(() => {
    props.setActiveTab({
      dashboard: "active",
    });
  }, []);

  useEffect(() => {
    if (props.userData !== undefined) {
      getTransactionHistory();
    }
  }, [props.userData]);

  let [transactionData, setTransactionData] = useState([]);
  let getTransactionHistory = () => {
    axios
      .get(`/api/v1/mingacoin/transfer/history/${props.userData._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setTransactionData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      {props.userData !== "" ? (
        <>
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">
                  <FormattedMessage
                    id="user.defaultDashboard.heading"
                    defaultMessage="Welcome!"
                  />
                </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <FormattedMessage
                      id="user.defaultDashboard.dashboardBreadcrumb"
                      defaultMessage="Dashboard"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}

          <div className="row">
            <div className="col-12 col-md-5 col-offset-7">
              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4">
                        <i className="fas fa-user"></i>

                        <label
                          style={{ marginLeft: "10px", marginTop: "10px" }}
                        >
                          <FormattedMessage
                            id="user.defaultDashboard.accountLabel"
                            defaultMessage="Account"
                          />{" "}
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          disabled
                          type="text"
                          defaultValue={
                            props.userData.firstName +
                            " " +
                            props.userData.lastName
                          }
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4">
                        <i className="fas fa-money-bill-alt"></i>
                        <label
                          style={{ marginLeft: "10px", marginTop: "10px" }}
                        >
                          <FormattedMessage
                            id="user.defaultDashboard.balanceLabel"
                            defaultMessage="Balance"
                          />
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          disabled
                          type="text"
                          defaultValue={props.userData.mingaCoins}
                          className="form-control"
                          style={{ textAlign: "right" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 ">
              <div className="card">
                <div className="card-body">
                  <div className="col-12 col-md-12">
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "20px" }}
                    >
                      <table className="table table-hover table-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>
                              <FormattedMessage
                                id="user.defaultDashboard.table.dateLabel"
                                defaultMessage="Date"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="user.defaultDashboard.table.from"
                                defaultMessage="From"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="user.defaultDashboard.table.to"
                                defaultMessage="To"
                              />
                            </th>
                            <th>
                              <FormattedMessage
                                id="user.defaultDashboard.table.amountLabel"
                                defaultMessage="Amount"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {transactionData.map((i, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{moment(i.transferDate).format("LLL")}</td>
                              <td>
                                {i.fromUserId.firstName} {i.fromUserId.lastName}
                              </td>
                              <td>
                                {i.toUserId.firstName} {i.toUserId.lastName}
                              </td>
                              <td>{i.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default UserDashboardDefaultScreen;
