import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import mapMarker from "../../assets/img/icons8-marker-48.png";

const AnyReactComponent = () => (
  <div>
    <img src={mapMarker} />
  </div>
);

function MingaLocationMap(props) {
  let [center, setCenter] = useState({ lat: -0.180653, lng: -78.467834 });
  let [zoom, setZoom] = useState(14);
  let [draggable, setDraggable] = useState(true);

  let onChildMouseDown = () => {
    setDraggable(false);
  };

  let onChildMouseUp = () => {
    setDraggable(true);
  };

  let onChildMouseMove = (key, marker, newCoords) => {
    props.setMingaCoords({
      lat: newCoords.lat,
      lng: newCoords.lng,
    });
  };

  useEffect(() => {
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        setCenter({
          lat: coords.latitude,
          lng: coords.longitude,
        });
        props.setMingaCoords({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      });
    }
  }, []);

  return (
    // Important! Always set the container height explicitly
    <div id="map-section" style={{ height: "60vh", width: "100%" }}>
      <GoogleMapReact
        draggable={draggable}
        apiKey="AIzaSyDnkFfg3DqElEhRuRsZoBVCCLiJkH3PdBU"
        defaultCenter={center}
        defaultZoom={zoom}
        onChildMouseDown={onChildMouseDown}
        onChildMouseUp={onChildMouseUp}
        onChildMouseMove={onChildMouseMove}
      >
        <AnyReactComponent
          lat={props.mingaCoords.lat}
          lng={props.mingaCoords.lng}
        />
      </GoogleMapReact>
    </div>
  );
}

export default MingaLocationMap;
