// import AccountApprovalDetails from "./AccountApprovalDetails";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, Route, useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Input from "../../components/Input/AgentMessageInput";
import InfoBar from "../InfoBars/AgentInfoBar";
function AgentChat(props) {
    let { path, url } = useRouteMatch();
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
    const handleClose = () => setShow(false);
    let [userId, setUserId] = useState();
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    let [isSuspending, setIsSuspending] = useState(false);
    let [textareaData, settextareaData] = useState();

    let [isSaving, setIsSaving] = useState(false);
    let [isError, setIsError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();

    let [data, setData] = useState([]);
    let [isGettingData, setIsGettingData] = useState(false);
    let getData = () => {
        setIsGettingData(true);
        axios
            .get(`/api/v1/communityleaders/approvedaccounts`, {
                withCredentials: true,
            })
            .then((response) => {
                setData(response.data);
                setIsGettingData(false);
                console.log("RESSS", response.data);
            })
            .catch((error) => {
                setIsGettingData(false);
                console.log(error);
            });
    };
    useEffect(() => {
        props.setActiveTab({
            messages: "active",
        });
        getData();
    }, []);


    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
        { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
        { title: 'The Good, the Bad and the Ugly', year: 1966 },
        { title: 'Fight Club', year: 1999 },
        { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
        { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
        { title: 'Forrest Gump', year: 1994 },
    ];
    const Leaders = [
        { firstName: 'The Shawshank Redemption', lastName: 'Shakeel', lastMessage: "Ok Bye" },
        { firstName: 'Ahmad', lastName: 'Shakeel', lastMessage: "Ok Bye" },
        { firstName: 'Muhammad', lastName: 'Shakeel', lastMessage: "Hi" },
        { firstName: 'Ali', lastName: 'Shakeel', lastMessage: "Good hy" },
        { firstName: 'Zain', lastName: 'Shakeel', lastMessage: "Facebook pe" },
        { firstName: 'Zohaib', lastName: 'Shakeel', lastMessage: "Ok" },
        { firstName: 'Hammad', lastName: 'Shakeel', lastMessage: "Allah HAfiz" },
        { firstName: 'Adrees', lastName: 'Shakeel', lastMessage: "Ok" },
        { firstName: 'Arsal', lastName: 'Shakeel', lastMessage: "Ok theek hy" },
        { firstName: 'Fakhar', lastName: 'Shakeel', lastMessage: "Nice 1" },
        { firstName: 'Abdul Raheem', lastName: 'Shakeel', lastMessage: "Ok Bye" },

    ];
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            // textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    }));
    const sendMessage = (event) => {
        event.preventDefault();
        console.log(event, "EVENT");
        // if(message) {
        //   socket.emit('sendMessage', message, () => setMessage(''));
        // }
    }
    const classes = useStyles();
    return (

        <>
            <Route exact path={`${path}`}>
                <div className="card">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">
                                <FormattedMessage
                                    id="admin.totalCommunityLeaders.dashboardBreadcrumb"
                                    defaultMessage="Dashboard"
                                />
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to="/dashboard/messages">
                                <FormattedMessage
                                    id="agent.messages.messagesBreadcrumb"
                                    defaultMessage="Messages"
                                />
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <FormattedMessage
                                id="agent.Chat.chatBreadcrumb"
                                defaultMessage="Chat"
                            />
                        </li>
                    </ul>
                    <div className="card-body">
                        <Container>
                            <InfoBar></InfoBar>
                            {/* <Messages messages={messages} name={name} /> */}
                            <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
                            {/* <div className={classes.root}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                    <div className="">
                                        <Link  to="/dashboard/messages" id="back_user_list" >
                                            <i className="material-icons">chevron_left</i>
                                        </Link>
                                        <div className="media">
                                            <div className="media-img-wrap">
                                                <div className="avatar avatar-online">
                                                    <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div ><strong> Dr. Darren Elder</strong></div>
                                                <div ><i>online</i></div>
                                            </div>
                                        </div>
                                    </div>
                                    </Paper>
                                    <Paper className={classes.paper}>
                                    <div className="">
                                        <Link  to="/dashboard/messages" id="back_user_list" >
                                            <i className="material-icons">chevron_left</i>
                                        </Link>
                                        <div className="media">
                                            <div className="media-img-wrap">
                                                <div className="avatar avatar-online">
                                                    <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div ><strong> Dr. Darren Elder</strong></div>
                                                <div ><i>online</i></div>
                                            </div>
                                        </div>
                                    </div>
                                    </Paper>
                                    <Paper className={classes.paper}>
                                    <div className="">
                                        <Link  to="/dashboard/messages" id="back_user_list" >
                                            <i className="material-icons">chevron_left</i>
                                        </Link>
                                        <div className="media">
                                            <div className="media-img-wrap">
                                                <div className="avatar avatar-online">
                                                    <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="media-body">
                                                <div ><strong> Dr. Darren Elder</strong></div>
                                                <div ><i>online</i></div>
                                            </div>
                                        </div>
                                    </div>
                                    </Paper>
                                    
                                </Grid>
                            </Grid>
                        </div> */}
                        </Container>
                        {/* <SimpleBar style={{maxHeight: 100}}>
                                <Container>
                                    <div className={classes.root}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                <div className="">
                                                    <Link  to="/dashboard/messages" id="back_user_list" >
                                                        <i className="material-icons">chevron_left</i>
                                                    </Link>
                                                    <div className="media">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                                                <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div ><strong> Dr. Darren Elder</strong></div>
                                                            <div ><i>online</i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.root}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                <div className="">
                                                    <Link  to="/dashboard/messages" id="back_user_list" >
                                                        <i className="material-icons">chevron_left</i>
                                                    </Link>
                                                    <div className="media">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                                                <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div ><strong> Dr. Darren Elder</strong></div>
                                                            <div ><i>online</i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.root}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}>
                                                <div className="">
                                                    <Link  to="/dashboard/messages" id="back_user_list" >
                                                        <i className="material-icons">chevron_left</i>
                                                    </Link>
                                                    <div className="media">
                                                        <div className="media-img-wrap">
                                                            <div className="avatar avatar-online">
                                                                <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div ><strong> Dr. Darren Elder</strong></div>
                                                            <div ><i>online</i></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Container>
                            </SimpleBar> */}
                    </div>
                </div>
                {/* </div> */}
                {/* <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage
                                id="communityLeader.profileSetting.modalTitle"
                                defaultMessage="Successfully Updated"
                            />
                        </Modal.Title>
                    </Modal.Header>
                </Modal> */}
            </Route>
            {/* <Route path={`${path}/:companyId`}>
                <AccountApprovalDetails />
            </Route> */}
        </>
    );
}

export default AgentChat;
