import axios from "axios";
// import AccountApprovalDetails from "./AccountApprovalDetails";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Button, Modal, Row, Spinner, Container } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Route, useRouteMatch } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import MingaLocationMap from "../MingaLocationMap";

function CreateProject(props) {
  const mess = (
    <FormattedMessage
      id="createProject.error"
      defaultMessage="Signatory not found"
    />
  );

  const mess1 = (
    <FormattedMessage
      id="createProject.error1"
      defaultMessage="Cannot have same signatories on a single project"
    />
  );

  const mess2 = (
    <FormattedMessage
      id="createProject.error2"
      defaultMessage="You cannot be a signatory to your own project"
    />
  );

  const mess3 = (
    <FormattedMessage
      id="createProject.error3"
      defaultMessage="Internal server error"
    />
  );

  const mess4 = (
    <FormattedMessage
      id="createProject.error4"
      defaultMessage=" Click on Add Button before submitting"
    />
  );

  const mess5 = (
    <FormattedMessage
      id="createProject.error5"
      defaultMessage="Select Location before submitting"
    />
  );
  const mess6 = (
    <FormattedMessage
      id="createProject.error6"
      defaultMessage="Minga Project successfully submitted for approval."
    />
  );
  const mess7 = (
    <FormattedMessage
      id="createProject.error7"
      defaultMessage="Unknown Error Occured"
    />
  );

  let { path, url } = useRouteMatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  const [showMapModal, setShowMapModal] = useState(false);
  const handleCloseMapModal = () => {
    setShowMapModal(false);
    setMingaCoords({ ...mingaCoords, lat: "", lng: "" });
  }
  const handleCloseSucessMapModal = () => {
    setShowMapModal(false);
  }
  const handleShowMapModal = (e) => {
    setMapOpened(true);
    setShowMapModal(true);
    e.preventDefault();
  };

  let locale = Cookies.get("Locale");

  let [isLoadingCategories, setIsLoadingCategories] = useState();
  let [categories, setCategories] = useState([]);
  let getCategories = () => {
    setIsLoadingCategories(true);
    axios
      .get(`/api/v1/mingacategories`, { withCredentials: true })
      .then((response) => {
        setCategories(response.data);
        setIsLoadingCategories(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // console.log(Cookies.get("Locale"));
    getCategories();
    props.setActiveTab({
      createProject: "active",
    });
  }, []);

  let [projectName, setProjectName] = useState();
  let [volunteers, setVolunteers] = useState();
  let [projectCategory, setProjectCategory] = useState();
  let [projectDescription, setProjectDescription] = useState();
  let [signatory1Email, setSignatory1Email] = useState();
  let [signatory2Email, setSignatory2Email] = useState();
  let [signatory1Info, setSignatory1Info] = useState("");
  let [signatory2Info, setSignatory2Info] = useState("");

  let [error1, setError1] = useState();
  let [error2, setError2] = useState();
  let [isGettingSignatory1Data, setIsGettingSignatory1Data] = useState(false);
  let [isGettingSignatory2Data, setIsGettingSignatory2Data] = useState(false);
  let getSignatory1Info = (e) => {
    if (signatory1Email === signatory2Email) {
      // setError1("Cannot have same signatories on a single project");
      setError1(mess1);
    }
    setError1("");
    e.preventDefault();
    setSignatory1Info("");
    setIsGettingSignatory1Data(true);
    axios
      .get(
        `/api/v1/communityleaders/getsignatoryinfo/${props.userData._id}/${signatory1Email}`,
        { withCredentials: true }
      )
      .then((response) => {
        setSignatory1Info(response.data);
        setIsGettingSignatory1Data(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          if (error.response.status === 404) {
            // setError1("Signatory not found");
            setError1(mess);
          } else if (error.response.status === 601) {
            // setError1("You cannot be a signatory to your own project");
            setError1(mess2);
          } else {
            // setError1("Internal server error");
            setError1(mess3);
          }
        }
        setIsGettingSignatory1Data(false);
      });
  };
  let getSignatory2Info = (e) => {
    e.preventDefault();
    if (signatory1Email === signatory2Email) {
      // setError2("Cannot have same signatories on a single project");
      setError2(mess1);
      return;
    }
    setError2("");
    e.preventDefault();
    setSignatory2Info("");
    setIsGettingSignatory2Data(true);
    axios
      .get(
        `/api/v1/communityleaders/getsignatoryinfo/${props.userData._id}/${signatory2Email}`,
        { withCredentials: true }
      )
      .then((response) => {
        setSignatory2Info(response.data);
        setIsGettingSignatory2Data(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          if (error.response.status === 404) {
            // setError2("Signatory not found");
            setError2(mess);
          } else if (error.response.status === 601) {
            // setError2("You cannot be a signatory to your own project");
            setError2(mess2);
          } else {
            // setError2("Internal server error");
            setError2(mess3);
          }
        }
        setIsGettingSignatory2Data(false);
      });
  };

  let [isImageSelected, setIsImageSelected] = useState(false);
  let [fileType, setFileType] = useState([]);
  let [fileName, setFileName] = useState([]);
  let [imageData, setImageData] = useState([]);
  let onChangePictureHandler = (event) => {
    if (event.target.files.length > 0) {
      Array.from(event.target.files).forEach((i, index) => {
        let fileParts = i.name.split(".");
        let fn = fileName;
        fn.push(`${props.userData._id}${Date.now()}.${fileParts[1]}`);
        setFileName(fn);
        let ft = fileType;
        ft.push(fileParts[1]);
        setFileType(ft);
        let idata = imageData;
        idata.push(i);
        setImageData(idata);
      });
      setIsImageSelected(true);
    }
  };

  let [mapError, setMapError] = useState();
  let [msg, setMsg] = useState();
  let [isSavingChanges, setIsSavingChanges] = useState(false);
  let imageURLs = [];
  let handleSubmitProject = (e) => {
    setIsSavingChanges(true);
    setMapError("");
    e.preventDefault();
    if (mapOpened) {
      if (signatory1Info !== "" || signatory2Info !== "") {
        if (isImageSelected) {
          fileName.forEach((i, index) => {
            axios
              .post(
                "/api/v1/users/sign_s3",
                {
                  fileName: fileName[index],
                  fileType: fileType[index],
                },
                { withCredentials: true }
              )
              .then((response) => {
                var returnData = response.data.data.returnData;
                var signedRequest = returnData.signedRequest;
                var url = returnData.url;
                imageURLs.push(url);
                // delete axios.defaults.headers.common["Authorization"];
                // Put the fileType in the headers for the upload
                var options = {
                  headers: {
                    "Content-Type": fileType[index],
                  },
                  withCredentials: false,
                };
                axios
                  .put(signedRequest, imageData[index], options)
                  .then((result) => {
                    console.log(fileName.length, index);
                    // console.log(index);
                    if (fileName.length - 1 === index) {
                      sendToServer();
                    }
                  })
                  .catch((error) => {
                    if (process.env.NODE_ENV === "development") {
                      alert("ERROR " + JSON.stringify(error));
                      console.log(error);
                    }
                    setIsSavingChanges(false);
                  });
              })
              .catch((error) => {
                if (process.env.NODE_ENV === "development") {
                  alert(JSON.stringify(error));
                }
                setIsSavingChanges(false);
                // setMsg("Couldn't Get Signed URL ");
                // handleShowSuccessModal();
              });
          });
        } else {
          sendToServer();
        }
      } else {
        setIsSavingChanges(false);
        // setError1("Click on Add Button before submitting");
        setError1(mess4);
        // setError2("Click on Add Button before submitting");
        setError2(mess4);
      }
    } else {
      setIsSavingChanges(false);
      // setMapError("Select Location before submitting");
      setMapError(mess5);
    }
  };

  let sendToServer = () => {
    let data;
    data = {
      communityleader: props.userData._id,
      projectName: projectName,
      volunteers: volunteers,
      description: projectDescription,
      category: projectCategory,
      pictureURL: imageURLs,
      signatory1: signatory1Info._id,
      signatory2: signatory2Info._id,
      mingaCoords: mingaCoords,
    };
    axios
      .post(`/api/v1/mingaproject`, data, { withCredentials: true })
      .then((response) => {
        // setMsg("Minga Project successfully submitted for approval.");
        setMsg(mess6);
        setMapOpened(false);
        setIsFileSelected(false);
        setIsSavingChanges(false);
        handleShowSuccessModal();
      })
      .catch((error) => {
        // setMsg("Unknown Error Occured");
        setMsg(mess7);
        setIsSavingChanges(false);
        handleShowSuccessModal();
        console.log(error);
      });
  };

  let [mapOpened, setMapOpened] = useState(false);
  let [mingaCoords, setMingaCoords] = useState({
    lat: "",
    lng: "",
  });

  let [isFileSelected, setIsFileSelected] = useState(false);
  let fileInput;
  let triggerInputFile = (e) => {
    e.preventDefault();
    fileInput.click();
  };
  // console.log("MINGA CORDS lat", mingaCoords.lat);
  // console.log("MINGA CORDS lng", mingaCoords.lng);
  // console.log("MINGA CORDS", mingaCoords);

  return (
    <>
      <Route exact path={`${path}`}>
        <div className="card">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <FormattedMessage
                  id="communityLeader.createProject.dashboardBreadcrumb"
                  defaultMessage="Dashboard"
                />
              </a>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage
                id="communityLeader.createProject.createProjectBreadcrumb"
                defaultMessage="Create Project"
              />
            </li>
          </ul>
          <div className="card-body">
            <form onSubmit={handleSubmitProject}>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="communityLeader.createProject.projectName"
                        defaultMessage="Project Name"
                      />
                    </label>
                    <div className="form-group form-focus focused">
                      <input
                        type="text"
                        required
                        className="form-control "
                        // placeholder="Enter Name"
                        onChange={(e) => setProjectName(e.target.value)}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="communityLeader.placeholder.name"
                          defaultMessage="Enter Name"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  {/* <div className="form-group"> */}
                  <label>
                    <FormattedMessage
                      id="communityLeader.createProject.location"
                      defaultMessage="Location of Project"
                    />
                  </label>
                  {/* </div> */}
                  <div>
                    {" "}
                    <button
                      className="btn btn-outline-dark"
                      onClick={handleShowMapModal}
                    >
                      <FormattedMessage
                        id="communityLeader.createProject.selectLocationButton"
                        defaultMessage="Select Location"
                      />
                    </button>
                    <i
                      className="fas fa-map-marker-alt"
                      style={{ fontSize: "30px", marginLeft: "20px" }}
                    ></i>

                  </div>
                  {mingaCoords.lng === "" && mingaCoords.lat === "" ?
                    (
                      <div></div>
                    ) : (
                      <Container>
                        <Row style={{ textAlign: 'left' }}>
                          <Col>
                            <strong>
                              <FormattedMessage
                                id="communityLeader.createProject.latitude"
                                defaultMessage="Latitude :"
                              />
                            </strong>
                            {' '}{mingaCoords.lat}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <strong>
                              <FormattedMessage
                                id="communityLeader.createProject.longitude"
                                defaultMessage="Longitude :"
                              />
                            </strong>
                            {' '}{mingaCoords.lng}
                          </Col>
                        </Row>
                      </Container>
                    )}

                  <p style={{ color: "red" }}>{mapError}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="communityLeader.createProject.mingaCategory"
                        defaultMessage="Minga Category"
                      />
                    </label>

                    <select
                      className="select form-control floating"
                      required
                      onChange={(e) => {
                        setProjectCategory(e.target.value);
                      }}
                    >
                      {isLoadingCategories ? (
                        <>
                          <option value=""></option>
                          <option disabled>
                            <span className="sr-only">
                              <FormattedMessage
                                id="loading"
                                defaultMessage="Loading..."
                              />
                            </span>
                          </option>
                        </>
                      ) : (
                          <>
                            <option selected></option>
                            {categories.map((i) => (
                              <option key={i._id} value={i._id}>
                                {locale === "en-US" ||
                                  locale === "undefined" ||
                                  locale === null
                                  ? i.englishName
                                  : i.spanishName}
                              </option>
                            ))}
                          </>
                        )}
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="communityLeader.createProject.volunteers"
                        defaultMessage="Number of Minga Volunteers Needed"
                      />
                    </label>
                    <input
                      type="number"
                      required
                      className="form-control"
                      onChange={(e) => setVolunteers(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-4">
                  <label>
                    <FormattedMessage
                      id="communityLeader.createProject.Signatory1"
                      defaultMessage="Signatory 1"
                    />
                  </label>
                  <div className="form-group form-focus focused">
                    <input
                      type="email"
                      className="form-control"
                      // placeholder="Enter Email"
                      required
                      onChange={(e) =>
                        setSignatory1Email(e.target.value.toLowerCase())
                      }
                    />

                    <label className="focus-label">
                      <FormattedMessage
                        id="communityLeader.placeholder.email"
                        defaultMessage="Enter Email"
                      />
                    </label>
                  </div>
                  <p style={{ color: "red" }}>{error1}</p>
                </div>

                <div className="col-sm-2">
                  {isGettingSignatory1Data ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#00d0f1", marginTop: "35px" }}
                      >
                        <span className="sr-only">
                          <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                      </Spinner>
                    </>
                  ) : (
                      <button
                        type="submit"
                        // className="btn btn-primary"
                        className="btn"
                        style={{ marginTop: "35px" }}
                        onClick={getSignatory1Info}
                      >
                        <FormattedMessage
                          id="communityLeader.createProject.addButton1"
                          defaultMessage="Add"
                        />
                      </button>
                    )}
                </div>

                <div className="col-12 col-sm-6">
                  {signatory1Info !== "" ? (
                    <div className="row">
                      <div className="col-sm-4 form-group">
                        <label>
                          <FormattedMessage
                            id="communityLeader.createProject.Name"
                            defaultMessage="Name"
                          />
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            signatory1Info.firstName +
                            " " +
                            signatory1Info.lastName
                          }
                        />
                      </div>
                      <div className="col-sm-4 form-group">
                        <label>
                          <FormattedMessage
                            id="communityLeader.createProject.Phone"
                            defaultMessage="Phone"
                          />
                        </label>
                        <input
                          type="phone"
                          className="form-control"
                          disabled
                          defaultValue={signatory1Info.mobile}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row">
                <div className="col-sm-4">
                  <label>
                    <FormattedMessage
                      id="communityLeader.createProject.Signatory2"
                      defaultMessage="Signatory 2"
                    />
                  </label>
                  <div className="form-group form-focus focused">
                    <input
                      type="email"
                      className="form-control"
                      // placeholder="Enter Email"
                      required
                      onChange={(e) =>
                        setSignatory2Email(e.target.value.toLowerCase())
                      }
                    />
                    <label className="focus-label">
                      <FormattedMessage
                        id="communityLeader.placeholder.email"
                        defaultMessage="Enter Email"
                      />
                    </label>
                  </div>

                  <p style={{ color: "red" }}>{error2}</p>
                </div>
                <div className="col-sm-2">
                  {isGettingSignatory2Data ? (
                    <>
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#00d0f1", marginTop: "35px" }}
                      >
                        <span className="sr-only">
                          <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                      </Spinner>
                    </>
                  ) : (
                      <button
                        type="submit"
                        // className="btn btn-primary"
                        className="btn"
                        style={{ marginTop: "35px" }}
                        onClick={getSignatory2Info}
                      >
                        <FormattedMessage
                          id="communityLeader.createProject.addButton2"
                          defaultMessage="Add"
                        />
                      </button>
                    )}
                </div>
                <div className="col-12 col-sm-6">
                  {signatory2Info !== "" ? (
                    <div className="row">
                      <div className="col-sm-4 form-group">
                        <label>

                          <FormattedMessage
                            id="communityLeader.createProject.Name"
                            defaultMessage="Name"
                          />
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          defaultValue={
                            signatory2Info.firstName +
                            " " +
                            signatory2Info.lastName
                          }
                        />
                      </div>
                      <div className="col-sm-4 form-group">
                        <label>
                          <FormattedMessage
                            id="communityLeader.createProject.Phone"
                            defaultMessage="Phone"
                          />
                        </label>
                        <input
                          type="phone"
                          className="form-control"
                          disabled
                          defaultValue={signatory2Info.mobile}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <br />

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="communityLeader.createProject.description"
                        defaultMessage="Please describe the community project in detail. Please include a detailed list of volunteer job description needed to complete this project and the proposed Minga Unit compensation for each job type."
                      />
                    </label>
                    <textarea
                      required
                      type="text"
                      className="form-control"
                      rows="5"
                      // style={{ height: "50px" }}
                      onChange={(e) => setProjectDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="communityLeader.createProject.mingaImages"
                        defaultMessage="Add Minga Images"
                      />
                    </label>
                    <br />

                    <input
                      required
                      type="file"
                      name="sampleFile"
                      accept=".jpg,.png"
                      className="form-control"
                      multiple={true}
                      onChange={onChangePictureHandler}
                    // style={{ visibility: "hidden" }}
                    // ref={(fileInput1) => (fileInput = fileInput1)}
                    />
                    {/* <button
                      className="btn form-control"
                      onClick={triggerInputFile}
                    >
                      {" "}
                      <FormattedMessage
                        id="fileUpload.part1"
                        defaultMessage="Choose Files"
                      />{" "}
                      - {fileType.length}{" "}
                      <FormattedMessage
                        id="fileUpload.part2"
                        defaultMessage="Files Selected"
                      />
                    </button> */}

                    <small className="form-text text-muted">
                      <FormattedMessage
                        id="user.placead.allowedFormat"
                        defaultMessage="Allowed .jpg and .png"
                      />
                    </small>
                  </div>
                </div>
              </div>

              <div className="col-12 text-center">
                {isSavingChanges ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "#00d0f1" }}
                  >
                    <span className="sr-only">
                      <FormattedMessage
                        id="loading"
                        defaultMessage="Loading..."
                      />
                    </span>
                  </Spinner>
                ) : mingaCoords.lng === "" && mingaCoords.lng === "" || mingaCoords.lng === undefined && mingaCoords.lng === undefined ? (
                  <button disabled
                    type="submit"
                    // className="btn btn-primary"
                    className="btn"
                    style={{ width: "fit-content" }}
                  >
                    <FormattedMessage
                      id="communityLeader.createProject.sendApprovalButton2"
                      defaultMessage="Submit for Approval"
                    />
                  </button>
                ) : (<button
                  type="submit"
                  // className="btn btn-primary"
                  className="btn"
                  style={{ width: "fit-content" }}
                >
                  <FormattedMessage
                    id="communityLeader.createProject.sendApprovalButton2"
                    defaultMessage="Submit for Approval"
                  />
                </button>)}
              </div>
            </form>
          </div>
        </div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>{msg}</Modal.Title>
          </Modal.Header>
        </Modal>
        <Modal
          size="lg"
          show={showMapModal}
          backdrop="static"
          onHide={handleCloseMapModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id="communityLeader.createProject.modalTitle"
                defaultMessage="Drag the marker to the location"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <MingaLocationMap
              mingaCoords={mingaCoords}
              setMingaCoords={setMingaCoords}
            />
          </Modal.Body>
          <Modal.Footer>
            {mingaCoords.lat === "" && mingaCoords.lng === "" ? (
              <Button disabled>
                <FormattedMessage
                  id="communityLeader.createProject.setLocation"
                  defaultMessage="Set Location"
                />
              </Button>
            ) : (
                <Button onClick={handleCloseSucessMapModal}>
                  <FormattedMessage
                    id="communityLeader.createProject.setLocation"
                    defaultMessage="Set Location"
                  />
                </Button>)}
          </Modal.Footer>
        </Modal>
      </Route>
      {/* <Route path={`${path}/:companyId`}>
                <AccountApprovalDetails />
            </Route> */}
    </>
  );
}

export default CreateProject;
