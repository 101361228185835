import axios from "axios";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
// import "simplebar/src/simplebar.css";
// import "../../assets/css/adminStyle.css";
import "../../assets/css/adminStyle.css";
// import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";


function AdminSidebar(props) {
  let history = useHistory();
  let handleLogout = (e) => {
    // Cookies.remove("Authorization");
    // setTimeout(() => {}, 1);
    e.preventDefault();
    axios
      .get("/api/v1/auth/user/logout", { withCredentials: true })
      .then((response) => {
        console.log(response);
        props.checkLoginStatus();
        history.push("/");
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (

    <div
      className="sidebar"
      id="sidebar"
      style={{ backgroundColor: "#07631F" }}
    >
      <div className="sidebar-inner slimscroll">
        <SimpleBar style={{ maxHeight: 570 }}>
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="menu-title">
                <span>
                  <FormattedMessage
                    id="admin.sidebar.main"
                    defaultMessage="Main"
                  />
                </span>
              </li>
              <li className={props.activeTab.dashboard}>
                <Link to={`${props.match.url}`}>
                  <i className="fa fa-home"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.dashboard"
                      defaultMessage="Dashboard"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.categories}>
                <Link to={`${props.match.url}/categories`}>
                  <i className="fa fa-list-alt"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.categories"
                      defaultMessage="Categories"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.advertisementcategories}>
                <Link to={`${props.match.url}/advertisementcategories`}>
                  <i className="fa fa-list-alt"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.advertisementcategories"
                      defaultMessage="Advertisement Categories"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.uploadHomeImages}>
                <Link to={`${props.match.url}/uploadhomeimages`}>
                  <i className="fas fa-images"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.changeHomeImages"
                      defaultMessage="Change Home Images"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.totalCommunityLeaders}>
                <Link to={`${props.match.url}/totalcommunityleaders`}>
                  <i className="fas fa-users"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.totalCommunityLeaders"
                      defaultMessage="Total Community Leaders"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.totalUsers}>
                <Link to={`${props.match.url}/totalusers`}>
                  <i className="fas fa-user"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.users"
                      defaultMessage="Users"
                    />
                  </span>
                </Link>
              </li>
              {/* <li className={props.activeTab.totalAgents}>
              <Link to={`${props.match.url}/totalAgents`}>
                <i class="fas fa-user-secret"></i>{" "}
                <span>
                  <FormattedMessage
                    id="admin.sidebar.agents"
                    defaultMessage="Agents"
                  />
                </span>
              </Link>
            </li> */}
              <li className={props.activeTab.pendingRequests}>
                <Link to={`${props.match.url}/pendingrequests`}>
                  <i className="fa fa-hourglass"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.pendingRequests"
                      defaultMessage="Pending Requests"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.projectRequests}>
                <Link to={`${props.match.url}/projectrequests`}>
                  <i className="fa fa-building"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.projectRequests"
                      defaultMessage="Project Requests"
                    />
                  </span>
                </Link>
              </li>

              <li className="menu-title">
                <span>
                  <FormattedMessage
                    id="admin.sidebar.settings"
                    defaultMessage="Settings"
                  />
                </span>
              </li>
              {/* <li className={props.activeTab.contractDetails}>
                            <Link to={`${props.match.url}/smartcontractdetails`}>
                                <i className="fa fa-file-contract"></i>{" "}
                                <span>Smart Contract Details</span>
                            </Link>
                        </li> */}
              <li className={props.activeTab.settings}>
                <Link to={`${props.match.url}/profilesettings`}>
                  <i className="fa fa-cog"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.profileSettings"
                      defaultMessage="Profile Settings"
                    />
                  </span>
                </Link>
              </li>
              <li className={props.activeTab.changePassword}>
                <Link to={`${props.match.url}/changepassword`}>
                  <i className="fa fa-key"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.changePassword"
                      defaultMessage="Change Password"
                    />
                  </span>
                </Link>
              </li>
              <li>
                <Link to={"/"} onClick={handleLogout}>
                  <i className="fa fa-sign-out-alt"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="admin.sidebar.logout"
                      defaultMessage="Logout"
                    />
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </SimpleBar>
      </div>

    </div>

  );
}

export default AdminSidebar;
