import { wait } from "@testing-library/react";
import axios from "axios";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import { Link, Redirect } from "react-router-dom";
import ReactVerificationCodeInput from "react-verification-code-input";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import loginBanner from "../../assets/img/login-banner.png";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

function ForgotPassword() {

  const mess1 = (<FormattedMessage id="forgotPassword.error1"
    defaultMessage="User not found" />);

  const mess2 = (<FormattedMessage id="forgotPassword.error2"
    defaultMessage="Incorrect Pin Entered" />);
  const mess3 = (<FormattedMessage id="forgotPassword.error3"
    defaultMessage="Password and Confirm Password should be same." />);
  const mess4 = (
    <FormattedMessage
      id="forgotPassword.error4"
      defaultMessage="Cannot send Pin code email, try again."
    />
  );


  let [totalWaitTime, setTotalWaitTime] = useState(30);
  let [time, setTime] = useState(totalWaitTime);
  let [showCountDown, setShowCountDown] = useState(false);


  let [email, setEmail] = useState();
  let [pin, setPin] = useState();
  let [forgot, setForgot] = useState(false);
  let [pinEntered, setPinEntered] = useState(false);
  let [done, setDone] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [password, setPassword] = useState();
  let [confirmPassword, setConfirmPassword] = useState();
  let [msg, setMsg] = useState();
  let [isError, setIsError] = useState();
  let handleForgotPasswordSubmitEvent = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsError(false);

    setMsg();
    axios
      .post("/api/v1/users/forgotPassword", {
        email: email,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setForgot(true);
          var refreshIntervalId = setInterval(() => {
            if (time !== -1) {
              setTime(time--);
            }
            else {
              setShowCountDown(true)
              clearInterval(refreshIntervalId);
              setTotalWaitTime(30);
              setTime(totalWaitTime);
              // console.log("hello");
            }
          }, 1000)

          // console.log(response);
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
          }

          // setMsg("User not found");
          setMsg(mess1);
          setIsLoading(false);
          setIsError(true);
        }
      );
  };
  let ResendCode = () => {
    console.log("RESEND BUTTON",totalWaitTime);
    setTotalWaitTime(totalWaitTime*2);
    setShowCountDown(false);
    let data = {
      email: email,
      method: "reset_password"
    }

    console.log("RESEND BUTTON",data);
    axios
      .post("/api/v1/auth/user/resendEmail", data, { withCredentials: true })
      .then(
        (response) => {
          console.log(response);        
          // setIsLoading(false);
          // setForgot(true);

          // console.log(response);
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
          }

          // setMsg("User not found");
          setMsg(mess4);
          setIsLoading(false);
          setIsError(true);
        }
      );
    var refreshIntervalId = setInterval(() => {
      if (time !== -1) {
        setTime(time--);
      }
      else {
        setShowCountDown(true)
        clearInterval(refreshIntervalId);
        setTime(totalWaitTime);
        // console.log("hello");
      }
    }, 1000)

  }
  let handlePinSubmitEvent = () => {
    console.log("PIN SUBMIT");
    setIsLoading(true);
    setIsError(false);
    setMsg();
    console.log(pin);
    axios
      .post("/api/v1/users/verifyResetPasswordPin", {
        email: email,
        resetPasswordPin: pin,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setPinEntered(true);
          // console.log(response);
        },
        (error) => {
          if (process.env.NODE_ENV === "development") {
            console.log(error);
          }
          setPin();
          // setMsg("Incorrect Pin Entered");
          setMsg(mess2);
          setIsLoading(false);
          setIsError(true);
        }
      );
  };

  let handlePasswordSubmitEvent = (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsError(false);
    setMsg();
    if (confirmPassword === password) {
      // console.log("Email: " + email, "\nPassword: " + password);
      axios
        .post("/api/v1/users/resetPassword", {
          email: email,
          newPassword: password,
        })
        .then(
          (response) => {
            setIsLoading(false);
            setForgot(true);
            setDone(true);
            // console.log(response);
          },
          (error) => {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
            }
            // setMsg("Incorrect Pin Entered");
            setMsg(mess2);
            setIsLoading(false);
            setIsError(true);
          }
        );
    } else {
      // setMsg("Password and Confirm Password should be same.");
      setMsg(mess3);
      setIsLoading(false);
      setIsError(true);
    }
  };

  let handleChange = (value) => {
    console.log(value);
    setPin(value);
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <Header />

        {/* <!-- Page Content --> */}
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                {/* <!-- Account Content --> */}
                <div className="account-content">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-md-7 col-lg-6 login-left">
                      <img src={loginBanner} className="img-fluid" />
                    </div>
                    <div className="col-md-12 col-lg-6 login-right">
                      {/* <div className="login-header">
                        <h3>Forgot Password?</h3>
                        <p className="small text-muted">
                          Enter your email to get a password reset link
                        </p>
                      </div> */}

                      {/* <!-- Forgot Password Form --> */}
                      {!forgot ? (
                        <>
                          <div className="login-header">
                            <h3>
                              <FormattedMessage
                                id="forgotPassword.forgotPasswordLabel"
                                defaultMessage="Forgot Password?"
                              />
                            </h3>
                            <p className="small text-muted">
                              <FormattedMessage
                                id="forgotPassword.emailLinkMessage"
                                defaultMessage="   Enter your email to get a password reset link"
                              />
                            </p>
                          </div>
                          <form onSubmit={handleForgotPasswordSubmitEvent}>
                            <div className="form-group form-focus focused">
                              <input
                                autoFocus
                                required
                                type="email"
                                className="form-control floating"
                                value={email}
                                onChange={(e) => {
                                  setEmail(e.target.value);
                                }}
                              />
                              <label className="focus-label">
                                <FormattedMessage
                                  id="forgotPassword.emailLabel"
                                  defaultMessage="Email"
                                />
                              </label>
                            </div>
                            <div
                              className="text-center"
                              style={{ margin: "30px" }}
                            >
                              {isError ? (
                                <p style={{ color: "red" }}>{msg}</p>
                              ) : (
                                  <></>
                                )}
                            </div>
                            <div className="text-right">
                              <Link
                                to="/login"
                                className="forgot-link"
                                style={{ color: "#000000" }}
                              >
                                <FormattedMessage
                                  id="forgotPassword.rememberPasswordLink"
                                  defaultMessage="  Remember your password"
                                />
                              </Link>
                            </div>
                            {isLoading ? (
                              <div className="text-center">
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{ color: "#00d0f1" }}
                                >
                                  <span className="sr-only">
                                    <FormattedMessage
                                      id="forgotPassword.loading"
                                      defaultMessage="Loading..."
                                    />
                                  </span>
                                </Spinner>
                              </div>
                            ) : (
                                <button
                                  // className="btn btn-primary btn-block btn-lg login-btn"
                                  className="btn btn-block btn-lg login-btn"
                                  type="submit"
                                >
                                  <FormattedMessage
                                    id="forgotPassword.resetPasswordButton1"
                                    defaultMessage=" Reset Password"
                                  />
                                </button>
                              )}
                          </form>
                        </>
                      ) : !pinEntered ? (
                        <>
                          <div className="login-header">
                            <h3>
                              <FormattedMessage
                                id="forgotPassword.enterPinLabel"
                                defaultMessage="Enter Pin"
                              />
                            </h3>
                            <p className="small text-muted">
                              <FormattedMessage
                                id="forgotPassword.pinReceivedEmailLabel"
                                defaultMessage="  Enter pin you received in your email"
                              />
                            </p>
                          </div>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                          >
                            <div className="form-group form-focus">
                              {/* <input
                                required
                                type="text"
                                className="form-control floating"
                                defaultValue=""
                                value={pin}
                                onChange={(e) => {
                                  setPin(e.target.value);
                                }}
                              />
                              <label className="focus-label">
                                <FormattedMessage
                                  id="forgotPassword.pinLabel"
                                  defaultMessag="Pin"
                                />
                              </label> */}
                              <div
                                className="col-12"
                                style={{ textAlign: "center" }}
                              >
                                <div style={{ display: "inline-block" }}>
                                  <ReactVerificationCodeInput
                                    type={"text"}
                                    fields={4}
                                    autoFocus={true}
                                    onChange={handleChange}
                                    // onComplete={handlePinSubmitEvent}
                                    loading={isLoading}
                                    className="react-verification-code-mobile"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                              className="text-center"
                              style={{ margin: "30px" }}
                            >
                              {isError ? (
                                <p style={{ color: "red" }}>{msg}</p>
                              ) : (
                                  <></>
                                )}
                            </div>
                            <div className="text-right">
                              <Link
                                to="/login"
                                className="forgot-link"
                                style={{ color: "#000000" }}
                              >
                                <FormattedMessage
                                  id="forgotPassword.rememberPasswordLink"
                                  defaultMessag="  Remember your password"
                                />
                              </Link>
                            </div>
                            {isLoading ? (
                              <div className="text-center">
                                <Spinner
                                  animation="border"
                                  role="status"
                                  style={{ color: "00d0f1" }}
                                >
                                  <span className="sr-only">
                                    <FormattedMessage
                                      id="forgotPassword.loading"
                                      defaultMessage="Loading..."
                                    />
                                  </span>
                                </Spinner>
                              </div>
                            ) : showCountDown ? (
                              <>
                                <button
                                  // className="btn btn-primary btn-block btn-lg login-btn"
                                  className="btn btn-block btn-lg login-btn"
                                  type="submit"
                                  onClick={() => handlePinSubmitEvent()}
                                >
                                  <FormattedMessage
                                    id="forgotPassword.submitButton1"
                                    defaultMessage="Submit"
                                  />
                                </button>

                                <button
                                  // className="btn btn-primary btn-block btn-lg login-btn"
                                  className="btn btn-block btn-lg login-btn"
                                  onClick={ResendCode}
                                >
                                  <FormattedMessage
                                    id="forgotPassword.resendButton1"
                                    defaultMessage="Resend verification code"
                                  />
                                  
                                </button>
                              </>

                            ) : (
                                  <>
                                    <button
                                      // className="btn btn-primary btn-block btn-lg login-btn"
                                      className="btn btn-block btn-lg login-btn"
                                      type="submit"
                                      onClick={() => handlePinSubmitEvent()}
                                    >
                                      <FormattedMessage
                                        id="forgotPassword.submitButton1"
                                        defaultMessage="Submit"
                                      />
                                    </button>

                                    <button disabled
                                      // className="btn btn-primary btn-block btn-lg login-btn"
                                      className="btn btn-block btn-lg login-btn"
                                      type="submit"
                                    >
                                      <FormattedMessage
                                        id="forgotPassword.resendButton1"
                                        defaultMessage="Resend verification code"
                                      />
                                    </button>
                                    <p id="time">
                                    <FormattedMessage
                                        id="forgotPassword.timeLeft"
                                        defaultMessage="Time left: "
                                      />                                      
                                      {time}{' '} 
                                      <FormattedMessage
                                        id="forgotPassword.seconds"
                                        defaultMessage="seconds"
                                      />                         
                                      </p>
                                  </>

                                )}
                          </form>
                        </>
                      ) : (
                            <>
                              <div className="login-header">
                                <h3>
                                  <FormattedMessage
                                    id="forgotPassword.resetPasswordHeading"
                                    defaultMessage="Reset Password"
                                  />
                                </h3>
                                {/* <p className="small text-muted">
                          Enter pin you received in your email
                        </p> */}
                              </div>
                              <form onSubmit={handlePasswordSubmitEvent}>
                                <div className="form-group">
                                  <label>
                                    <FormattedMessage
                                      id="forgotPassword.newPasswordLabel"
                                      defaultMessage="New Password"
                                    />
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                </div>
                                <div className="form-group">
                                  <label>
                                    <FormattedMessage
                                      id="forgotPassword.confirmPasswordLabel"
                                      defaultMessage="Confirm Password"
                                    />
                                  </label>
                                  <input
                                    type="password"
                                    className="form-control"
                                    required
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                  />
                                </div>
                                <div
                                  className="text-center"
                                  style={{ margin: "30px" }}
                                >
                                  {isError ? (
                                    <p style={{ color: "red" }}>{msg}</p>
                                  ) : (
                                      <></>
                                    )}
                                </div>
                                <div className="submit-section">
                                  {isLoading ? (
                                    <div className="text-center">
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "00d0f1" }}
                                      >
                                        <span className="sr-only">
                                          <FormattedMessage
                                            id="forgotPassword.loading"
                                            defaultMessage="Loading..."
                                          />
                                        </span>
                                      </Spinner>
                                    </div>
                                  ) : (
                                      <button
                                        // className="btn btn-primary btn-block btn-lg login-btn"
                                        className="btn btn-block btn-lg login-btn"
                                        type="submit"
                                      >
                                        <FormattedMessage
                                          id="forgotPassword.saveChangesButton2"
                                          defaultMessage="Save Changes"
                                        />
                                      </button>
                                    )}
                                </div>
                              </form>
                            </>
                          )}
                      {done ? <Redirect to="/login" /> : <></>}
                      {/* <!-- /Forgot Password Form --> */}
                    </div>
                  </div>
                </div>
                {/* <!-- /Account Content --> */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Page Content --> */}
        <Footer position="absolute" />
      </div>
    </div>
  );
}

export default ForgotPassword;
