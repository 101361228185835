import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import HomeMap from "../Home/HomeMap";
import MeetTeamBlock from "../Home/MeetTeamBlock";
import MingaBlock1 from "../Home/MingaBlock1";
import StartMinga from "../Home/StartMingaBlock4";
import WhatIsMingaBlock2 from "../Home/WhatIsMingaBlock2";
import WhyBarterMingaBlock3 from "../Home/WhyBarterMingaBlock3";



const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#ffffff",
  },
}));

function HomeScreen(props) {
  const classes = useStyles();
  let [isGettingImages, setIsGettingImages] = useState(false);

  let [carouselimages, setCarouselImages] = useState([]);

  let getImages = () => {
    setIsGettingImages(true);

    axios
      .get("/homepagepicture")
      .then((response) => {
        setCarouselImages(response.data);
        setIsGettingImages(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <>
      {isGettingImages ? (
        <Backdrop className={classes.backdrop} open={isGettingImages}>
          <CircularProgress color="#ffffff" />
        </Backdrop>
      ) : (
          <div className="main-wrapper">
            <Header
              setlocal={props.setlocal}
              selectedNav={"home"}
              setIsLoggedIn={props.setIsLoggedIn}
              setUser={props.setUser}
            />
            <MingaBlock1 carouselimages={carouselimages} local={props.local} />
            <WhatIsMingaBlock2
              carouselimages={carouselimages}
              local={props.local}
            />
            <WhyBarterMingaBlock3 />
            <StartMinga />
            <MeetTeamBlock />
            <HomeMap />

            <Footer position={"relative"} />
          </div>
        )}
    </>
  );
}

export default HomeScreen;
