import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FormattedMessage } from "react-intl";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";


function ProfileSetting(props) {
  let [isImageSelected, setIsImageSelected] = useState(false);
  let [imageData, setImageData] = useState();
  let [mobileInput, setMobileInput] = useState();
  let [isSavingChanges, setIsSavingChanges] = useState(false);
  let [isFieldsChanged, setIsFieldChanged] = useState(false);
  let [check, setCheck] = useState(false);
  let handleSet2FAcheck = (e) => {
    setCheck(!check);
    setIsFieldChanged(true);
  };
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  let [fileType, setFileType] = useState();
  let [fileName, setFileName] = useState();
  let onChangePictureHandler = (event) => {
    if (event.target.files[0] !== undefined) {
      let fileParts = event.target.files[0].name.split(".");
      setFileName(`${props.userData._id}${Date.now()}`);
      setFileType(fileParts[1]);
      setImageData(event.target.files[0]);
      setIsImageSelected(true);
    }
  };
  useEffect(() => {
    if (props.userData) {
      if (props.userData._2FA) {
        setCheck(props.userData._2FA);
      }
    }
    props.setActiveTab({
      dashboard: "",
      transferPayment: "",
      settings: "active",
      userProjects: "",
      changePassword: "",
    });
  }, []);
  let onSubmitHandleEvent = (event) => {
    setIsSavingChanges(true);
    event.preventDefault();
    if (isImageSelected) {
      axios
        .post(
          "/api/v1/users/sign_s3",
          {
            fileName: fileName,
            fileType: fileType,
          },
          { withCredentials: true }
        )
        .then((response) => {
          var returnData = response.data.data.returnData;
          var signedRequest = returnData.signedRequest;
          var url = returnData.url;
          delete axios.defaults.headers.common["Authorization"];
          // Put the fileType in the headers for the upload
          var options = {
            headers: {
              "Content-Type": fileType,
            },
            withCredentials: false,
          };
          axios
            .put(signedRequest, imageData, options)
            .then((result) => {
              axios
                .post(
                  `/api/v1/users/upload/image/${props.userData._id}`,
                  {
                    url: url,
                  },
                  { withCredentials: true }
                )
                .then((response) => {
                  if (!isFieldsChanged) {
                    setIsSavingChanges(false);
                    handleShowSuccessModal();
                    props.handleDataUpdated();
                  }
                })
                .catch((error) => {
                  setIsSavingChanges(false);
                  if (process.env.NODE_ENV === "development") {
                    console.log(error);
                  }
                });
            })
            .catch((error) => {
              if (process.env.NODE_ENV === "development") {
                alert("ERROR " + JSON.stringify(error));
                console.log(error);
              }
              setIsSavingChanges(false);

              // setMsg("Couldn't Upload File, try again");
              // handleShowSuccessModal();
            });
        })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            alert(JSON.stringify(error));
          }
          setIsSavingChanges(false);
          // setMsg("Couldn't Get Signed URL ");
          // handleShowSuccessModal();
        });
    }
    if (isFieldsChanged) {
      let data = {
        mobile: mobileInput,
        _2FA: check,
        whatsapp: whatsapp,
        facebook: facebook,
        address: address,
        city: city,
        province: province,
      };
      axios
        .put(`/api/v1/users/${props.userData._id}`, data, {
          withCredentials: true,
        })
        .then((response) => {
          setIsSavingChanges(false);
          handleShowSuccessModal();
          props.handleDataUpdated();
        })
        .catch((error) => {
          setIsSavingChanges(false);
          if (process.env.NODE_ENV === "development") {
            console.log(error);
          }
        });
    }
  };

  let [whatsapp, setWhatsapp] = useState();
  let [facebook, setFacebook] = useState();
  let [address, setAddress] = useState();
  let [city, setCity] = useState();
  let [province, setProvince] = useState();
  let [description, setDescription] = useState();

  return (
    <>
      {props.userData.firstName !== undefined ? (
        <div className="card">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <FormattedMessage
                  id="user.profileSettings.dashboardBreadcrumb"
                  defaultMessage="Dashboard"
                />
              </a>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage
                id="user.profileSettings.profileSettingsBreadcrumb"
                defaultMessage="Profile Settings"
              />
            </li>
          </ul>
          <div className="card-body">
            {/* // <!-- Profile Settings Form --> */}
            <form onSubmit={onSubmitHandleEvent}>
              <div className="row form-row">
                <div className="col-12 col-md-12">
                  <div className="form-group">
                    <div className="change-avatar">
                      <div className="profile-img">
                        {props.userData.pictureURL !== undefined ? (
                          <img
                            src={props.userData.pictureURL}
                            alt="User Image"
                          />
                        ) : (
                            <>
                              <div
                                style={{
                                  background: "#E9ECEF",
                                  width: "100px",
                                  height: "100px",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#00D0F1",
                                    fontSize: "24px",
                                    marginLeft: "35%",
                                    paddingTop: "33%",
                                  }}
                                >
                                  <strong>{props.userData.firstName[0]}</strong>
                                </p>
                              </div>
                            </>
                          )}
                      </div>
                      <div className="upload-img">
                        <div className="change-photo-btn">
                          <span>
                            <i className="fa fa-upload"></i>
                            <FormattedMessage
                              id="user.profileSettings.uploadButton1"
                              defaultMessage="Upload Photo"
                            />
                          </span>
                          <input
                            name="sampleFile"
                            type="file"
                            className="upload"
                            accept=".png,.jpg,.jpeg"
                            onChange={onChangePictureHandler}
                          />
                        </div>
                        <small className="form-text text-muted">
                        <FormattedMessage
                              id="user.profileSettings.allow"
                              defaultMessage="Allowed JPG, JPEG, PNG. Max size of 5MB"
                            />

                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.firstName"
                        defaultMessage="First Name"
                      />
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      defaultValue={props.userData.firstName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.lastName"
                        defaultMessage="Last Name"
                      />
                    </label>
                    <input
                      disabled
                      type="text"
                      className="form-control"
                      defaultValue={props.userData.lastName}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.emailId"
                        defaultMessage="Email ID"
                      />
                    </label>
                    <input
                      disabled
                      type="email"
                      className="form-control"
                      defaultValue={props.userData.email}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.idCard"
                        defaultMessage="Identity Card Number"
                      />
                    </label>
                    <input
                      disabled
                      type="number"
                      className="form-control"
                      defaultValue={props.userData.idCard}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.mobile"
                        defaultMessage=" Mobile"
                      />
                    </label>
                    <input
                      // pattern="[+]852[0-9]{8}"
                      type="text"
                      defaultValue={props.userData.mobile}
                      className="form-control"
                      onChange={(e) => {
                        setMobileInput(e.target.value);
                        setIsFieldChanged(true);
                      }}
                      required
                    />
                    {/* <small>Format: +852XXXXXXXX</small> */}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.whatsapp"
                        defaultMessage="Whatsapp"
                      />
                    </label>
                    <input
                      type="text"
                      defaultValue={props.userData.whatsapp}
                      className="form-control"
                      onChange={(e) => {
                        setWhatsapp(e.target.value);
                        setIsFieldChanged(true);
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.facebook"
                        defaultMessage="Facebook URL"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={props.userData.facebook}
                      onChange={(e) => {
                        setIsFieldChanged(true);
                        setFacebook(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.address"
                        defaultMessage="Address"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={props.userData.address}
                      onChange={(e) => {
                        setIsFieldChanged(true);
                        setAddress(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.city"
                        defaultMessage="City"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={props.userData.city}
                      onChange={(e) => {
                        setIsFieldChanged(true);
                        setCity(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.province"
                        defaultMessage="Province"
                      />
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={props.userData.province}
                      onChange={(e) => {
                        setIsFieldChanged(true);
                        setProvince(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.description"
                        defaultMessage="Description"
                      />
                    </label>
                    <textarea
                      rows="4"
                      className="form-control"
                      defaultValue={props.userData.description}
                      onChange={(e) => {
                        setIsFieldChanged(true);
                        setDescription(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="user.profileSettings.twoFactor"
                        defaultMessage="Two Factor Authentication"
                      />
                    </label>
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      label={
                        <FormattedMessage
                          id="profilesettings.2FA"
                          defaultMessage="If enabled, you will receive a security code for login!"
                        />
                      }
                      checked={check}
                      onChange={handleSet2FAcheck}
                    />
                  </div>
                </div>
              </div>
              <div className="submit-section">
                {isSavingChanges ? (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#00D0F1" }}
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id="loading"
                          defaultMessage="Loading..."
                        />
                      </span>
                    </Spinner>
                  </div>
                ) : (
                    <div className="text-center">
                      <button
                        type="submit"
                        // className="btn btn-primary submit-btn"
                        className="btn submit-btn"
                      >
                        <FormattedMessage
                          id="user.profileSettings.saveChangesButton2"
                          defaultMessage="Save Changes"
                        />
                      </button>
                    </div>
                  )}
              </div>
            </form>
            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
              <Modal.Header closeButton>
                <Modal.Title>
                  <FormattedMessage
                    id="user.profileSettings.modalSuccess"
                    defaultMessage="Successfully Updated"
                  />
                </Modal.Title>
              </Modal.Header>
            </Modal>
          </div>
        </div>
      ) : (
          <></>
        )}
    </>
  );
}
export default ProfileSetting;
