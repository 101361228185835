import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams, useRouteMatch } from "react-router-dom";
import "../../assets/css/adminStyle.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function TicketDetailScreen() {
  const { path } = useRouteMatch();
  const { communityleaderId } = useParams();
  let [data, setData] = useState();
  let [msgs, setMsgs] = useState([]);
  let [isGettingData, setIsGettingData] = useState(true);
  let getTicketDetails = () => {
    // console.log(ticketId);
    setIsGettingData(true);
    axios
      .get(`/api/v1/ticket/accountapproval/${communityleaderId}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setMsgs(response.data.comments);
        setIsGettingData(false);
      })
      .catch((error) => {
        console.log(error);
        setIsGettingData(false);
      });
  };

  useEffect(() => {
    getTicketDetails();
  }, []);

  let getTicketDetailsAgain = () => {
    axios
      .get(`/api/v1/ticket/accountapproval/${communityleaderId}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setMsgs(response.data.comments);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [inputMsg, setInputMsg] = useState("");
  let handleSendMsg = () => {
    if (inputMsg === "" || inputMsg === undefined) {
    } else {
      let data = {
        msg: inputMsg,
        role: "communityleader",
      };
      axios
        .post(`/api/v1/ticket/accountapproval/msg/${communityleaderId}`, data, {
          withCredentials: true,
        })
        .then((response) => {
          setInputMsg("");
          setMsgs(response.data.comments);
          getTicketDetailsAgain();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div className="card">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="/dashboard">
            <FormattedMessage
              id="communityLeader.mymingas.dashboardBreadcrumb"
              defaultMessage="Dashboard"
            /></a>
        </li>
        <li className="breadcrumb-item">
          <FormattedMessage
            id="communityLeader.ticketDetails.companyRequest"
            defaultMessage="Company Request" /></li>
        <li className="breadcrumb-item">
          <FormattedMessage
            id="communityLeader.ticketDetails.companyDetails"
            defaultMessage="Company Details" /></li>
        <li className="breadcrumb-item active">
          <FormattedMessage
            id="communityLeader.ticketDetails.ticketDetails"
            defaultMessage="Ticket Details" /></li>
      </ul>

      {isGettingData ? (
        <> </>
      ) : (
          <div className="card-body">
            <h3>
              {data.communityleader.firstName} {data.communityleader.lastName}
              {" - "}
              {data.subject}
            </h3>

            <div clasName="page wrapper" style={{ width: "154%" }}>
              <div className="content container-fluid">
                <div className="chat-cont-right">
                  <div className="chat-body">
                    <div className="chat-scroll">
                      <ul className="list-unstyled">
                        {msgs.map((i) => (
                          <>
                            {i.role === "communityleader" ? (
                              <>
                                <li className="media sent">
                                  <div className="media-body">
                                    <div className="msg-box">
                                      <div>
                                        <p>{i.msg}</p>
                                        <ul className="chat-msg-info">
                                          <li>
                                            <div className="chat-time">
                                              <span>
                                                {moment(i.date).format("h:mm A")}

                                                {" - "}
                                                {moment(i.date).format("LL")}
                                              </span>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </>
                            ) : (
                                <>
                                  <li className="media received">
                                    {data.communityleader.pictureURL !==
                                      undefined ? (
                                        <div className="avatar">
                                          <img
                                            src={data.communityleader.pictureURL}
                                            alt="User Image"
                                            className="avatar-img rounded-circle"
                                          />
                                        </div>
                                      ) : (
                                        <a
                                          style={{ background: "#cccccc" }}
                                          href="doctor-profile.html"
                                          className="avatar avatar-sm mr-2 avatar-img rounded-circle"
                                          onClick={(e) => {
                                            e.preventDefault();
                                          }}
                                        >
                                          <p
                                            style={{
                                              paddingLeft: "10px",
                                              paddingBottom: "20px",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <strong>
                                              {data.communityleader.firstName[0]}
                                            </strong>
                                          </p>
                                        </a>
                                      )}
                                    <div className="media-body">
                                      <div className="msg-box">
                                        <div>
                                          <p>{i.msg}</p>
                                          <ul className="chat-msg-info">
                                            <li>
                                              <div className="chat-time">
                                                <span>
                                                  {moment(i.date).format("h:mm A")}

                                                  {" - "}
                                                  {moment(i.date).format("LL")}
                                                </span>
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              )}
                          </>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div class="chat-footer">
                    <div class="input-group">
                      {/* <div class="input-group-prepend">
                      <div class="btn-file btn">
                        <i class="fa fa-paperclip"></i>
                        <input type="file" />
                      </div>
                    </div> */}
                      <input
                        value={inputMsg}
                        type="text"
                        class="input-msg-send form-control"
                        placeholder="Type something"
                        onChange={(e) => setInputMsg(e.target.value)}
                      />
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn msg-send-btn"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSendMsg();
                          }}
                        >
                          <i class="fab fa-telegram-plane"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );
}

export default TicketDetailScreen;
