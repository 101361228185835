import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
// import AccountApprovalDetails from "./AccountApprovalDetails";
import { FormattedMessage } from "react-intl";
import { useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function SendMinga(props) {

  const mess1 = (<FormattedMessage
    id="communityLeader.sendMinga.error1"
    defaultMessage="User not found." />);

  const mess2 = (<FormattedMessage
    id="communityLeader.sendMinga.error2"
    defaultMessage="Internal Server Error." />);

  const mess3 = (<FormattedMessage
    id="communityLeader.sendMinga.error3"
    defaultMessage="Coins cannot be sent to Community Leaders." />);

  const mess4 = (<FormattedMessage
    id="communityLeader.sendMinga.error4"
    defaultMessage="Unknown error occurred, try again." />);

  const mess5 = (<FormattedMessage
    id="communityLeader.sendMinga.mess5"
    defaultMessage="Request Successfully Submitted For Approval By Project Signatories" />);

  const mess6 = (<FormattedMessage
    id="communityLeader.sendMinga.error6"
    defaultMessage="Invalid request body." />);

  const mess7 = (<FormattedMessage
    id="communityLeader.sendMinga.error7"
    defaultMessage="Enter Valid Amount" />);


  let { path, url } = useRouteMatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  let [searchError, setSearchError] = useState("");
  let [sendToUser, setSendToUser] = useState("");
  let [isGettingSendToUserDetails, setIsGettingSendToUserDetails] = useState(
    false
  );
  let getSendToUserDetails = (e) => {
    setSendToUser("");
    setSearchError("");
    e.preventDefault();
    setIsGettingSendToUserDetails(true);
    axios
      .post(
        `/api/v1/mingacoin/transfer/usersearch`,
        { email: email },
        { withCredentials: true }
      )
      .then((response) => {
        setSendToUser(response.data);
        setIsGettingSendToUserDetails(false);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 404) {
            // setSearchError("User not found.");
            setSearchError(mess1);
          } else if (error.response.status === 500) {
            // setSearchError("Internal Server Error.");
            setSearchError(mess2);
          } else if (error.response.status === 600) {
            // setSearchError("Coins cannot be sent to Community Leaders.");
            setSearchError(mess3);
          } else {
            // setSearchError("Unknown error occurred, try again.");
            setSearchError(mess4);
          }
        } else {
          // setSearchError("Unknown error occurred, try again.");
          setSearchError(mess4);
        }
        setIsGettingSendToUserDetails(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getUserProjects();
    props.setActiveTab({
      sendMinga: "active",
    });
  }, []);

  let [email, setEmail] = useState();
  let [amount, setAmount] = useState();

  let [msg, setMsg] = useState();
  let [isTransfering, setIsTransfering] = useState(false);
  let [transferErrors, setTransferError] = useState("");
  let handleTransfer = (e) => {
    e.preventDefault();
    setTransferError("");
    setIsTransfering(true);
    if (
      amount === 0 ||
      amount === null ||
      amount === undefined ||
      amount === "" ||
      amount === "0"
    ) {
      console.log("It came here");
      // setTransferError("Enter Valid Amount");
      setTransferError(mess7);
      setIsTransfering(false);
      return;
    }
    let data = {
      communityleader: props.userData._id,
      userId: sendToUser._id,
      amount: amount,
      projectId: selectedProject,
      signatory1Id: signatory1,
      signatory2Id: signatory2,
    };
    axios
      .post(`/api/v1/mingacoin/mingacoinrequest`, data, {
        withCredentials: true,
      })
      .then((response) => {
        setSendToUser(response.data);
        setSendToUser("");
        setAmount("");
        setEmail("");
        setMsg(
          // "Request Successfully Submitted For Approval By Project Signatories"
          mess5
        );
        setIsTransfering(false);
        handleShowSuccessModal();
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            // setTransferError("Invalid request body.");
            setTransferError(mess6);
          }
          if (error.response.status === 404) {
            // setTransferError("User not found.");
            setTransferError(mess1);
          } else if (error.response.status === 500) {
            // setTransferError("Internal Server Error.");
            setTransferError(mess2);
          } else {
            // setTransferError("Unknown error occurred, try again.");
            setTransferError(mess4);
          }
        } else {
          // setTransferError("Unknown error occurred, try again.");
          setTransferError(mess4);
        }
        setIsTransfering(false);
        console.log(error);
      });
  };

  let [selectedProject, setSelectedProject] = useState();
  let [signatory1, setSignatory1] = useState();
  let [signatory2, setSignatory2] = useState();
  let [projects, setProjects] = useState([]);
  let [isGettingProjects, setIsGettingProjects] = useState(false);
  let getUserProjects = () => {
    setIsGettingProjects(true);
    axios
      .get(`/api/v1/mingaproject/approvedprojects/${props.userData._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setProjects(response.data);
        setIsGettingProjects(false);
      })
      .catch((error) => {
        setIsGettingProjects(false);
        console.log(error);
      });
  };

  let handleSelectproject = (id) => {
    projects.forEach((i) => {
      if (i._id === id) {
        setSignatory1(i.signatory1);
        setSignatory2(i.signatory2);
      }
    });
    if (id === "") {
      setSignatory1();
      setSignatory2();
    }
  };

  return (
    <>
      {props.userData.firstName !== undefined ? (
        <>
          <div className="card">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">
                  <FormattedMessage
                    id="user.transferPayment.dashboardBreadcrumb"
                    defaultMessage="Dashboard"
                  />
                </a>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage
                  id="user.transferPayment.transferPaymentBreadcrumb"
                  defaultMessage="Transfer Payment"
                />
              </li>
            </ul>
            <div className="card-body">
              <h3>
                <FormattedMessage
                  id="user.transferPayment.headingLabel"
                  defaultMessage="Transfer Minga"
                />
              </h3>
              <br />

              <form onSubmit={getSendToUserDetails}>
                <div className="form-group ">
                  <div className="row">
                    <div className="col-md-4">
                      <label>
                        <FormattedMessage
                          id="user.transferPayment.toUser"
                          defaultMessage="To User (Quick Search)"
                        />
                      </label>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group form-focus focused">
                        <input
                          type="email"
                          className="form-control"
                          required
                          value={email}
                          onChange={(e) =>
                            setEmail(e.target.value.toLowerCase())
                          }
                        // defaultValue={props.userData.name}
                        />
                        <label className="focus-label">
                          <FormattedMessage
                            id="user.transferPayment.searchUser"
                            defaultMessage="Search user using email"
                          />
                        </label>
                      </div>
                      <div className="row no-gutters">
                        <p style={{ color: "red", paddingLeft: "3%" }}>
                          {searchError}
                        </p>
                      </div>
                      {sendToUser !== "" ? (
                        <>
                          <div className="form-group ">
                            <label className="form-label">
                              <FormattedMessage
                                id="user.transferPayment.name"
                                defaultMessage="Name"
                              />
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue={
                                sendToUser.firstName + " " + sendToUser.lastName
                              }
                              disabled
                            />
                          </div>
                          <div className="form-group ">
                            <label className="form-label">
                              <FormattedMessage
                                id="user.transferPayment.mobile"
                                defaultMessage="Mobile"
                              />
                            </label>
                            <input
                              type="tel"
                              className="form-control"
                              disabled
                              defaultValue={sendToUser.mobile}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>

                    <div className="col-sm-2" style={{ paddingTop: "4px" }}>
                      <label> </label>
                      {/* <button className="btn btn-primary"> */}
                      {isGettingSendToUserDetails ? (
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "#00d0f1" }}
                        >
                          <span className="sr-only">
                            <FormattedMessage
                              id="loading"
                              defaultMessage="Loading..."
                            />
                          </span>
                        </Spinner>
                      ) : (
                          <>
                            <button className="btn" type="submit">
                              {" "}
                              {/* <i className="fa fa-search" aria-hidden="true"></i> */}
                              <FormattedMessage
                                id="user.getDetails.button"
                                defaultMessage="Get Details"
                              />
                            </button>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </form>

              <form onSubmit={handleTransfer}>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <label>
                        <FormattedMessage
                          id="user.transferPayment.project"
                          defaultMessage="Select Project"
                        />
                      </label>
                    </div>
                    <div className="col-md-4">
                      <select
                        className="select form-control floating"
                        defaultValue=""
                        required
                        onChange={(e) => {
                          setSelectedProject(e.target.value);
                          handleSelectproject(e.target.value);
                        }}
                      >
                        {isGettingProjects ? (
                          <>
                            <option value=""></option>
                            <option disabled>
                              <span className="sr-only">
                                <FormattedMessage
                                  id="loading"
                                  defaultMessage="Loading..."
                                />
                              </span>
                            </option>
                          </>
                        ) : (
                            <>
                              <option value=""></option>
                              {projects.map((i) => (
                                <option key={i._id} value={i._id}>
                                  {i.projectName}
                                </option>
                              ))}
                            </>
                          )}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <label>
                        <FormattedMessage
                          id="user.transferPayment.amount"
                          defaultMessage="Amount"
                        />
                      </label>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group form-focus focused">
                        <input
                          min="1"
                          type="number"
                          className="form-control"
                          value={amount}
                          onChange={(e) => setAmount(e.target.value)}
                        />
                        <label className="focus-label">
                          <FormattedMessage
                            id="user.searchServices.amountPlaceholder"
                            defaultMessage="Enter amount"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
                {sendToUser !== "" ? (
                  <div className="text-center">
                    {isTransfering ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#00d0f1" }}
                      >
                        <span className="sr-only">
                          <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                      </Spinner>
                    ) : (
                        <>
                          <p
                            style={{
                              color: "red",

                              marginBottom: "3%",
                            }}
                          >
                            {transferErrors}
                          </p>

                          <button
                            type="submit"
                            // className="btn btn-primary"
                            className="btn"
                            style={{ width: "fit-content" }}
                          >
                            <FormattedMessage
                              id="user.transferPayment.submitButton"
                              defaultMessage=" Submit"
                            />
                          </button>
                        </>
                      )}
                  </div>
                ) : null}
              </form>
            </div>
          </div>

          <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
            <Modal.Header closeButton>
              <Modal.Title>{msg}</Modal.Title>
            </Modal.Header>
          </Modal>
        </>
      ) : null}
    </>
  );
}

export default SendMinga;
