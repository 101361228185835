// import AccountApprovalDetails from "./AccountApprovalDetails";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, Route, useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import photo from "../../assets/img/MINGAS_LOGO1.png";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";


function AgentMessages(props) {
    let { path, url } = useRouteMatch();
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);
    const handleClose = () => setShow(false);
    let [userId, setUserId] = useState();
    const handleShow = () => setShow(true);
    const [show, setShow] = useState(false);
    let [isSuspending, setIsSuspending] = useState(false);
    let [textareaData, settextareaData] = useState();

    let [isSaving, setIsSaving] = useState(false);
    let [isError, setIsError] = useState(false);
    let [errorMsg, setErrorMsg] = useState();

    let [data, setData] = useState([]);
    let [isGettingData, setIsGettingData] = useState(false);
    let getData = () => {
        setIsGettingData(true);
        axios
            .get(`/api/v1/communityleaders/approvedaccounts`, {
                withCredentials: true,
            })
            .then((response) => {
                setData(response.data);
                setIsGettingData(false);
                console.log("RESSS", response.data);
            })
            .catch((error) => {
                setIsGettingData(false);
                console.log(error);
            });
    };
    useEffect(() => {
        props.setActiveTab({
            messages: "active",
        });
        getData();
    }, []);

    // const toggleChecked = (e) => {
    //   console.log("HELLO",e._id);
    //   console.log( "/api/v1/auth/user/suspend/"+e._id);
    //   if(e.suspended===false)
    //   {
    //       axios.put("/api/v1/auth/user/suspend/"+e._id,{ withCredentials: true})
    //       .then((response) => {
    //           console.log("Res",response);
    //           getData();
    //       })
    //       .catch((error) => {
    //           console.log(error);
    //       });

    //   }
    //   else
    //   {
    //       axios.put("/api/v1/auth/user/unsuspend/"+e._id,{ withCredentials: true})
    //       .then((response) => {
    //           console.log("Res",response);
    //           getData();
    //       })
    //       .catch((error) => {
    //           console.log(error);
    //       });

    //   }
    // };
    //   const suspendUser = (e) => {
    //     console.log("USER ID", userId);
    //     // console.log("E._ID",e._id);
    //     setIsSuspending(true);
    //     let data = {
    //       reason: textareaData,
    //     };
    //     axios
    //       .put("/api/v1/suspension/suspend/" + userId, data, {
    //         withCredentials: true,
    //       })
    //       .then((response) => {
    //         console.log("Res", response);
    //         handleClose();
    //         setIsSuspending(false);
    //         getData();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //       });
    //   };
    //   const toggleChecked = (e) => {
    //     console.log("HELLO", e);
    //     setUserId(e._id);
    //     if (e.suspended === true) {
    //       let data = {
    //         reason: "",
    //       };
    //       axios
    //         .put("/api/v1/suspension/unsuspend/" + e._id, data, {
    //           withCredentials: true,
    //         })
    //         .then((response) => {
    //           console.log("Res", response);
    //           getData();
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   };
    //   const handleChange = (event) => {
    //     settextareaData(event.target.value);
    //   };
    //   const suspendandSetLeader = (e) => {
    //     console.log("Adrees", e);
    //     setUserId(e._id);
    //     handleShow(true);
    //   };
    //   const exportCSV = () => {
    //     // console.log("EXPORING",data);
    //     var csvRow = [];
    //     var array = [
    //       [
    //         "Sr. No.",
    //         "First_Name",
    //         "Last_Name",
    //         "Email",
    //         "Phone Number",
    //         "Identification_Card_Number",
    //         "Description",
    //       ],
    //     ];
    //     var dataForCSV = data;
    //     for (var i = 0; i < dataForCSV.length; i++) {
    //       array.push([
    //         i + 1,
    //         dataForCSV[i].firstName,
    //         dataForCSV[i].lastName,
    //         dataForCSV[i].email,
    //         dataForCSV[i].mobile,
    //         dataForCSV[i].idCard,
    //         dataForCSV[i].description,
    //       ]);
    //     }
    //     // console.warn(array);
    //     for (var i = 0; i < array.length; ++i) {
    //       csvRow.push(array[i].join(","));
    //     }
    //     var csvString = csvRow.join("%0A");
    //     // console.warn("csvString",csvString);
    //     var a = document.createElement("a");
    //     a.href = "data:attachment/csv," + csvString;
    //     a.target = "_Blank";
    //     a.download = "CommunityLeader.csv";
    //     document.body.appendChild(a);
    //     a.click();
    //   };
    const top100Films = [
        { title: 'The Shawshank Redemption', year: 1994 },
        { title: 'The Godfather', year: 1972 },
        { title: 'The Godfather: Part II', year: 1974 },
        { title: 'The Dark Knight', year: 2008 },
        { title: '12 Angry Men', year: 1957 },
        { title: "Schindler's List", year: 1993 },
        { title: 'Pulp Fiction', year: 1994 },
        { title: 'The Lord of the Rings: The Return of the King', year: 2003 },
        { title: 'The Good, the Bad and the Ugly', year: 1966 },
        { title: 'Fight Club', year: 1999 },
        { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001 },
        { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980 },
        { title: 'Forrest Gump', year: 1994 },
        { title: 'Inception', year: 2010 },
        { title: 'The Lord of the Rings: The Two Towers', year: 2002 },
        { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
        { title: 'Goodfellas', year: 1990 },
        { title: 'The Matrix', year: 1999 },
        { title: 'Seven Samurai', year: 1954 },
        { title: 'Star Wars: Episode IV - A New Hope', year: 1977 },
        { title: 'City of God', year: 2002 },
        { title: 'Se7en', year: 1995 },
        { title: 'The Silence of the Lambs', year: 1991 },
        { title: "It's a Wonderful Life", year: 1946 },
        { title: 'Life Is Beautiful', year: 1997 },
        { title: 'The Usual Suspects', year: 1995 },
        { title: 'Léon: The Professional', year: 1994 },
        { title: 'Spirited Away', year: 2001 },
        { title: 'Saving Private Ryan', year: 1998 },
        { title: 'Once Upon a Time in the West', year: 1968 },
        { title: 'American History X', year: 1998 },
        { title: 'Interstellar', year: 2014 },
        { title: 'Casablanca', year: 1942 },
        { title: 'City Lights', year: 1931 },
        { title: 'Psycho', year: 1960 },
        { title: 'The Green Mile', year: 1999 },
        { title: 'The Intouchables', year: 2011 },
        { title: 'Modern Times', year: 1936 },
        { title: 'Raiders of the Lost Ark', year: 1981 },
        { title: 'Rear Window', year: 1954 },
        { title: 'The Pianist', year: 2002 },
        { title: 'The Departed', year: 2006 },
        { title: 'Terminator 2: Judgment Day', year: 1991 },
        { title: 'Back to the Future', year: 1985 },
        { title: 'Whiplash', year: 2014 },
        { title: 'Gladiator', year: 2000 },
        { title: 'Memento', year: 2000 },
        { title: 'The Prestige', year: 2006 },
        { title: 'The Lion King', year: 1994 },
        { title: 'Apocalypse Now', year: 1979 },
        { title: 'Alien', year: 1979 },
        { title: 'Sunset Boulevard', year: 1950 },
        { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964 },
        { title: 'The Great Dictator', year: 1940 },
        { title: 'Cinema Paradiso', year: 1988 },
        { title: 'The Lives of Others', year: 2006 },
        { title: 'Grave of the Fireflies', year: 1988 },
        { title: 'Paths of Glory', year: 1957 },
        { title: 'Django Unchained', year: 2012 },
        { title: 'The Shining', year: 1980 },
        { title: 'WALL·E', year: 2008 },
        { title: 'American Beauty', year: 1999 },
        { title: 'The Dark Knight Rises', year: 2012 },
        { title: 'Princess Mononoke', year: 1997 },
        { title: 'Aliens', year: 1986 },
        { title: 'Oldboy', year: 2003 },
        { title: 'Once Upon a Time in America', year: 1984 },
        { title: 'Witness for the Prosecution', year: 1957 },
        { title: 'Das Boot', year: 1981 },
        { title: 'Citizen Kane', year: 1941 },
        { title: 'North by Northwest', year: 1959 },
        { title: 'Vertigo', year: 1958 },
        { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983 },
        { title: 'Reservoir Dogs', year: 1992 },
        { title: 'Braveheart', year: 1995 },
        { title: 'M', year: 1931 },
        { title: 'Requiem for a Dream', year: 2000 },
        { title: 'Amélie', year: 2001 },
        { title: 'A Clockwork Orange', year: 1971 },
        { title: 'Like Stars on Earth', year: 2007 },
        { title: 'Taxi Driver', year: 1976 },
        { title: 'Lawrence of Arabia', year: 1962 },
        { title: 'Double Indemnity', year: 1944 },
        { title: 'Eternal Sunshine of the Spotless Mind', year: 2004 },
        { title: 'Amadeus', year: 1984 },
        { title: 'To Kill a Mockingbird', year: 1962 },
        { title: 'Toy Story 3', year: 2010 },
        { title: 'Logan', year: 2017 },
        { title: 'Full Metal Jacket', year: 1987 },
        { title: 'Dangal', year: 2016 },
        { title: 'The Sting', year: 1973 },
        { title: '2001: A Space Odyssey', year: 1968 },
        { title: "Singin' in the Rain", year: 1952 },
        { title: 'Toy Story', year: 1995 },
        { title: 'Bicycle Thieves', year: 1948 },
        { title: 'The Kid', year: 1921 },
        { title: 'Inglourious Basterds', year: 2009 },
        { title: 'Snatch', year: 2000 },
        { title: '3 Idiots', year: 2009 },
        { title: 'Monty Python and the Holy Grail', year: 1975 },
    ];
    const Leaders = [
        { firstName: 'The Shawshank Redemption', lastName: 'Shakeel', lastMessage: "Ok Bye" },
        { firstName: 'Ahmad', lastName: 'Shakeel', lastMessage: "Ok Bye" },
        { firstName: 'Muhammad', lastName: 'Shakeel', lastMessage: "Hi" },
        { firstName: 'Ali', lastName: 'Shakeel', lastMessage: "Good hy" },
        { firstName: 'Zain', lastName: 'Shakeel', lastMessage: "Facebook pe" },
        { firstName: 'Zohaib', lastName: 'Shakeel', lastMessage: "Ok" },
        { firstName: 'Hammad', lastName: 'Shakeel', lastMessage: "Allah HAfiz" },
        { firstName: 'Adrees', lastName: 'Shakeel', lastMessage: "Ok" },
        { firstName: 'Arsal', lastName: 'Shakeel', lastMessage: "Ok theek hy" },
        { firstName: 'Fakhar', lastName: 'Shakeel', lastMessage: "Nice 1" },
        { firstName: 'Abdul Raheem', lastName: 'Shakeel', lastMessage: "Ok Bye" },

    ];
    return (
        <>
            <Route exact path={`${path}`}>
                <div className="card">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/dashboard">
                                <FormattedMessage
                                    id="admin.totalCommunityLeaders.dashboardBreadcrumb"
                                    defaultMessage="Dashboard"
                                />
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <FormattedMessage
                                id="agent.messages.messafesBreadcrumb"
                                defaultMessage="Messages"
                            />
                        </li>
                    </ul>
                    <div className="card-body">
                        {/* <button
              style={{ float: "right", marginBottom: "10px" }}
              className="btn"
              onClick={exportCSV}
            > 
             <FormattedMessage
                id="admin.communityLeaders.downloadCSV"
                defaultMessage="Download CSV"
              />
            </button> */}
                        <div className="table-responsive" style={{ paddingTop: "20px" }}>
                            <table className="table table-hover table-center mb-0">
                                <thead>

                                    {/* <Autocomplete
                                        id="Search-box"
                                        options={top100Films}
                                        getOptionLabel={(option) => option.title}
                                        // style={{ width: 300 }}
                                        renderInput={(params) => <TextField {...params} label="Search" variant="outlined" />}
                                    /> */}
                                </thead>
                                <tbody>
                                    <div >
                                        {/* <div className="chat-header">
                                            <span>Chats</span>
                                            <a href="javascript:void(0)" className="chat-compose">
                                                <i className="material-icons">control_point</i>
                                            </a>
                                        </div> */}
                                        <Container>
                                            <Row>
                                                <Col xs="12">
                                                    <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                                                        Search
                                                </Form.Label>
                                                    <InputGroup className="mb-2">
                                                        <FormControl id="inlineFormInputGroup" placeholder="Username" />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text><i className="fas fa-search"></i></InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Link to="messages/chat" className="media">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-away">
                                                            <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <Container>
                                                        <div className="media-body">
                                                            <div>
                                                                <strong><div className="user-name">Dr. Ruby Perrin</div></strong>
                                                                <div className="user-last-chat">Hey, How are you?</div>
                                                            </div>
                                                            <div>
                                                                <i><div className="last-chat-time block">2 min</div></i>
                                                                <div className="badge badge-success badge-pill">15</div>
                                                            </div>
                                                        </div>
                                                    </Container>
                                                </Link>
                                            </Row>
                                            <hr></hr>
                                            <Row>
                                                <a href="javascript:void(0);" className="media">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-away">
                                                            <img src={photo} alt="User Image" className="avatar-img rounded-circle" />
                                                        </div>
                                                    </div>
                                                    <Container>
                                                        <div className="media-body">
                                                            <div>
                                                                <strong><div className="user-name">Dr. Ruby Perrin</div></strong>
                                                                <div className="user-last-chat">Hey, How are you?</div>
                                                            </div>
                                                            <div>
                                                                <i><div className="last-chat-time block">2 min</div></i>
                                                                <div className="badge badge-success badge-pill">15</div>
                                                            </div>
                                                        </div>
                                                    </Container>
                                                </a>
                                            </Row>
                                        </Container>

                                        {/* <form className="chat-search">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <i className="fas fa-search"></i>
                                                </div>
                                                <input type="text" className="form-control" placeholder="Search"/>
                                            </div>
                                        </form> */}
                                        <div className="chat-body" >
                                            <div className="chat-scroll">
                                                {/*        <a href="javascript:void(0);" className="media">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-away">
                                                             <img src="assets/img/doctors/doctor-thumb-01.jpg" alt="User Image" className="avatar-img rounded-circle"/> 
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div>
                                                            <div className="user-name">Dr. Ruby Perrin</div>
                                                            <div className="user-last-chat">Hey, How are you?</div>
                                                        </div>
                                                        <div>
                                                            <div className="last-chat-time block">2 min</div>
                                                            <div className="badge badge-success badge-pill">15</div>
                                                        </div>
                                                    </div>
                                                </a> */}
                                                {/* <hr></hr> */}
                                                {/* <a href="javascript:void(0);" className="media read-chat active">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-online">
                                                            <img src="assets/img/doctors/doctor-thumb-02.jpg" alt="User Image" className="avatar-img rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div>
                                                            <div className="user-name">Dr. Darren Elder</div>
                                                            <div className="user-last-chat">I'll call you later </div>
                                                        </div>
                                                        <div>
                                                            <div className="last-chat-time block">8:01 PM</div>
                                                        </div>
                                                    </div>
                                                </a> */}
                                                {/* <hr></hr> */}
                                                {/* <a href="javascript:void(0);" className="media">
                                                    <div className="media-img-wrap">
                                                        <div className="avatar avatar-away">
                                                            <img src="assets/img/doctors/doctor-thumb-03.jpg" alt="User Image" className="avatar-img rounded-circle"/>
                                                        </div>
                                                    </div>
                                                    <div className="media-body">
                                                        <div>
                                                            <div className="user-name">Dr. Deborah Angel</div>
                                                            <div className="user-last-chat">Give me a full explanation about our project</div>
                                                        </div>
                                                        <div>
                                                            <div className="last-chat-time block">7:30 PM</div>
                                                            <div className="badge badge-success badge-pill">3</div>
                                                        </div>
                                                    </div>
                                                </a> */}
                                            </div>
                                        </div>
                                    </div>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage
                                id="communityLeader.profileSetting.modalTitle"
                                defaultMessage="Successfully Updated"
                            />
                        </Modal.Title>
                    </Modal.Header>
                </Modal>
            </Route>
            {/* <Route path={`${path}/:companyId`}>
                <AccountApprovalDetails />
            </Route> */}
        </>
    );
}

export default AgentMessages;
