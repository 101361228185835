import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Card, CardDeck, Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function MyAds(props) {
  useEffect(() => {
    props.setActiveTab({
      myads: "active",
    });
    getMyAds();
  }, []);

  let [myAds, setMyAds] = useState([]);
  let getMyAds = () => {
    axios
      .get(`/api/v1/placement/ads/myAds`, { withCredentials: true })
      .then((response) => {
        console.log("RES", response.data)
        setMyAds(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const toggleChecked = (e) => {
    console.log("HELLO", e.hidden);
    let data;
    // setUserId(e._id);
    if (e.hidden === true) {
      data = {
        hidden: false,
      };
    } else {
      data = {
        hidden: true,
      };
    }
    // console.log(data);
    axios
      .put("/api/v1/placement/hideAdvertisement/" + e._id, data, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Res", response);
        getMyAds();
        //   getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let locale = Cookies.get("Locale");
  // let { path, url } = useRouteMatch();
  return (
    <>
      {/* <div className="card"> */}
      <Card>
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="index.html">
              <FormattedMessage
                id="user.myAds.dashboardBreadcrumb"
                defaultMessage="Dashboard"
              />
            </a>
          </li>
          <li className="breadcrumb-item active">
            <FormattedMessage
              id="user.myAds.myAds"
              defaultMessage="My Ads"
            />
          </li>
        </ul>
        
        <div className="card-body">
          <div className="col-12 col-md-12">
        <CardDeck>
          {myAds.map((item, index) => (
            <Col xs={6} md={4}>
              <Card style={{ width: '15rem' }}>
                <Card.Header>
                  <h3 style={{ textAlign: "center" }}><u>{item.title}</u></h3>
                  <Card.Img variant="top" height="150" src={item.pictureURLs[0]} />
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    {item.category !== undefined ? (
                      <Row>
                        <p className="search-result-ad-text">
                          <strong>
                            <FormattedMessage id="user.adWidget.category"
                              defaultMessage="Category :" />
                          </strong>
                          {locale === "en-US" || locale === undefined ? (item.category.englishName) : (item.category.spanishName)}
                        </p>
                      </Row>
                    ) : (null)}
                    <Row>
                      <p className="search-result-ad-text">
                        <strong>
                          <FormattedMessage id="user.adWidget.type"
                            defaultMessage="Type :" />
                        </strong>
                        {item.adType}
                      </p>
                    </Row>
                    <Row>
                      <p className="search-result-ad-text">
                        <strong>
                          <FormattedMessage id="user.adWidget.location"
                            defaultMessage="Location :" />
                        </strong>
                        {item.location}
                      </p>
                    </Row>
                    <Row>
                      <p className="search-result-ad-text">
                        <strong>
                          <FormattedMessage id="user.adWidget.description"
                            defaultMessage="Description :" />
                        </strong>
                        {item.description}
                      </p>
                    </Row>
                    <Row>
                      <p className="search-result-ad-text">
                        <strong>
                          <FormattedMessage id="user.adWidget.date"
                            defaultMessage="Date :" />
                        </strong>
                        {item.addedDate}
                      </p>
                    </Row>
                    <Row>
                      <p className="search-result-ad-price">
                        <strong>
                          <FormattedMessage id="user.adWidget.price"
                            defaultMessage="Price :" />
                        </strong>
                        {item.price}
                      </p>
                    </Row>
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <FormGroup>
                    <FormControlLabel
                      value="Show"
                      control={
                        <Switch color="primary"
                          checked={item.hidden}
                          onChange={() => toggleChecked(item)}
                        />}
                      label={<FormattedMessage id="user.adWidget.hide"
                        defaultMessage="Hide" />
                      }
                      labelPlacement="end"
                    />
                  </FormGroup>
                </Card.Footer>
              </Card>
              {/* <MyAdWidget key={item._id} ad={item} ></MyAdWidget> */}
            </Col>
          ))}
        </CardDeck>
        </div>
        </div>
        {/* </div> */}
      </Card>
    </>
  );
}

export default MyAds;
