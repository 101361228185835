import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useRouteMatch } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(18),
    height: theme.spacing(18),
    display: "inline-block",
  },
}));

function AdvertisementWidget(props) {
  let locale = Cookies.get("Locale");
  let { path, url } = useRouteMatch();
  const classes = useStyles();
  // console.log("PROPSSSS,",props.ad);
  return (
    <>

      <div className="card">
        <Link to={`/marketplace/${props.ad._id}`}>
          <div className="card-body search-result-widget">
            <div className="row" style={{ textAlign: "center" }}>
              <div
                className={`col-12 ${classes.root}`}
                style={{ display: "inline-block" }}
              >
                <Avatar
                  variant="square"
                  alt="Ad Image"
                  src={props.ad.pictureURLs[0]}
                  className={classes.large}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="search-result-ad-title">{props.ad.title}</p>
                {props.ad.category !== undefined ? (
                  <p className="search-result-ad-text">
                    <FormattedMessage id="marketPlace.adWidget.category"
                      defaultMessage="Category:" /> {locale === "en-US" || locale === undefined ? (props.ad.category.englishName) : (props.ad.category.spanishName)}
                  </p>
                ) : (<div></div>)}
                {/* {locale === "en-US" || locale === undefined
                                ? i.englishName
                                : i.spanishName} */}
                <p className="search-result-ad-text">
                  <FormattedMessage id="marketPlace.adWidget.type"
                    defaultMessage="Type:" /> {props.ad.adType}</p>
                <p className="search-result-ad-text">
                  <FormattedMessage id="marketPlace.adWidget.location"
                    defaultMessage="Location:" /> {props.ad.location}
                </p>
                <p className="search-result-ad-price">
                  <FormattedMessage id="marketPlace.adWidget.price"
                    defaultMessage="Price:" /> {props.ad.price}
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>

    </>
  );
}

export default AdvertisementWidget;
