import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Route, useRouteMatch } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function PlaceAd(props) {
  let locale = Cookies.get("Locale");
  let { path, url } = useRouteMatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  let [advertisementCategories, setAdvertisementCategories] = useState([]);
  let [
    isLoadingAdvertisementCategories,
    setIsLoadingAdvertisementCategories,
  ] = useState(false);
  let getAdvertisementCategories = () => {
    setIsLoadingAdvertisementCategories(true);
    axios
      .get(`/api/v1/advertisementcategories`, { withCredentials: true })
      .then((response) => {
        setAdvertisementCategories(response.data);
        setIsLoadingAdvertisementCategories(false);
        console.log(response.data);
      })
      .catch((error) => {
        setIsLoadingAdvertisementCategories(false);
        console.log(error);
      });
  };
  useEffect(() => {
    props.setActiveTab({
      placead: "active",
    });
    getAdvertisementCategories();
  }, []);

  let [title, setTitle] = useState();
  let [location, setLocation] = useState();
  let [selectedCategory, setSelectedCategory] = useState();
  let [price, setPrice] = useState();
  let [description, setDescription] = useState();
  let [adType, setAdType] = useState();

  let [isImageSelected, setIsImageSelected] = useState(false);
  let [fileType, setFileType] = useState([]);
  let [fileName, setFileName] = useState([]);
  let [imageData, setImageData] = useState([]);
  let onChangePictureHandler = (event) => {
    if (event.target.files.length > 0) {
      let fn = [],
        ft = [],
        idata = [];
      Array.from(event.target.files).forEach((i, index) => {
        let fileParts = i.name.split(".");
        fn.push(`${props.userData._id}${Date.now()}.${fileParts[1]}`);
        ft.push(fileParts[1]);
        idata.push(i);
      });
      setFileName(fn);
      setFileType(ft);
      setImageData(idata);
      setIsImageSelected(true);
      console.log(fileName);
    }
  };

  let [msg, setMsg] = useState();
  let [isSavingChanges, setIsSavingChanges] = useState(false);
  let imageURLs = [];
  let [pictureURLs, setPictureURLs] = useState();
  let handleSubmitAd = (e) => {
    setIsSavingChanges(true);
    e.preventDefault();
    if (isImageSelected) {
      console.log("Comming here");
      fileName.forEach((i, index) => {
        axios
          .post(
            "/api/v1/users/sign_s3",
            {
              fileName: fileName[index],
              fileType: fileType[index],
            },
            { withCredentials: true }
          )
          .then((response) => {
            console.log("Comming success here");
            var returnData = response.data.data.returnData;
            var signedRequest = returnData.signedRequest;
            var url = returnData.url;
            // console.log(imageURLs);
            imageURLs.push(url);
            // delete axios.defaults.headers.common["Authorization"];
            // Put the fileType in the headers for the upload
            var options = {
              headers: {
                "Content-Type": fileType[index],
              },
              withCredentials: false,
            };
            axios
              .put(signedRequest, imageData[index], options)
              .then((result) => {
                console.log(fileName.length, index);
                // console.log(index);
                if (fileName.length - 1 === index) {
                  sendToServer();
                }
              })
              .catch((error) => {
                if (process.env.NODE_ENV === "development") {
                  alert("ERROR " + JSON.stringify(error));
                  console.log(error);
                }
                setIsSavingChanges(false);
              });
          })
          .catch((error) => {
            console.log("Comming fail here");
            if (process.env.NODE_ENV === "development") {
              alert(JSON.stringify(error));
            }
            setIsSavingChanges(false);
            // setMsg("Couldn't Get Signed URL ");
            // handleShowSuccessModal();
          });
      });
    } else {
      sendToServer();
    }
  };

  let sendToServer = () => {
    console.log("Is it coming here");
    let data;
    data = {
      userId: props.userData._id,
      title: title,
      location: location,
      category: selectedCategory,
      price: price,
      description: description,
      adType: adType,
      pictureURLs: imageURLs,
    };
    axios
      .post(`/api/v1/placement`, data, { withCredentials: true })
      .then((response) => {
        setMsg("Advertisment Successfully Placed");
        setIsSavingChanges(false);
        handleShowSuccessModal();
      })
      .catch((error) => {
        setMsg("Unknown Error Occured");
        setIsSavingChanges(false);
        handleShowSuccessModal();
        console.log(error);
      });
  };

  let [isFileSelected, setIsFileSelected] = useState(false);
  let fileInput;
  let triggerInputFile = (e) => {
    e.preventDefault();
    fileInput.click();
  };

  return (
    <>
      <Route exact path={`${path}`}>
        <div className="card">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">
                <FormattedMessage
                  id="user.placead.dashboardBreadcrumb"
                  defaultMessage="Dashboard"
                />
              </a>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage
                id="user.placead.placeadBreadcrumb"
                defaultMessage="Put Services"
              />
            </li>
          </ul>
          <div className="card-body">
            <form onSubmit={handleSubmitAd}>
              <div className="form-group">
                <div className="row">
                  {/* <div className="col-12 col-md-6"> */}
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.title"
                        defaultMessage="Title"
                      />
                    </label>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-focus focused">
                      <input
                        defaultValue={title}
                        type="text"
                        className="form-control"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="user.placead.title"
                          defaultMessage="Title"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  {/* <div className="col-12 col-md-6"> */}
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.location"
                        defaultMessage="Location"
                      />
                    </label>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-focus focused">
                      <input
                        defaultValue={location}
                        type="text"
                        className="form-control"
                        onChange={(e) => setLocation(e.target.value)}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="user.placead.location"
                          defaultMessage="Location"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-12 col-md-6"> */}
              <div className="form-group">
                <div className="row">
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.category"
                        defaultMessage="Category"
                      />
                    </label>
                  </div>

                  <div className="col-md-4">
                    {" "}
                    <select
                      required
                      defaultValue=""
                      className="select form-control floating"
                      onChange={(e) => {
                        setSelectedCategory(e.target.value);
                      }}
                    >
                      {isLoadingAdvertisementCategories ? (
                        <>
                          <option value=""></option>
                          <option disabled>
                            <FormattedMessage
                              id="user.placead.loading"
                              defaultMessage="Loading..."
                            />
                          </option>
                        </>
                      ) : (
                          <>
                            <option value=""></option>
                            {advertisementCategories.map((i) => (
                              <option key={i._id} value={i._id}>
                                {locale === "en-US" || locale === undefined
                                  ? i.englishName
                                  : i.spanishName}
                              </option>
                            ))}
                          </>
                        )}
                    </select>
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* <br /> */}
              <div className="form-group">
                <div className="row">
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.price"
                        defaultMessage="Price"
                      />
                    </label>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-focus focused">
                      <input
                        defaultValue={price}
                        type="text"
                        className="form-control"
                        style={{ textAlign: "right" }}
                        onChange={(e) => setPrice(e.target.value)}
                      // placeholder="Price"
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="user.placead.price"
                          defaultMessage="Price"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.description"
                        defaultMessage="Description (Optional)"
                      />
                    </label>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <textarea
                        rows="5"
                        type="text"
                        className="form-control"
                        defaultValue={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row input-group">
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.type"
                        defaultMessage="Type"
                      />
                    </label>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group" style={{ paddingLeft: "10px" }}>
                      <input
                        type="radio"
                        id="demand"
                        value="Demand"
                        name="adtype"
                        checked={adType === "Demand" ? true : false}
                        onChange={(e) => setAdType(e.target.value)}
                        required
                      />
                      <label htmlFor="demand" style={{ paddingLeft: "10px" }}>
                        <FormattedMessage
                          id="user.placead.demandRadioButton1"
                          defaultMessage="Demand"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="form-group" style={{ paddingLeft: "10px" }}>
                      <input
                        type="radio"
                        id="offer"
                        value="Offer"
                        name="adtype"
                        checked={adType === "Offer" ? true : false}
                        onChange={(e) => setAdType(e.target.value)}
                      />

                      <label htmlFor="offer" style={{ paddingLeft: "10px" }}>
                        <FormattedMessage
                          id="user.placead.offerRadioButton2"
                          defaultMessage="Offer"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-2">
                    <label>
                      <FormattedMessage
                        id="user.placead.images"
                        defaultMessage="Images"
                      />
                    </label>
                  </div>{" "}
                  <div className="col-md-4">
                    <div className="form-group">
                      {/* <label>
                      <FormattedMessage
                        id="user.placead.addImagesOutlineButton"
                        defaultMessage=" Add Images"
                      />
                    </label> */}

                      <input
                        required
                        type="file"
                        name="sampleFile"
                        accept=".jpg,.png,.jpeg,.jfif"
                        className="form-control"
                        multiple={true}
                        onChange={onChangePictureHandler}
                      // style={{ visibility: "hidden" }}
                      // ref={(fileInput1) => (fileInput = fileInput1)}
                      />

                      {/* <button
                        className="btn form-control"
                        onClick={triggerInputFile}
                      >
                        {" "}
                        <FormattedMessage
                          id="fileUpload.part1"
                          defaultMessage="Choose Files"
                        />{" "}
                        - {fileType.length}{" "}
                        <FormattedMessage
                          id="fileUpload.part2"
                          defaultMessage="Files Selected"
                        />
                      </button> */}
                      <small className="form-text text-muted">

                        <FormattedMessage
                          id="user.placead.allowedFormat"
                          defaultMessage="Allowed .jpg and .png"
                        />
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center">
                {isSavingChanges ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "#00d0f1" }}
                  >
                    <span className="sr-only">
                      <FormattedMessage
                        id="user.placead.loading"
                        defaultMessage="Loading..."
                      />
                    </span>
                  </Spinner>
                ) : (
                    <button
                      type="submit"
                      // className="btn btn-primary"
                      className="btn"
                      style={{ width: "fit-content" }}
                    >
                      <FormattedMessage
                        id="user.placead.SaveChangesButton"
                        defaultMessage="Save"
                      />
                    </button>
                  )}
              </div>
            </form>
          </div>
        </div>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>{msg}</Modal.Title>
          </Modal.Header>
        </Modal>
      </Route>
      {/* <Route path={`${path}/:companyId`}>
                <AccountApprovalDetails />
            </Route> */}
    </>
  );
}

export default PlaceAd;
