import axios from "axios";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
// import "../../assets/css/adminStyle.css";
import "../../assets/css/adminStyle.css";
// import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function CommunityLeaderSidebar(props) {
  let history = useHistory();
  let handleLogout = (e) => {
    // Cookies.remove("Authorization");
    // setTimeout(() => {}, 1);
    e.preventDefault();
    axios
      .get("/api/v1/auth/user/logout", { withCredentials: true })
      .then((response) => {
        console.log(response);
        props.checkLoginStatus();
        history.push("/");
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className="sidebar"
      id="sidebar"
      style={{ backgroundColor: "#07631F" }}
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>
                <FormattedMessage
                  id="communityLeader.sidebar.main"
                  defaultMessage="Main"
                />
              </span>
            </li>
            <li className={props.activeTab.dashboard}>
              <Link to={`${props.match.url}`}>
                <i className="fa fa-home"></i>{" "}
                <span>
                  <FormattedMessage
                    id="communityLeader.sidebar.dashboard"
                    defaultMessage="Dashboard"
                  />
                </span>
              </Link>
            </li>
            {props.userData.accountStatus === "Approved" ? (
              <li className={props.activeTab.mymingas}>
                <Link to={`${props.match.url}/mymingas`}>
                  <i className="far fa-credit-card"></i>
                  <span>
                    <FormattedMessage
                      id="communityLeader.sidebar.mymingas"
                      defaultMessage="My Mingas"
                    />
                  </span>
                </Link>
              </li>
            ) : null}
            {props.userData.accountStatus === "Approved" ? (
              <li className={props.activeTab.sendMinga}>
                <Link to={`${props.match.url}/sendminga`}>
                  <i className="far fa-credit-card"></i>
                  <span>
                    <FormattedMessage
                      id="communityLeader.sidebar.sendMinga"
                      defaultMessage="Send Minga Coin"
                    />
                  </span>
                </Link>
              </li>
            ) : null}
            {props.userData.accountStatus !== "Approved" ? (
              <li className={props.activeTab.requestAccountApproval}>
                <Link to={`${props.match.url}/requestaccountapproval`}>
                  <i className="fa fa-hourglass"></i>{" "}
                  <span>
                    <FormattedMessage
                      id="communityLeader.sidebar.requestAccount"
                      defaultMessage="Request Account Approval"
                    />
                  </span>
                </Link>
              </li>
            ) : null}
            {props.userData.accountStatus === "Approved" ? (
              <li className={props.activeTab.createProject}>
                <Link to={`${props.match.url}/createproject`}>
                  <i className="fa fa-building"></i>
                  <span>
                    <FormattedMessage
                      id="communityLeader.sidebar.createProject"
                      defaultMessage="Create Project"
                    />
                  </span>
                </Link>
              </li>
            ) : null}
            {props.userData.accountStatus === "Approved" ? (
              <li className={props.activeTab.coinRequests}>
                <Link to={`${props.match.url}/coinrequests`}>
                  <i className="fas fa-money-bill-alt"></i>
                  <span>
                    <FormattedMessage
                      id="communityLeader.sidebar.coinRequests"
                      defaultMessage="Minga Unit Requests"
                    />
                  </span>
                </Link>
              </li>
            ) : null}

            <li className="menu-title">
              <span>
                <FormattedMessage
                  id="communityLeader.sidebar.settings"
                  defaultMessage="Settings"
                />
              </span>
            </li>

            <li className={props.activeTab.settings}>
              <Link to={`${props.match.url}/profilesettings`}>
                <i className="fa fa-cog"></i>
                <span>
                  <FormattedMessage
                    id="communityLeader.sidebar.profileSettings"
                    defaultMessage="Profile Settings"
                  />
                </span>
              </Link>
            </li>
            <li className={props.activeTab.changePassword}>
              <Link to={`${props.match.url}/changepassword`}>
                <i className="fa fa-key"></i>
                <span>
                  <FormattedMessage
                    id="communityLeader.sidebar.changePassword"
                    defaultMessage="Change Password"
                  />
                </span>
              </Link>
            </li>
            <li>
              <Link to={"/"} onClick={handleLogout}>
                <i className="fa fa-sign-out-alt"></i>
                <span>
                  <FormattedMessage
                    id="communityLeader.sidebar.logout"
                    defaultMessage="Logout"
                  />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default CommunityLeaderSidebar;
