import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import patient from "../../assets/img/patients/patient.jpg";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import { Card, CardDeck } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';



function About() {
    return (
      
<section className="section home-block6-section">
      <div className="row no-gutters">
        <div className="col-12 text-center">
          <p className="home-team-block-title">
            <FormattedMessage
              id="home.block6.meetTeam"
              defaultMessage="Meet the Team"
            />
          </p>
        </div>
      </div>
      <SimpleBar style={{ maxHeight: 350 }}>
      <>
      <div
        className="row"
        style={{
          justifyContent: "center",
          width: "100%",
          paddingBottom: "30px",
          paddingLeft: "30px"
        }}
      >
        
        <CardDeck>
          <Card style={{ width: '18rem', textAlign: "center" }}>
            <Card.Body>
              <Card.Img variant="top" className="img-fluid"
                alt="User Image"
                src={require('../../assets/img/Mary_Wingo.jpg')}
                style={{ borderRadius: "50%", width: "100px" }} />
              <Card.Title>
                <a href="#" style={{ color: "green" }}>
                  {" "}
                  <FormattedMessage
                    id="home.block6.maryWingo"
                    defaultMessage="Mary Wingo, Ph.D"
                  />
                </a>
              </Card.Title>
              <Card.Text>
                <p className="speciality">
                  <FormattedMessage
                    id="home.block6.speciality1"
                    defaultMessage="Co Creator"
                  />
                </p>

                <ul className="available-info" >
                  <li>
                    <i className="fab fa-whatsapp-square"></i>
                      N/A
                    </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                      N/A
                    </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                        Texas, US
                    </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem', textAlign: "center" }}>
            <Card.Body>
              <Card.Img variant="top" className="img-fluid"
                alt="User Image"
                src={require("../../assets/img/Zaid_Munir.jpg")}
                style={{ borderRadius: "50%", width: "100px" }} />
              <Card.Title>
                <a href="#" style={{ color: "green" }}>
                  <FormattedMessage
                    id="home.block6.zaidMunir"
                    defaultMessage="Zaid Munir"
                  />
                </a>
              </Card.Title>
              <Card.Text>
                <p className="speciality">
                  <FormattedMessage
                    id="home.block6.speciality2"
                    defaultMessage="System Architect/programmer"
                  />
                </p>

                <ul className="available-info">
                  <li>
                    <i className="fab fa-whatsapp-square"></i>
                      +92 333 4047461
                  </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                      zaid@scytalelabs.com
                  </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                      Lahore, PK
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: '18rem', textAlign: "center" }}>
            <Card.Body>
              <Card.Img variant="top" className="img-fluid"
                alt="User Image"
                src={require("../../assets/img/Luis_Morocho.jpg")}
                style={{ borderRadius: "50%", width: "100px" }} />
              <Card.Title>
                <a href="#" style={{ color: "green" }}>
                  {" "}
                  Luis Morocho
                </a>
              </Card.Title>
              <Card.Text>
                <p className="speciality">
                  <FormattedMessage
                    id="home.block6.speciality3"

                    defaultMessage="Co Creator"
                  />
                </p>
                <ul className="available-info" >
                  <li>
                    <i className="fab fa-whatsapp-square"></i>
                      N/A
                    </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                      N/A
                    </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                      Texas, US
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
      <div
        className="row"
        style={{
          justifyContent: "center",
          width: "100%",
          paddingBottom: "30px",
          paddingLeft: "30px"

        }}
      >
        <CardDeck >
          <Card style={{ width: '18rem', textAlign: "center" }}>
            <Card.Body>
              <Card.Img variant="top" className="img-fluid"
                alt="User Image"
                src={patient}
                style={{ borderRadius: "50%", width: "100px" }} />
              <Card.Title>
                <a href="#" style={{ color: "green" }}>
                  Agosto Reyes
                </a>
              </Card.Title>
              <Card.Text>
                <p className="speciality">
                  <FormattedMessage
                    id="home.block6.speciality4"
                    defaultMessage="Logo Design"
                  />
                </p>

                <ul className="available-info" >

                  <li>
                    <i className="fab fa-whatsapp-square"></i>
                      +593 98 951 3973
                    </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                      N/A
                    </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                      Texas, US
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem', textAlign: "center" }}>
            <Card.Body>
              <Card.Img variant="top" className="img-fluid"
                alt="User Image"
                src={patient}
                style={{ borderRadius: "50%", width: "100px" }} />
              <Card.Title>
                <a href="#" style={{ color: "green" }}>
                  Sherilyn Carpenter
                </a>
              </Card.Title>
              <Card.Text>
                <p className="speciality">
                  <FormattedMessage
                    id="home.block6.speciality4"
                    defaultMessage="Logo Design"
                  />
                </p>
                <ul className="available-info" >

                  <li>
                    <i className="fab fa-whatsapp-square"></i>
                        N/A
                    </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                        N/A
                    </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                      Texas, US
                  </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem', textAlign: "center" }}>
            <Card.Body>
              <Card.Img variant="top" className="img-fluid"
                alt="User Image"
                src={require("../../assets/img/Juani.jpg")}
                style={{ borderRadius: "50%", width: "100px" }} />
              <Card.Title>
                <a href="#" style={{ color: "green" }}>
                  Juani
                </a>
              </Card.Title>
              <Card.Text>
                <p className="speciality">
                  <FormattedMessage
                    id="home.block6.speciality5"
                    defaultMessage="Video"
                  />
                </p>

                <ul className="available-info" >

                  <li>
                    <i className="fab fa-whatsapp-square"></i>
                        N/A
                    </li>
                  <li>
                    <i className="fas fa-envelope"></i>
                        N/A
                    </li>
                  <li>
                    <i className="fas fa-map-marker-alt"></i>
                      Texas, US
                    </li>
                </ul>
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
      </div>
      </>
      </SimpleBar>
    </section>
    
    );
}

export default About;
