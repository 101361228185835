import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";


function ChangePassword(props) {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);
  let [oldPassword, setOldPassword] = useState();
  let [newPassword, setNewPassword] = useState();
  let [confirmNewPassword, setConfirmNewPassword] = useState();
  let [isSaving, setIsSaving] = useState(false);
  let [isError, setIsError] = useState(false);
  let [errorMsg, setErrorMsg] = useState();

  let handleSubmitEvent = (e) => {
    e.preventDefault();
    setIsError(false);
    setIsSaving(true);
    let data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    if (newPassword === confirmNewPassword) {
      axios
        .post(`/api/v1/users/${props.userData._id}/changePassword`, data, {
          withCredentials: true,
        })
        .then(
          (response) => {
            setIsSaving(false);
            handleShowSuccessModal();
          },
          (error) => {
            console.log(error);
            setErrorMsg(error.response.data);
            setIsSaving(false);
            setIsError(true);
          }
        );
    } else {
      setErrorMsg("Confirm password not same as new password");
      setIsError(true);
      setIsSaving(false);
    }
  };

  useEffect(() => {
    props.setActiveTab({
      dashboard: "",
      sendMinga: "",
      requestAccountApproval: "",
      createProject: "",
      poolSignature: "",
      contractDetails: "",
      settings: "",
      changePassword: "active",
    });
  }, []);
  return (
    <div className="card">
      <ul className="breadcrumb">
        <li className="breadcrumb-item">
          <a href="index.html">
            <FormattedMessage
              id="changePassword.dashboardBreadcrumb"
              defaultMessage="Dashboard"
            />
          </a>
        </li>
        <li className="breadcrumb-item active">
          <FormattedMessage
            id="changePassword.changePasswordBreadcrumb"
            defaultMessage="Change Password"
          />
        </li>
      </ul>
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-lg-6">
            {/* <!-- Change Password Form --> */}
            <form onSubmit={handleSubmitEvent}>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="changePassword.label1"
                    defaultMessage="Old Password"
                  />
                </label>
                <input
                  type="password"
                  required
                  className="form-control"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="changePassword.label2"
                    defaultMessage="New Password"
                  />
                </label>
                <input
                  type="password"
                  required
                  className="form-control"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>
                  <FormattedMessage
                    id="changePassword.label3"
                    defaultMessage="Confirm Password"
                  />
                </label>
                <input
                  type="password"
                  required
                  className="form-control"
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                  }}
                />
              </div>
              {isError ? (
                <div className="form-group">
                  <p style={{ color: "#FF0000" }}>{errorMsg}</p>
                </div>
              ) : (
                  <></>
                )}
              {isSaving ? (
                <div className="text-center">
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ color: "#00d0f1" }}
                  >
                    <span className="sr-only">
                      <FormattedMessage
                        id="changePassword.loading"
                        defaultMessage="Loading..."
                      />
                    </span>
                  </Spinner>
                </div>
              ) : (
                  <div className="submit-section">
                    <button
                      type="submit"
                      // className="btn btn-primary submit-btn"
                      className="btn submit-btn"
                    >
                      <FormattedMessage
                        id="changePassword.saveButton"
                        defaultMessage=" Save Changes"
                      />
                    </button>
                  </div>
                )}
            </form>
            {/* <!-- /Change Password Form --> */}
          </div>
        </div>
      </div>
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="changePassword.modalSuccess"
              defaultMessage="Successfully Updated"
            />
          </Modal.Title>
        </Modal.Header>
      </Modal>
    </div>
  );
}

export default ChangePassword;
