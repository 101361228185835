import axios from "axios";
import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FormattedMessage } from "react-intl";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import "../../assets/css/adminStyle.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import { default as logo, default as logoSmall } from "../../assets/img/MINGAS_LOGO1.png";
import patient from "../../assets/img/patients/patient.jpg";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
function CommunityLeaderHeader(props) {
  let history = useHistory();
  let { path } = useRouteMatch();
  let [menuOpenedClass, setMenuOpenedClass] = useState();
  let [slideNavClass, setSlideNavClass] = useState();

  let handleSlideNav = (e) => {
    e.preventDefault();
    if (slideNavClass !== "" && menuOpenedClass !== "") {
      setMenuOpenedClass("");
      setSlideNavClass("");
      props.setSlideNavClass("");
    } else {
      setMenuOpenedClass("menu-opened");
      setSlideNavClass("slide-nav");
      props.setSlideNavClass("slide-nav");
    }
  };

  let handleLogout = (e) => {
    // Cookies.remove("Authorization");
    // setTimeout(() => {}, 1);
    e.preventDefault();
    axios
      .get("/api/v1/auth/user/logout", { withCredentials: true })
      .then((response) => {
        console.log(response);
        props.checkLoginStatus();
        history.push("/");
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [activeTab, setActiveTab] = useState({
    dashboard: "active",
    sendMinga: "",
    requestAccountApproval: "",
    createProject: "",
    poolSignature: "",
    contractDetails: "",
    settings: "",
    changePassword: "",
  });

  return (
    // {/* <!-- Header --> */ }
    <header
      className={`admin-header ${menuOpenedClass}`}
      style={{ backgroundColor: "#CBAA6C" }}
    >
      {/* <nav
        className={`admin-header ${menuOpenedClass}`}
        style={{ backgroundColor: "#CBAA6C" }}
      > */}

      {/* <nav className="navbar navbar-expand-lg header-nav"> */}
      {/* <div className="navbar-header"> */}

      {/* <!-- Logo --> */}
      <div className="header-left" style={{ paddingBottom: "15px" }}>
        <a
          href="index.html"
          className="logo"
          onClick={(e) => e.preventDefault()}
        >
          <img src={logo} alt="Logo" />
        </a>
        <a
          href="index.html"
          className="logo logo-small"
          onClick={(e) => e.preventDefault()}
        >
          <img src={logoSmall} alt="Logo" width="30" height="30" />
        </a>
      </div>
      {/* <!-- /Logo --> */}

      {/* <!-- Mobile Menu Toggle --> */}
      <a className="mobile_btn" id="mobile_btn" onClick={handleSlideNav}>
        <i className="fa fa-bars"></i>
      </a>
      {/* <!-- /Mobile Menu Toggle --> */}

      {/* <!-- Header Right Menu --> */}
      <ul className="nav user-menu">
        {/* <!-- User Menu --> */}

        <li className="nav-item dropdown has-arrow"></li>

        <li className="nav-item dropdown has-arrow">
          <Dropdown>
            <Dropdown.Toggle
              style={{
                backgroundColor: "transparent",
                border: "0",
                // padding: "0"
              }}
            >
              <span className="admin-img">
                {props.userData.pictureURL !== undefined ? (
                  <img
                    className="rounded-circle"
                    style={{ objectFit: "cover" }}
                    src={props.userData.pictureURL}
                    width="60"
                    height="60"
                    alt="User"
                  />
                ) : (
                    <img
                      className="rounded-circle"
                      src={patient}
                      width="60"
                      height="60"
                      alt="User"
                    />
                  )}
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu alignRight="true">
              <Dropdown.Item>
                <Link to="/communityLeaderDashboard" style={{ width: "100%" }}>
                  <FormattedMessage
                    id="communityLeader.dashboard.dashboardLabel"
                    defaultMessage="Dashboard"
                  />
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link to="/communityLeaderSettings" style={{ width: "100%" }}>
                  <FormattedMessage
                    id="communityLeader.dashboard.profileSettingsLabel"
                    defaultMessage="Profile Settings"
                  />
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <Link onClick={handleLogout} to="/" style={{ width: "100%" }}>
                  <FormattedMessage
                    id="communityLeader.dashboard.logoutLabel"
                    defaultMessage="Logout"
                  />
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        {/* <!-- /User Menu --> */}
      </ul>
      {/* <!-- /Header Right Menu --> */}
      {/* </nav> */}
      {/* <!-- /Header --> */}
    </header>
    // {/* </div> */ }
  );
}

export default CommunityLeaderHeader;
