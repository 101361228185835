import axios from "axios";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { Alert, Button, InputGroup, ListGroup, Modal, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function MyMingas(props) {
  useEffect(() => {
    props.setActiveTab({
      mymingas: "active",
    });
    getMyMingas();
  }, []);

  let [myProjects, setMyProjects] = useState([]);
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showUnSuccessfulToast, setShowUnSuccessfulToast] = useState(false);

  const [userMailsList, setUserMailsList] = useState([]);
  const [userMail, setUserMail] = useState([]);
  const [myMingaId, setMyMingaId] = useState();
  const [emailAlert, setEmailAlert] = useState(false);
  const handleClose = () => {
    setUserMailsList([]);
    setIsSendingMail(false);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  let [isSendingMail, setIsSendingMail] = useState(false);
  let getMyMingas = () => {
    axios
      .get(`/api/v1/communityleaders/mymingaprojects/${props.userData._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log("USERR",response.data);
        setMyProjects(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changeHandler = (e) => {
    setEmailAlert(false);
    // console.log(e.target.value.toLowerCase());
    setUserMail(e.target.value.toLowerCase());
  };
  const addMails = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userMail)) {
      setEmailAlert(false);
      setUserMailsList((oldArray) => [...oldArray, userMail]);
    } else setEmailAlert(true);
  };
  const removeMails = (mail) => {
    // console.log("HELLOOO",mail);
    setUserMailsList(userMailsList.filter((e) => e !== mail));
  };
  const sendInvite = (id) => {
    // console.log("hello",userMailsList,id);
    let data = {
      mingaProjectId: id,
      email: userMailsList,
    };
    // console.log("DATA",data);
    setIsSendingMail(true);
    axios
      .post(`/api/v1/communityleaders/sendInvitations`, data, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data);
        setIsSendingMail(false);
        if (response.data.seccess === true) setShowToast(true);
        else {
          setShowUnSuccessfulToast(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onChangeFileHandler = (event) => {
    // console.log("HELLOOO",event.target.files[0]);
    Papa.parse(event.target.files[0], {
      complete: function (results) {
        // console.log(results);
        results.data.slice(1).map((list) => {
          // console.log("OKAR",list[1]);
          if (list[1] !== undefined)
            setUserMailsList((oldArray) => [...oldArray, list[1]]);
        });
        // for(var i=0;i<results.length;i++)
        // {
        //   console.log("OKAR",results[i][1]);
        // }
      },
    });
    // reader.onerror=errorHandler;
  };
  const handleProjectShow = (e) => {
    // console.log("HELLO",e);
    setMyMingaId(e._id);
    handleShow();
  };
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">
        <FormattedMessage
          id="communityLeader.mymingas.table.format"
          defaultMessage="Format"
        />
      </Popover.Title>
      <Popover.Content>
        <table>
          <thead>
            <tr>
              <th style={{ border: "1px solid black" }}>
                <FormattedMessage
                  id="communityLeader.mymingas.table.Sr.no"
                  defaultMessage="Sr.No."
                />
              </th>
              <th style={{ border: "1px solid black" }}>
                <FormattedMessage
                  id="communityLeader.mymingas.table.email"
                  defaultMessage="Email"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ border: "1px solid black" }}>1</td>
              <td style={{ border: "1px solid black" }}>
                <FormattedMessage
                  id="communityLeader.mymingas.table.mailexample"
                  defaultMessage="username@example.com"
                />
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>2</td>
              <td style={{ border: "1px solid black" }}>
                <FormattedMessage
                  id="communityLeader.mymingas.table.mailexample"
                  defaultMessage="username@example.com"
                />
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>3</td>
              <td style={{ border: "1px solid black" }}>
                <FormattedMessage
                  id="communityLeader.mymingas.table.mailexample"
                  defaultMessage="username@example.com"
                />
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>.</td>
              <td style={{ border: "1px solid black" }}>.</td>
            </tr>
            <tr>
              <td style={{ border: "1px solid black" }}>.</td>
              <td style={{ border: "1px solid black" }}>.</td>
            </tr>
          </tbody>
        </table>
      </Popover.Content>
    </Popover>
  );

  const TootlTip = () => (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <Button style={{ float: "right" }} variant="secondary">
        <FormattedMessage
          id="communityLeader.mymingas.table.fileFormat"
          defaultMessage="Click me to see file format"
        />
      </Button>
    </OverlayTrigger>
  );
  let button;
  // console.log("LIST",userMailsList);
  if (isSendingMail) {
    button = (
      <Button variant="Secondary">
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <FormattedMessage
          id="communityLeader.mymingas.table.sendingInvitation"
          defaultMessage="Sending Invitation"
        />
      </Button>
    );
  } else if (userMailsList.length === 0) {
    button = (
      <Button variant="secondary" disabled>
        <FormattedMessage
          id="communityLeader.mymingas.table.sendInvitation"
          defaultMessage="Send Invitation"
        />
      </Button>
    );
  } else {
    button = (
      <Button variant="secondary" onClick={() => sendInvite(myMingaId)}>
        <FormattedMessage
          id="communityLeader.mymingas.table.sendInvitation"
          defaultMessage="Send Invitation"
        />
      </Button>
    );
  }

  return (
    <>
      <div className="card">
        <ul className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="index.html">
              <FormattedMessage
                id="communityLeader.mymingas.dashboardBreadcrumb"
                defaultMessage="Dashboard"
              />
            </a>
          </li>
          <li className="breadcrumb-item active">
            <FormattedMessage
              id="communityLeader.mymingas.myMingas"
              defaultMessage="My Mingas"
            />
          </li>
        </ul>

        <div className="card-body">
          <div className="col-12 col-md-12">
            <div className="table-responsive" style={{ paddingTop: "20px" }}>
              <table className="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.number"
                        defaultMessage="#"
                      />
                    </th>

                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.projectName"
                        defaultMessage="Project Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.signatory1"
                        defaultMessage="Signatory 1"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.signatory2"
                        defaultMessage="Signatory 2"
                      />
                    </th>

                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.approvalStatus"
                        defaultMessage="Approval Status"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="communityLeader.mymingas.table.invitation"
                        defaultMessage="Invitation"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {myProjects.map((i, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{i.projectName}</td>
                      {/* <td>{i.description}</td> */}
                      <td>
                        {i.signatory1.firstName} {i.signatory1.lastName}{" "}
                      </td>
                      <td>
                        {i.signatory2.firstName} {i.signatory2.lastName}
                      </td>
                      <td>{i.adminApproval}</td>
                      <td>
                        <button
                          className="btn btn-outline-dark"
                          onClick={() => handleProjectShow(i)}
                        >
                          <FormattedMessage
                            id="communityLeader.mymingas.table.invite"
                            defaultMessage="Invite"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        <FormattedMessage
                          id="communityLeader.mymingas.table.inviteUsers"
                          defaultMessage="Invite Users"
                        />
                      </Modal.Title>
                    </Modal.Header>

                    {showToast ? (
                      <Alert
                        variant="success"
                        onClose={() => setShowToast(false)}
                        dismissible
                      >
                        <FormattedMessage
                          id="communityLeader.mymingas.table.sentMailSucessfully"
                          defaultMessage="Email Sent Sucessfully to all Users"
                        />
                      </Alert>
                    ) : (
                        <div></div>
                      )}
                    {showUnSuccessfulToast ? (
                      <Alert
                        variant="danger"
                        onClose={() => setShowUnSuccessfulToast(false)}
                        dismissible
                      >
                        <FormattedMessage
                          id="communityLeader.mymingas.table.sentMailUnSucessfully"
                          defaultMessage="Unable to Send Email to Users. Please Try Again"
                        />
                      </Alert>
                    ) : (
                        <div></div>
                      )}

                    <Modal.Body>
                      {emailAlert ? (
                        <Alert variant="danger">
                          <FormattedMessage
                            id="communityLeader.mymingas.table.invalidMail"
                            defaultMessage="Invalid Email Address"
                          />
                        </Alert>
                      ) : (
                          <div></div>
                        )}
                      <InputGroup className="mb-3">
                        <input
                          type="email"
                          className="form-control"
                          onChange={changeHandler}
                          className="form-control floating"
                          defaultValue={userMail}
                        // style={{ visibility: "hidden" }}
                        // ref={(fileInput1) => (fileInput = fileInput1)}
                        />
                        {/* <FormControl
                                onChange={changeHandler}
                                type="email"
                                placeholder="Enter Users Email"
                                aria-label="Enter Users Email"
                                aria-describedby="basic-addon2"
                              /> */}
                        <InputGroup.Append>
                          <Button
                            variant="outline-secondary"
                            onClick={addMails}
                          >
                            <i className="fa fa-plus-circle"></i>
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>

                      <FormattedMessage
                        id="communityLeader.mymingas.table.or"
                        defaultMessage="or"
                      />

                      <input
                        type="file"
                        name="sampleFile"
                        accept=".csv"
                        className="form-control"
                        multiple={false}
                        onChange={onChangeFileHandler}
                      // style={{ visibility: "hidden" }}
                      // ref={(fileInput1) => (fileInput = fileInput1)}
                      />
                      <br></br>
                      <TootlTip />
                    </Modal.Body>
                    <Modal.Body>
                      <ListGroup>
                        {userMailsList.map((i, index) => (
                          <tr key={i}>
                            <ListGroup.Item style={{ verticalAlign: "middle" }}>
                              {i}{" "}
                              <Button
                                variant="danger"
                                style={{
                                  float: "right",
                                  display: "flex",
                                  verticalAlign: "middle",
                                }}
                                onClick={() => removeMails(i)}
                              >
                                <i className="fa fa-minus-circle"></i>
                              </Button>
                            </ListGroup.Item>
                          </tr>
                        ))}
                      </ListGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      {!isSendingMail ? (
                        <Button variant="secondary" onClick={handleClose}>
                          <FormattedMessage
                            id="communityLeader.mymingas.table.cancelInvitation"
                            defaultMessage="Cancel Invitation"
                          />
                        </Button>
                      ) : (
                          <Button disabled variant="secondary">
                            <FormattedMessage
                              id="communityLeader.mymingas.table.cancelInvitation"
                              defaultMessage="Cancel Invitation"
                            />
                          </Button>
                        )}
                      {button}
                    </Modal.Footer>
                  </Modal>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyMingas;
