import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import failure from "../../../assets/img/emailVerification/failure.png";
import success from "../../../assets/img/emailVerification/success.png";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Header from "../../Header/Header";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function EmailVerification(props) {
  const { email, token } = useParams();
  const classes = useStyles();
  let [isConfirming, setIsConfirming] = useState(false);
  let [isSuccess, setIsSuccess] = useState("");
  let handleEmailVerification = () => {
    setIsSuccess("");
    setIsConfirming(true);
    axios
      .get(`/api/v1/users/emailverification/${email}/${token}`)
      .then((response) => {
        setIsSuccess(true);
        setIsConfirming(false);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 600) {
            setIsSuccess("valid");
          }
        } else {
          setIsSuccess(false);
        }
        setIsConfirming(false);
        console.log(error);
      });
  };
  useEffect(() => {
    handleEmailVerification();
  }, []);
  return (
    <div className="home-main-wrapper">
      <div className="home-section home-full-height">
        <Header setlocal={props.setlocal} selectedNav={"home"} />
        {isConfirming ? (
          <>
            <Backdrop className={classes.backdrop} open={isConfirming}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </>
        ) : null}
        {isSuccess === true ? (
          <div className="container" style={{ marginTop: "10%" }}>
            <div className="row">
              <div className="col-12 text-center booking-success">
                <p><FormattedMessage
                  id="emailVerification.success"
                  defaultMessage="Your Email Verification is successful" /></p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <img src={success} />
              </div>
            </div>
          </div>
        ) : null}
        {isSuccess === "valid" ? (
          <div className="container" style={{ marginTop: "10%" }}>
            <div className="row">
              <div className="col-12 text-center booking-success">
                <p>
                  <FormattedMessage
                    id="emailVerification.verified"
                    defaultMessage="Your Email is verified." /></p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center">
                <img src={success} />
              </div>
            </div>
          </div>
        ) : null}
        {isSuccess === false ? (
          <div className="container" style={{ marginTop: "10%" }}>
            <div className="row">
              <div className="col-12 text-center booking-failure">
                <p>
                  <FormattedMessage
                    id="emailVerification.unverified"
                    defaultMessage="Invalid Email Verification URL" /></p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center email-verification-failure">
                <img src={failure} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default EmailVerification;
