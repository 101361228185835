import React from "react";
import { FormattedMessage } from "react-intl";
import YouTube from "react-youtube";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import logo from "../../../assets/img/MINGAS_LOGO1.png";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function MingaBlock1(props) {
  const opts = {
    // height: "350",
    // width: "550",
    // playerVars: {
    //   // https://developers.google.com/youtube/player_parameters
    //   autoplay: 1,
    // },
  };

  return (
    <section className="section home-block1-section">
      <div className="container ">
        <div className="row no-gutters welcome-logo">
          <div className="col-sm-12 col-md-12 col-lg-4">
            <img src={logo} />
          </div>

          <div className="col-sm-12 col-md-12 col-lg-8">
            <p className=" home-block1-title">
              <FormattedMessage
                id="home.block1.title"
                defaultMessage="THE MINGA PROJECT"
              />
            </p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 text-center">
            <p className="home-block1-description">
              <FormattedMessage
                id="home.block1.titlebelowtext1"
                defaultMessage="Imagine a future where society can shape its own destiny"
              />
            </p>
          </div>
          <div className="col-12 text-center">
            <p className="home-block1-description">
              <FormattedMessage
                id="home.block1.titlebelowtext2"
                defaultMessage="Learn about this like-changing bartering system and sign up for a
                better future now"
              />
            </p>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-md-6 youtube-video-div">
            <YouTube
              videoId="px9YbL9Ea5c"
              opts={opts}
              className="youtube-video"
            />
          </div>
          <div className="col-12 col-md-6 mt-3">
            <p className="home-block1-description">
              <FormattedMessage
                id="home.block1.adjacentvideotext1"
                defaultMessage="Join a growing network of community leaders and volunteers that improves life through the power of sophisticated barter. All without the need for money."
              />
            </p>
            <br />
            <p className="home-block1-description">
              <FormattedMessage
                id="home.block1.adjacentvideotext2"
                defaultMessage="One Month Free and Only $2 a year afterward"
              />
            </p>
            <br />
            <a href="/signup">
              <p className="home-block1-description-signup">
                <FormattedMessage
                  id="home.block1.adjacentvideotext3"
                  defaultMessage="Sign up now!"
                />
              </p>
            </a>
          </div>
          <br />
        </div>
        {/* <div className="row">
          <div className="col-12">
            <Carousel interval={5000}>
              

              <Carousel.Item>
                <a href="#whatisminga">
                  <div className="col-12 text-center">
                    <h1 className="home-block1-carousel-headings">
                      Why Minga ?
                    </h1>
                  </div>

                  <Carousel.Caption>
                    <p className="home-block1-carousel-caption">
                      Click to See More..
                    </p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a href="#whybarter">
                  <div className="col-12 text-center">
                    <h1 className="home-block1-carousel-headings">
                      Why Barter ?
                    </h1>
                  </div>

                  <Carousel.Caption>
                    <p className="home-block1-carousel-caption">
                      Click to See More..
                    </p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a href="#carousel">
                  <div className="col-12 text-center">
                    <h1 className="home-block1-carousel-headings">
                      See the community developments yourself
                    </h1>
                  </div>
                  <Carousel.Caption>
                    <p className="home-block1-carousel-caption">
                      Click to See More..
                    </p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a href="#map-section">
                  <div className="col-12 text-center">
                    <h1 className="home-block1-carousel-headings">
                      Find Mingas Near You
                    </h1>
                  </div>
                  <Carousel.Caption>
                    <p className="home-block1-carousel-caption">
                      Click to See More..
                    </p>
                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            </Carousel>
          </div>
        </div> */}
      </div>
    </section>
  );
}

export default MingaBlock1;
