import axios from "axios";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import "react-intl-tel-input/dist/main.css";
import { Link, useHistory } from "react-router-dom";
import windowSize from "react-window-size";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import registerBanner from "../../../assets/img/signUp.jpg";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import Header from "../../Header/Header";

function RegisterScreen(props) {

  const trans = (<FormattedMessage id="register.passwordMess"
    defaultMessage="Passwords not matched" />);

  const trans1 = (<FormattedMessage id="register.successMess"
    defaultMessage="A verification email has been sent to your email address." />);
  const trans2 = (<FormattedMessage id="register.errorMess"
    defaultMessage="User Already Exists" />);

  const trans3 = (<FormattedMessage id="register.errorMess2"
    defaultMessage="Unauthorized/Illegal Attempt" />);

  const trans4 = (<FormattedMessage id="register.errorMess3"
    defaultMessage="Unknown error occured, try again" />);

  let history = useHistory();
  let [firstName, setFirstName] = useState();
  let [lastName, setLastName] = useState();
  let [idCard, setIdCard] = useState();
  let [email, setEmail] = useState();
  let [mobile, setMobile] = useState();
  let [password, setPassword] = useState();
  let [confirmPassword, setConfirmPassword] = useState();

  let [role, setRole] = useState("user");
  let [description, setDescription] = useState();

  let [isLoading, setIsLoading] = useState(false);

  let [msg, setMsg] = useState("");
  let [successMsg, setSuccessMsg] = useState("");
  let userSubmitEventHandler = (event) => {
    setMsg("");
    event.preventDefault();
    // if (!mobileCheck) {
    //   setMsg("Invalid Mobile Number");
    //   return;
    // }
    if (password !== confirmPassword) {
      // setMsg("Passwords don't match");
      setMsg(trans);
    }
    else {

      setIsLoading(true);

      let data = {
        idCard: idCard,
        firstName: firstName,
        lastName: lastName,
        mobile: mobile,
        email: email,
        password: password,
        confirmPassword: confirmPassword,
        role: role,
        description: description,
      };
      // console.log(data);
      axios
        .post("/api/v1/auth/user/signup", data, {})
        .then((response) => {
          // Cookies.set("Authorization", response.data.token, {
          //   sameSite: "None",
          //   secure: true,
          // });
          // window.location.reload();
          setIsLoading(false);
          setSuccessMsg(
            // "A verification email has been sent to your email address."
            trans1
          );
          // history.push("/dashboard");
        })
        .catch((error) => {
          console.log(error.response);
          if (error.response.status !== undefined) {
            if (error.response.status === 409) {
              // setMsg("User Already Exists");
              setMsg(trans2);
            } else if (error.response.status === 403) {
              // setMsg("Unauthorized/Illegal Attempt");
              setMsg(trans3);
            } else {
              // setMsg("Unknown error occured, try again");
              setMsg(trans4);
            }
          } else {
            // setMsg("Unknown error occured, try again");
            setMsg(trans4);
          }

          setIsLoading(false);
        });
    }
  };

  let [mobileCheck, setMobileCheck] = useState();

  return (
    <>
      <div className="main-wrapper">
        <div className="account-page">
          <Header
            setlocal={props.setlocal}
            setIsLoggedIn={props.setIsLoggedIn}
            setUser={props.setUser}
          />

          <div
            className="row"
            style={{ height: `inherit`, paddingRight: "20px" }}
          >
            <div className="col-md-9 col-lg-8 login-left no-gutters">
              <img
                src={registerBanner}
                className="img-fluid"
                alt="Doccure Register"
                style={{
                  width: "inherit",
                  height: "inherit",
                }}
              />
            </div>

            <div
              className="col-md-12 col-lg-4 login-right"
              style={{ paddingTop: "30px" }}
            >
              <div className="login-header">
                <h2 style={{ textAlign: "center" }}>
                  <FormattedMessage
                    id="signup.signUpLabel"
                    defaultMessage="Sign Up"
                  />
                </h2>
              </div>

              {/* <!-- Register Form --> */}
              <form onSubmit={userSubmitEventHandler}>
                <div className="form-group form-focus focused">
                  <input
                    type="text"
                    className="form-control floating"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    required
                  />
                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.firstNameLabel"
                      defaultMessage="First Name"
                    />
                  </label>
                </div>
                <div className="form-group form-focus focused">
                  <input
                    type="text"
                    className="form-control floating"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    required
                  />
                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.lastNameLabel"
                      defaultMessage="Last Name"
                    />
                  </label>
                </div>

                <div className="form-group form-focus focused">
                  <input
                    type="id"
                    className="form-control floating"
                    value={idCard}
                    onChange={(e) => {
                      setIdCard(e.target.value);
                    }}
                    maxLength="10"
                    required
                  />
                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.IdCardLabel"
                      defaultMessage="ID Card Number"
                    />
                  </label>
                </div>

                <div className="form-group form-focus focused">
                  <input
                    type="tel"
                    className="registration-mobile-check form-control floating "
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                    }}
                    required
                    style={{ paddingLeft: "50px" }}
                    placeholder="09XXXXXXXX"
                    pattern="09[0-9]{8}"
                  />

                  {/* <IntlTelInput
                    containerClassName="intl-tel-input"
                    inputClassName="form-control"
                    defaultCountry="ec"
                    // autoPlaceholder={true}
                    preferredCountries=""
                    onlyCountries={["ec"]}
                    onPhoneNumberChange={(e, v, c) => {
                      console.log(e);
                      console.log(v);
                      console.log(c);
                      setMobile(v);
                      setMobileCheck(e);
                    }}
                  /> */}
                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.mobileNumberLabel"
                      defaultMessage="Mobile Number"
                    />
                  </label>
                </div>

                <div className="form-group form-focus focused">
                  <input
                    type="email"
                    className="form-control floating"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value.toLowerCase());
                    }}
                    required
                  />
                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.emailLabel"
                      defaultMessage="Email"
                    />
                  </label>
                </div>
                <div className="form-group form-focus focused">
                  <input
                    type="password"
                    className="form-control floating"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    minLength="4"
                    required
                  />
                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.passwordLabel"
                      defaultMessage="Password"
                    />
                  </label>
                </div>
                <div className="form-group form-focus focused">
                  <input
                    type="password"
                    className="form-control floating"
                    value={confirmPassword}
                    onChange={(e) => {
                      // if (value !== password)
                      // return console.error("Password doesn't match")
                      setConfirmPassword(e.target.value);
                    }
                    }
                    minLength="4"
                    required
                  />

                  <label className="focus-label">
                    <FormattedMessage
                      id="signup.confirmPasswordLabel"
                      defaultMessage="Confirm Password"
                    />
                  </label>
                </div>

                <div className="form-group">
                  <div className="row input-group">
                    <div className="col-sm-2">
                      <h5>
                        <FormattedMessage
                          id="signup.randMessLabel"
                          defaultMessage="As a"
                        />
                      </h5>
                    </div>
                    <div className="col-sm-3">
                      <div
                        className="form-group"
                        style={{ paddingLeft: "20px" }}
                      >
                        <input
                          type="radio"
                          id="user"
                          value="user"
                          name="role"
                          checked={role === "user" ? true : false}
                          onChange={(e) => setRole(e.target.value)}
                          required
                        />
                        <label for="user">
                          <FormattedMessage
                            id="signup.userRadioButton1"
                            defaultMessage="User"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="radio"
                          id="communityleader"
                          value="communityleader"
                          name="role"
                          checked={role === "communityleader" ? true : false}
                          onChange={(e) => setRole(e.target.value)}
                        />

                        <label for="communityleader">
                          <FormattedMessage
                            id="signup.communityLeaderRadioButton2"
                            defaultMessage="Community Leader"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {role === "communityleader" ? (
                  <div className="form-group">
                    <label>
                      <FormattedMessage
                        id="signup.purposeLabel"
                        defaultMessage="Fully describe your activities as a leader"
                      />
                    </label>
                    <textarea
                      rows="5"
                      className="form-control floating"
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </div>
                ) : null}

                <div
                  className="text-center"
                  style={{ marginTop: "15px", marginBottom: "15px" }}
                >
                  <p style={{ color: "#FF0000" }}>{msg}</p>
                  <p style={{ color: "#07631F" }}>{successMsg}</p>
                </div>
                <div className="text-right">
                  <Link
                    to="/login"
                    className="forgot-link"
                    style={{ color: "#000000" }}
                  >
                    <FormattedMessage
                      id="signup.accountAlreadyLink"
                      defaultMessage="Already have an account?"
                    />{" "}
                  </Link>
                </div>
                {isLoading ? (
                  <div className="text-center">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#00d0f1" }}
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id="loading"
                          defaultMessage="Loading..."
                        />
                      </span>
                    </Spinner>
                  </div>
                ) : (
                    <button
                      // className="btn btn-primary btn-block btn-lg login-btn"
                      className="btn btn-block btn-lg login-btn"
                      type="submit"
                    >
                      <FormattedMessage
                        id="signup.signUpButton"
                        defaultMessage="  Sign Up"
                      />
                    </button>
                  )}
              </form>
              {/* <!-- /Register Form --> */}
            </div>
          </div>
          {/* </div> */}
          {/* </div>
      </div>
    </div>
        </div > */}
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}

export default windowSize(RegisterScreen);
