import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./container/App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
import Cookies from "js-cookie";
// import { IntlProvider } from "react-intl";
// import English from "./languages/en-US.json";
// import Spanish from "./languages/es-SV.json";
// import SimpleMap from "./components/Home/SimpleMap";

// const local = navigator.language;
// console.log(local);

// let lang;
// if (local === "en-US") {
//   lang = English;
// } else {
//   lang = Spanish;
// }

// number of community leaders (DashBodard)
// list of community leaders 
// Messages (List)



ReactDOM.render(
  
  <App />,

  document.getElementById("root")
);

// firebase.initializeApp(config);
// askForPermissioToReceiveNotifications();

// axios.defaults.headers.common["Authorization"] = Cookies.get("Authorization");

// axios.defaults.withCredentials = true;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
