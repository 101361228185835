import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Redirect, Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import "../../assets/css/adminStyle.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import patient from "../../assets/img/patients/patient.jpg";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import CreateProject from "../CommunityLeaders/Projects/CreateProject";
import MyMingas from "../CommunityLeaders/Projects/MyMingas";
import MingaCoinRequests from "../CommunityLeaders/Requests/MingaCoinRequests";
import RequestAccountApproval from "../CommunityLeaders/Requests/RequestAccountApproval";
import SendMinga from "../CommunityLeaders/SendMinga";
import CommunityLeaderHeader from "../Header/CommunityLeaderHeader";
import ChangePassword from "../Password/ChangePassword";
import ProfileSetting from "../ProfileSettings/CommunityLeaderProfileSetting";
import CommunityLeaderSidebar from "../Sidebars/CommunityLeaderSidebar";
import CommunityLeaderDashboardDefaultScreen from "./CommunityLeaderDashboardDefaultScreen";
import { FormattedMessage } from "react-intl";

// import CommunityLeaderHeader from "../CommunityLeaders/CommunityLeaderHeader";

function CommunityLeaderDashboard(props) {
  let history = useHistory();
  let { path } = useRouteMatch();
  let [menuOpenedClass, setMenuOpenedClass] = useState();
  let [slideNavClass, setSlideNavClass] = useState();

  let handleSlideNav = (e) => {
    e.preventDefault();
    if (slideNavClass !== "" && menuOpenedClass !== "") {
      setMenuOpenedClass("");
      setSlideNavClass("");
    } else {
      setMenuOpenedClass("menu-opened");
      setSlideNavClass("slide-nav");
    }
  };
  let handleLogout = (e) => {
    // Cookies.remove("Authorization");
    // setTimeout(() => {}, 1);
    e.preventDefault();
    axios
      .get("/api/v1/auth/user/logout", { withCredentials: true })
      .then((response) => {
        console.log(response);
        props.checkLoginStatus();
        history.push("/");
        // window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [activeTab, setActiveTab] = useState({
    dashboard: "active",
    sendMinga: "",
    requestAccountApproval: "",
    createProject: "",
    poolSignature: "",
    contractDetails: "",
    settings: "",
    changePassword: "",
  });

  let [isGettingUserData, setIsGettingUserData] = useState(false);
  let [userData, setUserData] = useState("");
  let getUserData = () => {
    console.log(props.user.id);
    setIsGettingUserData(true);
    axios
      .get(`/api/v1/users/${props.user.id}`, { withCredentials: true })
      .then((response) => {
        if (
          response.data.pictureURL === undefined ||
          response.data.pictureURL == null
        )
          response.data.pictureURL = patient;
        setUserData(response.data);
        setIsGettingUserData(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let getUserDataAgain = () => {
    axios
      .get(`/api/v1/users/${props.user.id}`, { withCredentials: true })
      .then((response) => {
        if (
          response.data.pictureURL === undefined ||
          response.data.pictureURL == null
        )
          response.data.pictureURL = patient;
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  let handleUpdateUserData = () => {
    getUserDataAgain();
  };

  return (
    <>
      {isGettingUserData ? (
        <>
          <div className="text-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "#00d0f1" }}
            >
              <span className="sr-only">
                <FormattedMessage
                  id="loading"
                  defaultMessage="Loading..."
                />
              </span>
            </Spinner>
          </div>
        </>
      ) : (
          <div className={`main-wrapper ${slideNavClass}`}>
            {/* <CommunityLeaderHeader userData={props.user} /> */}

            <CommunityLeaderHeader
              setlocal={props.setlocal}
              userData={props.user}
              checkLoginStatus={props.checkLoginStatus}
              selectedNav={""}
              setSlideNavClass={setSlideNavClass}
            >
            </CommunityLeaderHeader>

            <CommunityLeaderSidebar
              match={props.match}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              userData={userData}
              checkLoginStatus={props.checkLoginStatus}
            />
            <div className="page-wrapper">
              <div className="content container-fluid">
                <Switch>
                  <Route exact path={`${path}`}>
                    <CommunityLeaderDashboardDefaultScreen
                      match={props.match}
                      userData={userData}
                      setActiveTab={setActiveTab}
                    />
                  </Route>
                  <Route exact path={`${path}/profilesettings`}>
                    <ProfileSetting
                      setActiveTab={setActiveTab}
                      userData={userData}
                      handleDataUpdated={handleUpdateUserData}
                    />
                  </Route>
                  <Route exact path={`${path}/changepassword`}>
                    <ChangePassword
                      setActiveTab={setActiveTab}
                      userData={userData}
                    />
                  </Route>
                  <Route exact path={`${path}/requestaccountapproval`}>
                    {userData.accountStatus !== "Approved" ? (
                      <RequestAccountApproval
                        setActiveTab={setActiveTab}
                        userData={userData}
                      />
                    ) : (
                        <Redirect to={`${path}`} />
                      )}
                  </Route>
                  <Route exact path={`${path}/mymingas`}>
                    {userData.accountStatus === "Approved" ? (
                      <MyMingas setActiveTab={setActiveTab} userData={userData} />
                    ) : (
                        <Redirect to={`${path}`} />
                      )}
                  </Route>
                  <Route exact path={`${path}/sendminga`}>
                    {userData.accountStatus === "Approved" ? (
                      <SendMinga
                        setActiveTab={setActiveTab}
                        userData={userData}
                      />
                    ) : (
                        <Redirect to={`${path}`} />
                      )}
                  </Route>
                  <Route exact path={`${path}/createproject`}>
                    {userData.accountStatus === "Approved" ? (
                      <CreateProject
                        setActiveTab={setActiveTab}
                        userData={userData}
                      />
                    ) : (
                        <Redirect to={`${path}`} />
                      )}
                  </Route>
                  <Route exact path={`${path}/coinrequests`}>
                    {userData.accountStatus === "Approved" ? (
                      <MingaCoinRequests
                        setActiveTab={setActiveTab}
                        userData={userData}
                      />
                    ) : (
                        <Redirect to={`${path}`} />
                      )}
                  </Route>
                  <Route path={`${path}`}>
                    <CommunityLeaderDashboardDefaultScreen
                      match={props.match}
                      setActiveTab={setActiveTab}
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default CommunityLeaderDashboard;
