import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ReactCompareImage from "react-compare-image";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function HomeCarousel(props) {
  return (
    <section id="carousel" className="section">
      <Carousel interval={8000}>
        {props.carouselimages.map((i, index) => (
          <Carousel.Item key={index}>
            <ReactCompareImage
              leftImage={i.beforePictureURL}
              rightImage={i.afterPictureURL}
            />
            <Carousel.Caption>
              <h1 style={{ color: "#ffffff", marginBottom: "5%" }}>
                {props.local === "en-US" ? (
                  <span>{i.englishDescription}</span>
                ) : (
                    <span>{i.spanishDescription}</span>
                  )}
              </h1>
              {/* <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p> */}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </section>
  );
}

export default HomeCarousel;
