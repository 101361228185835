import React from "react";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function StartMingaBlock4() {
  return (
    <section id="startminga" className="section home-block4-section">
      <div className="vertical-container">
        <div className="center">
          <div className="row no-gutters">
            <div className="col-12 text-center">
              <p className=" home-block1-title">
                <FormattedMessage
                  id="home.block4.startminga"
                  defaultMessage="Want to start a minga ?"
                />
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="col-12">
              <p className="home-block2-description">
                <FormattedMessage
                  id="home.block4.startmingadescription"
                  defaultMessage="Are you an authorized community, charity, or social group leader? You can be a Minga creator and the collaborative magic begins with your initiative. Start improving on a grand scale your community today! 
                Please understand that there are important responsibilities with this position."
                />
              </p>
            </div>
            <br />
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://proyectominga774683741.wordpress.com/blog-2/"
              >
                {" "}
                <p className="home-block1-description-signup">
                  <FormattedMessage
                    id="home.block4.startmingasignuplink"
                    defaultMessage="Learn more here and sign up."
                  />
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StartMingaBlock4;
