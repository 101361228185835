import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import axios from "axios";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
import clsx from "clsx";
import GoogleMapReact from "google-map-react";
import React, { Component, useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles({
  list: {
    width: 400,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#fce373",
  },
});

const useStylesMobile = makeStyles({
  list: {
    width: 300,
    height: "100%",
  },
  fullList: {
    width: "auto",
  },
  paper: {
    background: "#fce373",
  },
});

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const K_WIDTH = 40;
const K_HEIGHT = 40;

const greatPlaceStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: "absolute",
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: "5px solid #f44336",
  borderRadius: K_HEIGHT,
  backgroundColor: "white",
  textAlign: "center",
  color: "#3f51b5",
  fontSize: 16,
  fontWeight: "bold",
  padding: 4,
};

class MyGreatPlace extends Component {
  // static propTypes = {
  //   text: String,
  // };

  // static defaultProps = {};

  constructor(props) {
    super(props);
    // props.minga(props.item);
    // props.images(props.item);
    this.handleEvent = this.handleEvent.bind(this);
  }

  // shouldComponentUpdate = shouldPureComponentUpdate;

  handleEvent() {
    this.props.minga(this.props.item);
    this.props.images(this.props.item);
    this.props.toggleDrawer("left", true);
  }

  render() {
    return (
      <div onClick={this.handleEvent} style={greatPlaceStyle}>
        {"M"}
      </div>
    );
  }
}

function SimpleMap() {
  let [center, setCenter] = useState({ lat: -0.180653, lng: -78.467834 });
  let [zoom, setZoom] = useState(14);

  const matches = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const classesMobile = useStylesMobile();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(
        matches ? classes.list : classesMobile.list,
        classes.paper,
        {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        }
      )}
      role="presentation"
    // onClick={toggleDrawer("left", false)}
    // onKeyDown={toggleDrawer("left", false)}
    >
      <List>
        <ListItem>
          {selectedMinga !== undefined ? (
            <h1>{selectedMinga.projectName}</h1>
          ) : null}
        </ListItem>
        <ListItem>
          {selectedMinga !== undefined ? (
            <p>{selectedMinga.description}</p>
          ) : null}
        </ListItem>
        <ListItem>
          <h4>Project Owner:</h4>

          <h5 style={{ paddingLeft: "4px" }}>
            {selectedMinga !== undefined ? (
              <>
                {selectedMinga.communityleader.firstName}{" "}
                {selectedMinga.communityleader.lastName}
              </>
            ) : null}
          </h5>
        </ListItem>
        <ListItem>
          <h4>Contact Detail: </h4>
          <h5 style={{ paddingLeft: "4px" }}>
            {selectedMinga !== undefined ? (
              <>{selectedMinga.communityleader.mobile}</>
            ) : null}
          </h5>
        </ListItem>
        <ListItem>
          {/* <Carousel interval={5000}>
            {selectedMinga !== undefined ? (
              <>
                {selectedMinga.pictureURL !== undefined ? (
                  <>
                    {selectedMinga.pictureURL.map((i, index) => (
                      <Carousel.Item key={index}>
                        <img src={i} width="200px" />
                      </Carousel.Item>
                    ))}
                  </>
                ) : null}
              </>
            ) : null}
          </Carousel> */}
          <ImageGallery items={images} />
        </ListItem>
      </List>
    </div>
  );

  useEffect(() => {
    getMingas();
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        const coords = pos.coords;
        setCenter({
          lat: coords.latitude,
          lng: coords.longitude,
        });
      });
    }
  }, []);

  let [mingas, setMingas] = useState([]);
  let getMingas = () => {
    axios
      .get("/getmingas")
      .then((response) => {
        console.log(response.data);
        setMingas(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [selectedMinga, setSelectedMinga] = useState();
  let [images, setImages] = useState();
  let setSelectedImages = (item) => {
    let img = [];
    item.pictureURL.forEach((i) => {
      img.push({
        original: i,
        thumbnail: i,
      });
    });
    setImages(img);
  };

  return (
    // Important! Always set the container height explicitly

    <div id="map-section" style={{ height: "100vh", width: "100%" }}>
      <GoogleMapReact
        apiKey="AIzaSyDnkFfg3DqElEhRuRsZoBVCCLiJkH3PdBU"
        //   bootstrapURLKeys={{ key: "AIzaSyCyw9os06MDOVMPJ_JEjUiL17Qm4hyYwgs" }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        {mingas.map((i, index) => {
          return (
            <MyGreatPlace
              lat={i.mingaCoords.lat}
              lng={i.mingaCoords.lng}
              toggleDrawer={toggleDrawer("left", true)}
              minga={setSelectedMinga}
              images={setSelectedImages}
              item={i}
            ></MyGreatPlace>
          );
        })}
      </GoogleMapReact>

      <Drawer
        // minga={selectedMinga}
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </div>
  );
}

export default SimpleMap;
