import React from "react";
import { FormattedMessage } from "react-intl";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function WhatIsMingaBlock3() {
  return (
    <section id="whybarter" className="section home-block3-section">
      <div className="vertical-container">
        <div className="center">
          <div className="row no-gutters">
            <div className="col-12 text-center">
              <p className=" home-block1-title">
                <FormattedMessage
                  id="home.block3.whybarter"
                  defaultMessage="Why Barter ?"
                />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="home-block2-description">
                <FormattedMessage
                  id="home.block3.whybarterdescription"
                  defaultMessage="Bartering is also an ancient form of economy developed before money was around. It was usually done only face-to-face before, but the Minga Project platform allows you to form sophisticated bartering networks to greatly improve your standard of living."
                />
              </p>
            </div>
            <br />
          </div>
          <div className="row">
            <div className="col-12 ">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://proyectominga774683741.wordpress.com/infografia-para-compartir-infographs-to-share/"
              >
                <p
                  className="home-block1-description-signup"
                  style={{ textAlign: "center" }}
                >
                  {" "}
                  <FormattedMessage
                    id="home.block3.whybarterlearnmore"
                    defaultMessage="Learn More"
                  />
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhatIsMingaBlock3;
