import React, { useState } from "react";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import SimpleMap from "./SimpleMap";

function HomeMap() {
  // let [companySearchName, setCompanySearchName] = useState();
  // let [companySearchLocation, setCompanySearchLocation] = useState();

  return (
    <section className="section">
      {/* <div className="container-fluid">
        <div className="banner-wrapper">
          <div className="search-box"></div>
        </div>
      </div> */}
      <SimpleMap />
    </section>
  );
}

export default HomeMap;
