import Avatar from "@material-ui/core/Avatar";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Carousel, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import AdvertisementWidget from "./AdvertisementWidget";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStylesSnackbar = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

function AdPage(props) {
  const mess1 = (
    <FormattedMessage id="marketPlace.adPage.mess1" defaultMessage="success" />
  );

  const mess2 = (
    <FormattedMessage
      id="marketPlace.adPage.mess2"
      defaultMessage="Successfully Purchased Ad."
    />
  );

  const mess3 = (
    <FormattedMessage id="marketPlace.adPage.error3" defaultMessage="error" />
  );

  const mess4 = (
    <FormattedMessage
      id="marketPlace.adPage.error4"
      defaultMessage="User cannot buy his own ads."
    />
  );

  const mess5 = (
    <FormattedMessage
      id="marketPlace.adPage.error5"
      defaultMessage="Does not have sufficient funds."
    />
  );

  const mess6 = (
    <FormattedMessage
      id="marketPlace.adPage.error6"
      defaultMessage="Internal Server Error."
    />
  );

  const mess7 = (
    <FormattedMessage
      id="marketPlace.adPage.error7"
      defaultMessage="Unknown Error Occurred, Try again."
    />
  );

  const classes = useStyles();
  const classesSnackbar = useStylesSnackbar();

  let [snackbarController, setSnackbarController] = useState(false);
  let [snackbarSeverity, setSnackbarSeverity] = useState();
  let [snackbarMsg, setSnackbarMsg] = useState("");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarController(false);
  };

  let { adId } = useParams();
  useEffect(() => {
    getAdDetails();
  }, []);

  useEffect(() => {
    getAdDetails();
  }, [adId]);

  let [ad, setAd] = useState();
  let [isGettingAdDetails, setIsGettingAdDetails] = useState(false);
  let getAdDetails = () => {
    setIsGettingAdDetails(true);
    axios
      .get(`/api/v1/placement/${adId}`, { withCredentials: true })
      .then((response) => {
        setAd(response.data);
        console.log(response.data.userId._id);
        getOtherAds(response.data.userId._id);
        setIsGettingAdDetails(false);
      })
      .catch((error) => {
        setIsGettingAdDetails(false);
        console.log(error);
      });
  };

  let [otherAds, setOtherAds] = useState([]);
  let getOtherAds = (id) => {
    axios
      .get(`/api/v1/placement/otherads/${id}`, { withCredentials: true })
      .then((response) => {
        setOtherAds(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [isBuying, setIsBuying] = useState(false);
  let handleBuyAd = () => {
    setIsBuying(true);
    let data = {
      adId: ad._id,
      userId: props.user.id,
    };
    axios
      .post(`/api/v1/placement/purchase`, data, { withCredentials: true })
      .then((response) => {
        setIsBuying(false);
        setSnackbarSeverity("success");
        // setSnackbarSeverity(mess1);

        // setSnackbarMsg("Successfully Purchased Ad.");
        setSnackbarMsg(mess2);
        setSnackbarController(true);
      })
      .catch((error) => {
        setIsBuying(false);
        setSnackbarSeverity("error");
        // setSnackbarSeverity(mess3);
        if (error.response !== undefined) {
          if (error.response.status === 600) {
            // setSnackbarMsg("User cannot buy his own ads.");
            setSnackbarMsg(mess4);
          } else if (error.response.status === 601) {
            // setSnackbarMsg("Does not have sufficient funds.");
            setSnackbarMsg(mess5);
          } else if (error.response.status === 500) {
            // setSnackbarMsg("Internal Server Error.");
            setSnackbarMsg(mess6);
          } else {
            // setSnackbarMsg("Unknown Error Occurred, Try again.");
            setSnackbarMsg(mess7);
          }
        } else {
          // setSnackbarMsg("Unknown Error Occurred, Try again.");
          setSnackbarMsg(mess7);
        }
        setSnackbarController(true);
        console.log(error);
      });
  };
  console.log("ADD>USERID", ad);
  return (
    <>
      {ad !== undefined ? (
        <>
          <div className="row ad-page">
            <div className="col-md-12 col-lg-6 col-xl-4 theiaStickySidebar">
              <Carousel className="ad-page-carousel">
                {ad.pictureURLs.map((i, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100" src={i} alt="img" />
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
            <div className="col-md-12 col-lg-6 col-xl-8">
              <div className="container">
                <div className="row">
                  <p className="ad-page-title">{ad.title}</p>
                </div>
                <br />
                <div className="row">
                  <p className="ad-page-description">{ad.description}</p>
                </div>
                <br />
                <br />
                <div className="row">
                  <p className="ad-page-description">Type: {ad.adType}</p>
                </div>
                <div className="row">
                  <p className="ad-page-price">Price: {ad.price}</p>
                </div>
                <div className="row">
                  {isBuying ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#00d0f1" }}
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id="loading"
                          defaultMessage="Loading..."
                        />
                      </span>
                    </Spinner>
                  ) : (
                      <button className="btn" onClick={handleBuyAd}>
                        <FormattedMessage
                          id="marketPlace.adPlace.buyButton"
                          defaultMessage="Buy"
                        />
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
          {ad.userId !== null && ad.userId !== undefined ? (
            <>
              <div className="row no-gutters ad-page-owner">
                <div className="col-12">
                  <h3>
                    <FormattedMessage
                      id="marketPlace.adPlace.adOwner"
                      defaultMessage="Ad Owner"
                    />
                  </h3>
                  <div className={classes.root}>
                    <Avatar src={ad.userId.pictureURL !== undefined && ad.userId.pictureURL !== null ? ad.userId.pictureURL : ""} className={classes.large}>
                      {ad.userId.firstName[0]}
                    </Avatar>
                    <div>
                      <p className="ad-page-ad-owner-name">
                        {ad.userId.firstName} {ad.userId.lastName}
                      </p>
                      <p className="ad-page-ad-owner-email">{ad.userId.email}</p>
                      <p className="ad-page-ad-owner-mobile">{ad.userId.mobile}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row no-gutters ad-page-owner">
                <div className="col-12">
                  <h3>
                    <FormattedMessage
                      id="adPage.sameOwner"
                      defaultMessage="Ads by same owner"
                    />
                  </h3>
                </div>
              </div>
              <div className="row ">
                {otherAds !== undefined || otherAds !== null ? (
                  <>
                    {otherAds.map((item) => (
                      <div className="col-12 col-md-6 col-lg-3">
                        <AdvertisementWidget key={item._id} ad={item} />
                      </div>
                    ))}
                  </>
                ) : null}
              </div>
            </>
          ) : (<div></div>)}


          <Snackbar
            open={snackbarController}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity={snackbarSeverity}>
              {snackbarMsg}
            </Alert>
          </Snackbar>
        </>
      ) : null}
    </>
  );
}

export default AdPage;
