import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// import "../../assets/css/adminStyle.css";
import "../../assets/css/adminStyle.css";
// import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function AdminDashboardDefaultScreen(props) {
  let [usersCount, setUsersCount] = useState(0);
  let [approvedCommunityLeaderCount, setApprovedCommunityLeaderCount] = useState(0);
  let [pendingCommunityLeaderCount, setPendingCommunityLeaderCount] = useState(0);
  let [agentCount, setAgentCount] = useState(0);

  let getCounts = () => {
    axios
      .get("/api/v1/admin/getCounts", { withCredentials: true })
      .then((response) => {
        console.log(response);
        setUsersCount(response.data.userCount);
        setApprovedCommunityLeaderCount(response.data.approvedCommunityLeaderCount);
        setPendingCommunityLeaderCount(response.data.pendingCommunityLeaderCount);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  };
  useEffect(() => {
    props.setActiveTab({
      dashboard: "active",
    });
    getCounts();
  }, []);

  return (
    <>
      {props.userData !== "" ? (
        <>
          {/* <!-- Page Header --> */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">
                  <FormattedMessage
                    id="admin.defaultdashboard.heading"
                    defaultMessage="Welcome Admin!"
                  />
                </h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item active">
                    <FormattedMessage
                      id="admin.defaultdashboard.breadcrumb"
                      defaultMessage="Dashboard"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* <!-- /Page Header --> */}

          <div className="row">
            <div className="col-12 col-sm-3">
              <Link to={`${props.match.url}/totalcommunityleaders`}>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-success">
                        <i className="fas fa-users"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{approvedCommunityLeaderCount}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">
                        <FormattedMessage
                          id="admin.defaultDashboard.approvedCommunityLeaders"
                          defaultMessage="Approved Community Leaders"
                        />
                      </h6>
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-success w-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-12 col-sm-3">
              <Link to={`${props.match.url}/pendingrequests`}>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-danger border-danger">
                        <i className="fa fa-hourglass"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{pendingCommunityLeaderCount}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">
                        <FormattedMessage
                          id="admin.defaultDashboard.pendingRequests"
                          defaultMessage="Pending Requests"
                        />
                      </h6>
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-danger w-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            {/* <div className="col-12 col-sm-3"> */}
            {/* <Link to={`${props.match.url}/reviewmoderations`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon text-secondary border-secondary">
                                        <i className="fa fa-hourglass"></i>
                                    </span>
                                    <div className="dash-count">
                                        <h3>{pendingReviewCounts}</h3>
                                    </div>
                                </div>
                                <div className="dash-widget-info">
                                    <h6 className="text-muted">Total Pending Reviews</h6>
                                    <div className="progress progress-sm">
                                        <div className="progress-bar bg-secondary w-100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link> */}
            {/* </div> */}
            <div className="col-12 col-sm-3">
              <Link to={`${props.match.url}/totalusers`}>

                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-purple border-purple">
                        <i className="fa fa-user"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{usersCount}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-purple">
                      <h6 className="text-muted">
                        <FormattedMessage
                          id="admin.defaultDashboard.totalUsers"
                          defaultMessage="Total Users"
                        />
                      </h6>
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-purple w-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            {/* <div className="col-12 col-sm-3">
              <Link to={`${props.match.url}/totalagents`}>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-widget-header">
                      <span className="dash-widget-icon text-info border-info">
                      <i class="fas fa-user-secret"></i>
                      </span>
                      <div className="dash-count">
                        <h3>{agentCount}</h3>
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6 className="text-muted">
                        <FormattedMessage
                          id="admin.defaultDashboard.totalAgents"
                          defaultMessage="Total Agents"
                        />
                      </h6>
                      <div className="progress progress-sm">
                        <div className="progress-bar bg-info w-100"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </>
      ) : null}
    </>
  );
}

export default AdminDashboardDefaultScreen;
