import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import { Route, useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import UserHeader from "../../container/Header/UserHeader";
import AdPage from "./MarketePlaceComponents/AdPage";
// import Breadcrumb from "./Breadcrumb";
import AdvertisementWidget from "./MarketePlaceComponents/AdvertisementWidget";

function MarketPlace(props) {
  let { path, url } = useRouteMatch();
  let [isLogin, setIsLogin] = useState(false);
  let [userData, setUserData] = useState();
  let locale = Cookies.get("Locale");

  useEffect(() => {
    getAdvertisementCategories();
    handleSearchAds();
    if (props.location.state !== undefined) {
      if (props.location.state.user !== undefined) {
        console.log(props.location.state.user);
        setIsLogin(true);
      } else {
        setUserData(props.location.state.userData);
      }
    }
  }, []);

  let [categories, setCategories] = useState([]);
  let getAdvertisementCategories = () => {
    axios
      .get("/api/v1/advertisementcategories", { withCredentials: true })
      .then((response) => {
        let data = [];
        data = response.data;
        data.push({ englishName: "All", spanishName: "Todas" });

        setCategories(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [ads, setAds] = useState([]);
  let [isGettingAds, setIsGettingAds] = useState(false);
  let [title, setTitle] = useState("");
  let [location, setLocation] = useState("");
  let [category, setCategory] = useState();
  let [value, setValue] = React.useState([0, 500]);
  let [adType, setAdType] = useState();

  let [adT, setAdT] = useState([]);
  let handleSelectAdType = (value) => {
    let gd = adT;
    if (gd.includes(value)) {
      gd = gd.filter((i) => i !== value);
    } else {
      gd.push(value);
    }
    setAdT(gd);

    if (gd.length === 1) {
      if (gd[0] === "Offer") {
        setAdType("Offer");
      } else if (gd[0] === "Demand") {
        setAdType("Demand");
      }
    } else if (gd.length === 2 || gd.length === 0) {
      setAdType("");
    }
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let handleSearchAds = () => {
    setIsGettingAds(true);
    let catId = "";
    if (category !== undefined && category !== null) {
      catId = category._id;
    }
    let searchURL = `/api/v1/placement/search?title=${title}&location=${location}&category=${catId}&minPrice=${value[0]}&maxPrice=${value[1]}&adType=${adType}`;
    axios
      .get(searchURL, { withCredentials: true })      
      .then((response) => {
        console.log("HELLO",response.data);
        setIsGettingAds(false);
        setAds(response.data);
      })
      .catch((error) => {
        setIsGettingAds(false);
        console.log(error);
      });
  };

  useEffect(() => {
    handleSearchAds();
  }, [adType, category, value]);
  console.log("categories", categories);
  return (
    <div className="main-wrapper">
      {/* <DetectAdBlock pathname={window.location.pathname} /> */}
      <UserHeader
        selectedNav={"market"}
        setlocal={props.setlocal}
        userData={props.user}
        checkLoginStatus={props.checkLoginStatus}
      />

      <div className="content">
        <div className="container-fluid">
          <div className="container">
            <Route exact path={`${path}`}>
              <div className="row">
                <div className="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
                  {/* <!-- Search Filter --> */}
                  <div className="card search-filter">
                    <div className="card-header">
                      <h4 className="card-title mb-0">
                        <FormattedMessage
                          id="marketplace.searchFilter"
                          defaultMessage="Search Filter"
                        />
                      </h4>
                    </div>
                    <div className="card-body">
                      <div className="filter-widget">
                        {locale === "en-US" || locale === undefined ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Ads using keywords"
                            onChange={(e) => {
                              setTitle(e.target.value);
                            }}
                          />
                        ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Buscar anuncios por palabra clave"
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                          )}
                      </div>
                      <div className="filter-widget">
                        {locale === "en-US" || locale === undefined ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Ads using Location"
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                          />
                        ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Buscar anuncios por ubicación"
                              onChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                          )}
                      </div>
                      <div className="filter-widget">
                        {locale === "en-US" || locale === undefined ? (
                          <Autocomplete
                            id="combo-dox-demo"
                            options={categories}
                            getOptionLabel={(option) => option.englishName}
                            onChange={(event, value) => {
                              console.log(value);
                              setCategory(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Ads Category"
                                variant="outlined"
                              // onSelect={(e) => setCategory(e.target.value)}
                              />
                            )}
                          />
                        ) : (
                            <Autocomplete
                              id="combo-dox-demo"
                              options={categories}
                              getOptionLabel={(option) => option.spanishName}
                              onChange={(event, value) => {
                                console.log(value);
                                setCategory(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Categoría de anuncios"
                                  variant="outlined"
                                // onSelect={(e) => setCategory(e.target.value)}
                                />
                              )}
                            />
                          )}

                      </div>
                      <div className="filter-widget">
                        <Typography id="range-slider" gutterBottom>
                          <FormattedMessage
                            id="marketplace.coinRange"
                            defaultMessage="Coin range"
                          />
                        </Typography>
                        <Slider
                          min={0}
                          max={1000}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                          getAriaValueText={valuetext}
                        />
                      </div>
                      {/* <div className="filter-widget">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Location"
                        onChange={(e) => {
                          setSearchLocation(e.target.value);
                        }}
                      />
                    </div> */}

                      <div className="filter-widget">
                        <h4>
                          <FormattedMessage
                            id="marketplace.adsType"
                            defaultMessage="Ads Type"
                          />
                        </h4>
                        <div>
                          <label className="custom_check">
                            <input
                              type="checkbox"
                              name="gender_type"
                              onClick={(e) => handleSelectAdType("Offer")}
                            />
                            <span className="checkmark"></span>
                            <FormattedMessage
                              id="marketplace.offer"
                              defaultMessage="Products and services I am looking for"
                            />
                          </label>
                        </div>
                        <div>
                          <label className="custom_check">
                            <input
                              type="checkbox"
                              name="gender_type"
                              onClick={(e) => handleSelectAdType("Demand")}
                            />
                            <span className="checkmark"></span>
                            <FormattedMessage
                              id="marketplace.demand"
                              defaultMessage="Products and services I am offering"
                            />
                          </label>
                        </div>
                      </div>
                      {isGettingAds ? (
                        <div className="text-center">
                          <Spinner
                            animation="border"
                            role="status"
                            style={{ color: "#00d0f1" }}
                          >
                            <span className="sr-only">
                              <FormattedMessage
                                id="loading"
                                defaultMessage="Loading..."
                              />
                            </span>
                          </Spinner>
                        </div>
                      ) : (
                          <>
                            <div className="btn-search">
                              <button
                                type="button"
                                className="btn btn-block"
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleSearchAds();
                                }}
                              >
                                <FormattedMessage
                                  id="marketplace.serachButton"
                                  defaultMessage="Search"
                                />
                              </button>
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                  {/* <!-- /Search Filter --> */}
                </div>

                <div className="col-md-12 col-lg-8 col-xl-9">
                  <>
                    <div className="row">
                      {ads.map((item) => (
                        <div className="col-12 col-md-6 col-lg-4">
                          <AdvertisementWidget key={item._id} ad={item} />
                        </div>
                      ))}
                    </div>
                  </>
                </div>
              </div>
            </Route>
            <Route exact path={`${path}/:adId`}>
              <AdPage user={props.user} />
            </Route>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketPlace;
