import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import {
  Route,
  Switch,

  useHistory, useRouteMatch
} from "react-router-dom";
import "../../assets/css/adminStyle.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import patient from "../../assets/img/patients/patient.jpg";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import AdvertisementCategoriesComponent from "../Admin/AdvertisementCategoriesComponent";
import MingaCategoriesComponent from "../Admin/MingaCategoriesComponent";
import PendingRequestsScreen from "../Admin/PendingRequestsScreen";
import ProjectRequests from "../Admin/Projects/ProjectRequests";
import TotalCommunityLeaders from "../Admin/TotalCommunityLeaders";
import TotalUsers from "../Admin/TotalUsers";
import UploadHomeImages from "../Admin/UploadHomeImages";
import AdminHeader from "../Header/AdminHeader";
import ChangePassword from "../Password/ChangePassword";
import ProfileSetting from "../ProfileSettings/AdminProfileSetting";
import AdminSidebar from "../Sidebars/AdminSidebar";
import AdminDashboardDefaultScreen from "./AdminDashboardDefaultScreen";


function AdminDashboard(props) {
  let history = useHistory();
  let { path } = useRouteMatch();
  let [menuOpenedClass, setMenuOpenedClass] = useState();
  let [slideNavClass, setSlideNavClass] = useState();

  let handleSlideNav = (e) => {
    e.preventDefault();
    if (slideNavClass != "" && menuOpenedClass != "") {
      setMenuOpenedClass("");
      setSlideNavClass("");
    } else {
      setMenuOpenedClass("menu-opened");
      setSlideNavClass("slide-nav");
    }
  };

  let [activeTab, setActiveTab] = useState({
    dashboard: "active",
    sendMinga: "",
    requestAccountApproval: "",
    createProject: "",
    poolSignature: "",
    contractDetails: "",
    settings: "",
    changePassword: "",
  });

  let [isGettingUserData, setIsGettingUserData] = useState(false);
  let [userData, setUserData] = useState();
  let getUserData = () => {
    setIsGettingUserData(true);
    axios
      .get(`/api/v1/users/${props.user.id}`, { withCredentials: true })
      .then((response) => {
        if (
          response.data.pictureURL === undefined ||
          response.data.pictureURL == null
        )
          response.data.pictureURL = patient;
        setUserData(response.data);
        setIsGettingUserData(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);

  let handleUpdateUserData = () => {
    getUserDataAgain();
  };

  let getUserDataAgain = () => {
    axios
      .get(`/api/v1/users/${props.user.id}`, { withCredentials: true })
      .then((response) => {
        if (
          response.data.pictureURL === undefined ||
          response.data.pictureURL == null
        )
          response.data.pictureURL = patient;
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <>
      {isGettingUserData ? (
        <>
          <div className="text-center">
            <Spinner
              animation="border"
              role="status"
              style={{ color: "#00d0f1" }}
            >
              <span className="sr-only">
                <FormattedMessage
                  id="loading"
                  defaultMessage="Loading..."
                />
              </span>
            </Spinner>
          </div>
        </>
      ) : (
          <div className={`main-wrapper ${slideNavClass}`}>

            <AdminHeader
              setlocal={props.setlocal}
              userData={props.user}
              checkLoginStatus={props.checkLoginStatus}
              selectedNav={""}
              setSlideNavClass={setSlideNavClass}
            />
            <AdminSidebar
              match={props.match}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              checkLoginStatus={props.checkLoginStatus}
            />
            <div className="page-wrapper">
              <div className="content container-fluid">
                {/* <LoadTab
            screen={tab}
            tabChangeHandler={handleTabChangeEvents}
            selectedCompanyId={selectedCompanyId}
          /> */}
                <Switch>
                  <Route exact path={`${path}`}>
                    <AdminDashboardDefaultScreen
                      match={props.match}
                      setActiveTab={setActiveTab}
                    />
                  </Route>
                  <Route exact path={`${path}/categories`}>
                    <MingaCategoriesComponent
                      setActiveTab={setActiveTab}
                      userData={userData}
                    />
                  </Route>
                  <Route exact path={`${path}/advertisementcategories`}>
                    <AdvertisementCategoriesComponent
                      setActiveTab={setActiveTab}
                      userData={userData}
                    />
                  </Route>

                  <Route exact path={`${path}/profilesettings`}>
                    <ProfileSetting
                      setActiveTab={setActiveTab}
                      userData={userData}
                      handleDataUpdated={handleUpdateUserData}
                    />
                  </Route>
                  <Route exact path={`${path}/changepassword`}>
                    <ChangePassword
                      setActiveTab={setActiveTab}
                      userData={userData}
                      url={"users"}
                    />
                  </Route>
                  <Route path={`${path}/pendingrequests`}>
                    <PendingRequestsScreen setActiveTab={setActiveTab} />
                  </Route>
                  <Route path={`${path}/totalcommunityleaders`}>
                    <TotalCommunityLeaders setActiveTab={setActiveTab} />
                  </Route>
                  {/* <Route path={`${path}/totalagents`}>
                    <TotalAgents setActiveTab={setActiveTab} />
                  </Route> */}

                  <Route path={`${path}/totalusers`}>
                    <TotalUsers setActiveTab={setActiveTab} />
                  </Route>

                  <Route path={`${path}/projectrequests`}>
                    <ProjectRequests setActiveTab={setActiveTab} />
                  </Route>
                  <Route path={`${path}/uploadhomeimages`}>
                    <UploadHomeImages setActiveTab={setActiveTab} />
                  </Route>
                  {/* <Route path={`${path}/poolsignature`}>
                            <PoolSignature setActiveTab={setActiveTab} />
                        </Route> */}
                  {/* <Route path={`${path}/companyrequests`}>
                            <AccountApprovalRequests setActiveTab={setActiveTab} />
                        </Route>
                        <Route path={`${path}/reviewmoderations`}>
                            <ReviewModeration setActiveTab={setActiveTab} />
                        </Route>
                        <Route exact path={`${path}/approvedcompanies`}>
                            <ApprovedCompanies setActiveTab={setActiveTab} />
                        </Route>
                        <Route exact path={`${path}/rejectedcompanies`}>
                            <RejectedCompanies setActiveTab={setActiveTab} />
                        </Route>
                        <Route exact path={`${path}/smartcontractdetails`}>
                            <ContractDetailScreen setActiveTab={setActiveTab} />
                        </Route>
                        <Route exact path={`${path}/profilesettings`}>
                            <ProfileSetting
                                setActiveTab={setActiveTab}
                                userData={userData}
                                handleDataUpdated={handleUpdateUserData}
                            />
                        </Route>
                        <Route exact path={`${path}/changepassword`}>
                            <ChangePassword
                                setActiveTab={setActiveTab}
                                userData={userData}
                                url={"users"}
                            />
                        </Route> */}
                  <Route path={`${path}`}>
                    <AdminDashboardDefaultScreen
                      match={props.match}
                      setActiveTab={setActiveTab}
                    />
                  </Route>
                </Switch>
              </div>
            </div>
          </div>
        )}
    </>
  );
}

export default AdminDashboard;
