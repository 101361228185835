import axios from "axios";
import React, { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";
import windowSize from "react-window-size";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import loginBanner from "../../../assets/img/signIn.jpg";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
// import mobileImage from "../assets/img/mobileImage.png";
import Header from "../../Header/Header";

function LoginScreen(props) {
  const mess1 = (
    <FormattedMessage
      id="login.error1"
      defaultMessage="Incorrect email or password entered"
    />
  );
  const mess2 = (
    <FormattedMessage
      id="login.error2"
      defaultMessage=" Cannot send 2FA email, try again."
    />
  );
  const mess3 = (
    <FormattedMessage
      id="login.error3"
      defaultMessage="Confirm email address by clicking on confirmation link sent to you"
    />
  );
  const mess4 = (
    <FormattedMessage
      id="login.error4"
      defaultMessage="Unknown Error Occured, try again."
    />
  );
  const mess5 = (
    <FormattedMessage
      id="login.error5"
      defaultMessage="Individual users must login using Mobile Application."
    />
  );
  const mess6 = (
    <FormattedMessage id="login.error6" defaultMessage="Try login again" />
  );
  const mess7 = (
    <FormattedMessage
      id="login.error7"
      defaultMessage="Incorrect Pin Entered"
    />
  );
  const mess8 = (
    <FormattedMessage
      id="login.error8"
      defaultMessage="Your account is suspended. Contact us at support@proyectominga.com with your suspended email address"
    />
  );
  const mess9 = (
    <FormattedMessage
      id="login.error9"
      defaultMessage="Cannot send verification email, try again."
    />
  );
  // const totalWaitTime = 30;
  let [totalWaitTime, setTotalWaitTime] = useState(30);
  let [time, setTime] = useState(totalWaitTime);
  let [showCountDown, setShowCountDown] = useState(false);
  let [resetButton, setResetButton] = useState(false);
  let [isResend, setisResend] = useState(false);


  let history = useHistory();
  let [email, setEmail] = useState();
  let [password, setPassword] = useState();
  let [data, setData] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  let [isError, setIsError] = useState(false);
  let [msg, setMsg] = useState("");

  let [twoFACheck, setTwoFACheck] = useState(false);

  let handleSubmitEvent = (event) => {
    setMsg("");
    setIsError(false);
    setIsLoading(true);
    // setTotalWaitTime(2)
    setTime(totalWaitTime);
    // setisResend(false);
    // //YE KHATM KRNA HY 
    // setTwoFACheck(true);

    setShowCountDown(false);
    setResetButton(true);
    // setTime(totalWaitTime);
    event.preventDefault();
    axios
      .post(
        "/api/v1/auth/user/login",
        {
          email: email,
          password: password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data === "2FA") {
          setPinEmail(email);
          setEmail("");
          setPassword("");
          setShowCountDown(true);
          setTwoFACheck(true);
          // var refreshIntervalId = setInterval(() => {
          //   if (time !== -1) {
          //     setTime(time--);
          //   }
          //   else {
          //     setisResend(true);
          //     setResetButton(false)
          //     setTime(totalWaitTime);
          //     clearInterval(refreshIntervalId);
          //     console.log("hello first");
          //   }
          // }, 1000)
          // var refreshIntervalId = setInterval(() => {
          //   if (time !== -1) {
          //     setTime(time--);
          //   }
          //   else {
          //     setShowCountDown(true);
          //     clearInterval(refreshIntervalId);
          //     setTotalWaitTime(totalWaitTime * 2);
          //     setTime(totalWaitTime);
          //     // console.log("hello @FA");
          //   }
          // }, 1000)
        } else {
          console.log("zohaib: ", response.data);

          // Cookies.set("Authorization", response.data.token, {
          //   sameSite: "Strict",
          //   secure: true,
          // });
          //
          props.setUser(response.data.user);
          props.setIsLoggedIn(response.data.loggedIn);
          // alert("HELLO",response.data.id)
          localStorage.setItem("_id", response.data.id);
          // setData(response.data.token);
          // checkPermission(response.data.id, response.data.role);
          // setIsLoading(false);
          // window.location.reload();
          history.push("/dashboard");
          // setTimeout(() => {
          //   history.push("/dashboard");
          // }, 1000);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          setResetButton(false);
          setisResend(false);
          setResetButton(false)
          if (error.response.status === 400) {
            // setMsg("Incorrect email or password entered");
            if (
              error.response.data.localeCompare("Your account is suspended") === 0) {
              setMsg(mess8);
            } else {
              setMsg(mess1);
            }
          } else if (error.response.status === 501) {
            // setMsg("Cannot send 2FA email, try again.");
            setMsg(mess2);
            // setShowCountDown(true);
            // // console.log("2FA");
            // var refreshIntervalId = setInterval(() => {
            //   if (time !== -1) {
            //     setTime(time--);
            //   }
            //   else {
            //     setShowCountDown(false);
            //     clearInterval(refreshIntervalId);
            //     setTime(totalWaitTime);
            //     console.log("hello msg");
            //   }
            // }, 1000)

          } else if (error.response.status === 600) {
            setMsg(
              // "Confirm email address by clicking on confirmation link sent to you"
              mess3
            );

            var refreshIntervalId = setInterval(() => {
              if (time !== -1) {
                setTime(time--);
              }
              else {
                setisResend(true);
                setResetButton(false)
                clearInterval(refreshIntervalId);
                setTotalWaitTime(totalWaitTime * 2);
                setTime(totalWaitTime);
                console.log("hello1111");
              }
            }, 1000)
            setResetButton(true);
          } else {
            // setMsg("Unknown Error Occured, try again.");
            setMsg(mess4);
          }
        } else {
          // setMsg("Unknown Error Occured, try again.");
          setMsg(mess4);
        }
        console.log(error);
        console.log(error.response);

        setIsError(true);
        setIsLoading(false);
      });
  };

  let [pinEmail, setPinEmail] = useState();
  let [pin, setPin] = useState("");
  let [isSendingPin, setIsSendingPin] = useState(false);
  let [pinError, setPinError] = useState("");
  let ResendCode = (e) => {

    // console.log("RESEND BUTTON", e, time,pinEmail);
    if (e === "email_verification") {

      setResetButton(true);
      setisResend(false);
      setTotalWaitTime(totalWaitTime * 2);
      let data = {
        email: email,
        method: e
      }
      // console.log("DATA", data)
      axios
        .post("/api/v1/auth/user/resendEmail", data, { withCredentials: true })
        .then(
          (response) => {
            console.log("RES", response);
            if (response.data.success === false) {
              setMsg(mess9);
            }
          },
          (error) => {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
            }
            setMsg(mess4);
            setIsLoading(false);
            setIsError(true);
          }
        );
      var refreshIntervalId = setInterval(() => {
        if (time !== -1) {
          setTime(time--);
        }
        else {
          setisResend(true);
          setResetButton(false)
          setTime(totalWaitTime);
          clearInterval(refreshIntervalId);
          // console.log("hello");
        }
      }, 1000)
    }
    else {
      setShowCountDown(false);
      setTotalWaitTime(totalWaitTime * 2);
      // setisResend(false);
      let data = {
        email: pinEmail,
        method: e
      }
      console.log("DATA", data)
      axios
        .post("/api/v1/auth/user/resendEmail", data, { withCredentials: true })
        .then(
          (response) => {
            console.log("RES", response);
          },
          (error) => {
            if (process.env.NODE_ENV === "development") {
              console.log(error);
            }
            setMsg(mess4);
            console.log(error);
            setIsLoading(false);
            setIsError(true);
          }
        );

      var refreshIntervalId = setInterval(() => {
        if (time !== -1) {
          setTime(time--);
        }
        else {
          setShowCountDown(true);
          // setResetButton(false)
          setTime(totalWaitTime);
          clearInterval(refreshIntervalId);
          // console.log("hellocountdown");
        }
      }, 1000)
    }

  }

  let handlePinSubmitEvent = (e) => {
    e.preventDefault();
    
    // setShowCountDown(true);
    setPinError("");

    setIsSendingPin(true);
    let data = {
      email: pinEmail,
      pin: pin,
    };
    console.log("HANDLE PIN SUBMIT",data);
    axios
      .post("/api/v1/auth/user/2FAlogin", data, { withCredentials: true })
      .then((response) => {
        console.log("zohaib: ", response.data);
        if (response.data.role === "participant") {
          // setMsg("Individual users must login using Mobile Application.");
          setMsg(mess5);
        } else {
          // Cookies.set("Authorization", response.data.token, {
          //   sameSite: "Strict",
          //   secure: true,
          // });
          props.setUser(response.data.user);
          props.setIsLoggedIn(response.data.loggedIn);
          // setData(response.data.token);
          // checkPermission(response.data.id, response.data.role);
          // setIsLoading(false);
          // window.location.reload();
          history.push("/dashboard");
          // setTimeout(() => {
          //   history.push("/dashboard");
          // }, 1000);
        }
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            // setPinError("Try login again");
            setPinError(mess6);
          } else if (error.response.status === 401) {
            // setPinError("Incorrect Pin Entered");
            setPinError(mess7);
          } else {
            // setPinError("Unknown Error Occured, try again.");
            setPinError(mess4);
          }
        } else {
          // setPinError("Unknown Error Occured, try again.");
          setPinError(mess4);
        }
        console.log(error);
        console.log(error.response);
        // setPinError(error.response.data);
        setIsSendingPin(false);
      });
  };

  return (
    <div className="main-wrapper">
      <div className="account-page">
        <Header
          setlocal={props.setlocal}
          setIsLoggedIn={props.setIsLoggedIn}
          setUser={props.setUser}
        />

        <div className="row " style={{ height: `90vh`, paddingRight: "20px" }}>
          <div className="col-md-9 col-lg-8 login-left no-gutters">
            <img
              src={loginBanner}
              style={{ width: "inherit", height: "90vh" }}
            />
            <br />
          </div>
          <div
            className="col-md-10 col-lg-4 login-right"
            style={{ paddingTop: "90px" }}
          >
            {twoFACheck ? (
              <>
                <div className="login-header">
                  <h3>
                    <FormattedMessage
                      id="loginScreen.enterPin"
                      defaultMessage="Enter Pin"
                    />
                  </h3>
                  <p className="small text-muted">
                    <FormattedMessage
                      id="loginScreen.pinMessage"
                      defaultMessage=" Enter pin you received in your email"
                    />
                  </p>
                </div>
                <form >
                  <div className="form-group form-focus focused">
                    <input
                      name="pin"
                      required
                      type="text"
                      className="form-control floating"
                      value={pin}
                      onChange={(e) => {
                        setPin(e.target.value);
                      }}
                    />
                    <label className="focus-label">
                      <FormattedMessage
                        id="loginScreen.pin"
                        defaultMessage="Pin"
                        
                      />
                    </label>
                  </div>
                  <div className="text-center">
                    <p style={{ color: "red" }}>{pinError}</p>
                  </div>

                  {isSendingPin ? (
                    <div className="text-center">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "00d0f1" }}
                      >
                        <span className="sr-only">
                          <FormattedMessage
                            id="loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                      </Spinner>
                    </div>
                  ) : showCountDown ? (
                    <>
                      <button
                        className="btn btn-primary btn-block btn-lg login-btn"
                        // type="submit"
                        onClick={handlePinSubmitEvent}
                      >
                        <FormattedMessage
                          id="loginScreen.submitButton1"
                          defaultMessage=" Submit"
                        />
                      </button>

                      <button
                        // className="btn btn-primary btn-block btn-lg login-btn"
                        className="btn btn-block btn-lg login-btn"
                        onClick={() => ResendCode("2FA")}
                      >
                        <FormattedMessage
                            id="loginScreen.resendButton1"
                            defaultMessage="Resend pin code to login"
                          />
                      </button>
                    </>
                  ) : (
                        <>
                          <button
                            className="btn btn-primary btn-block btn-lg login-btn"
                            // type="submit"
                            onClick={handlePinSubmitEvent}
                          >
                            <FormattedMessage
                              id="loginScreen.submitButton1"
                              defaultMessage=" Submit"
                            />
                          </button>

                          <button disabled
                            className="btn btn-block btn-lg login-btn"
                          >
                            <FormattedMessage
                            id="loginScreen.resendButton1"
                            defaultMessage="Resend 2FA Code"
                            />
                          </button>
                          <p >
                            <FormattedMessage
                              id="loginScreen.timeLeft"
                              defaultMessage="Time left:"
                            />
                            {time}{' '}
                            <FormattedMessage
                              id="loginScreen.seconds"
                              defaultMessage="seconds"
                            />
                          </p>
                        </>

                      )}
                </form>
              </>
            ) : (
                <>
                  <div className="login-header">
                    <h2 style={{ textAlign: "center" }}>
                      <FormattedMessage
                        id="loginScreen.signInLabel"
                        defaultMessage="Sign In"
                      />
                    </h2>
                  </div>
                  {/* onSubmit={handleSubmitEvent} */}
                  <form >
                    <div className="form-group form-focus focused">
                      <input
                        type="email"
                        className="form-control floating"
                        defaultValue={email}
                        onChange={(e) => {
                          setEmail(e.target.value.toLowerCase());
                        }}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="loginScreen.emailLabel"
                          defaultMessage="Email"
                        />
                      </label>
                    </div>
                    <div className="form-group form-focus focused">
                      <input
                        type="password"
                        className="form-control floating"
                        defaultValue={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="loginScreen.passwordLabel"
                          defaultMessage="Password"
                        />
                      </label>
                    </div>
                    <div className="text-center">
                      <p style={{ color: "red" }}>{msg}</p>
                    </div>
                    <div className="text-right">
                      <Link
                        to="/forgotPassword"
                        className="forgot-link"
                        style={{ color: "#000000" }}
                      >
                        <FormattedMessage
                          id="loginScreen.forgotPassword"
                          defaultMessage="Forgot Password ?"
                        />
                      </Link>
                    </div>

                    {isLoading ? (
                      <div className="text-center">
                        <Spinner
                          animation="border"
                          role="status"
                          style={{ color: "#00d0f1" }}
                        >
                          <span className="sr-only">
                            <FormattedMessage
                              id="loading"
                              defaultMessage="Loading..."
                            />
                          </span>
                        </Spinner>
                      </div>
                    ) : resetButton ? (
                      <>
                        <button
                          // className="btn btn-primary btn-block btn-lg login-btn"
                          className="btn btn-block btn-lg login-btn"
                          type="submit"
                          onClick={handleSubmitEvent}
                        >
                          <FormattedMessage
                            id="loginScreen.signinButton1"
                            defaultMessage=" Sign In
"
                          />{" "}
                        </button>
                        <button
                          // className="btn btn-primary btn-block btn-lg login-btn"
                          className="btn btn-block btn-lg login-btn"
                          // onClick={() => ResendCode("email_verification")}
                          disabled
                        >
                          <FormattedMessage
                            id="loginScreen.resendButton2"
                            defaultMessage="Resend verification email"
                          />
                        </button>
                        <p >
                          <FormattedMessage
                            id="loginScreen.timeLeft"
                            defaultMessage="Time left:"
                          />
                          {time}{' '}
                          <FormattedMessage
                            id="loginScreen.seconds"
                            defaultMessage="seconds"
                          />
                        </p>
                      </>
                    ) : isResend ? (
                      <>
                        <button
                          // className="btn btn-primary btn-block btn-lg login-btn"
                          className="btn btn-block btn-lg login-btn"
                          type="submit"
                          onClick={handleSubmitEvent}
                        >
                          <FormattedMessage
                            id="loginScreen.signinButton1"
                            defaultMessage=" Sign In"
                          />{" "}
                        </button>
                        <button
                          // className="btn btn-primary btn-block btn-lg login-btn"
                          className="btn btn-block btn-lg login-btn"
                          onClick={() => ResendCode("email_verification")}
                        // disabled
                        >
                          <FormattedMessage
                            id="loginScreen.resendButton2"
                            defaultMessage="Resend verification email"
                          />
                        </button>
                        {/* <p >Time left: {time}</p> */}
                      </>
                    ) : (
                            <button
                              // className="btn btn-primary btn-block btn-lg login-btn"
                              className="btn btn-block btn-lg login-btn"
                              type="submit"
                              onClick={handleSubmitEvent}
                            >
                              <FormattedMessage
                                id="loginScreen.signinButton1"
                                defaultMessage=" Sign In"
                              />{" "}
                            </button>
                          )}
                    {/* Incorrect Email or Password. */}
                    <div className="text-center dont-have">
                      <FormattedMessage
                        id="loginScreen.noAccount"
                        defaultMessage="   Don’t have an account?"
                      />{" "}
                      <Link to="/signup">
                        <FormattedMessage
                          id="loginScreen.registerLink"
                          defaultMessage="Register"
                        />
                      </Link>
                    </div>
                  </form>
                </>
              )}
          </div>
        </div>
      </div>
      {/* <Footer position={""} /> */}
    </div>
  );
}
export default windowSize(LoginScreen);
