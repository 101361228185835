import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
// import AccountApprovalDetails from "./AccountApprovalDetails";
import { FormattedMessage } from "react-intl";
import { Route, useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";

function TransferPayment(props) {
  let { path, url } = useRouteMatch();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  let [searchError, setSearchError] = useState("");
  let [sendToUser, setSendToUser] = useState("");
  let [isGettingSendToUserDetails, setIsGettingSendToUserDetails] = useState(
    false
  );
  let getSendToUserDetails = (e) => {
    setSendToUser("");
    setSearchError("");
    e.preventDefault();
    setIsGettingSendToUserDetails(true);
    axios
      .post(
        `/api/v1/mingacoin/transfer/usersearch`,
        { email: email },
        { withCredentials: true }
      )
      .then((response) => {
        setSendToUser(response.data);
        setIsGettingSendToUserDetails(false);
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 404) {
            setSearchError("User not found.");
          } else if (error.response.status === 500) {
            setSearchError("Internal Server Error.");
          }
        } else {
          setSearchError("Unknown error occurred, try again.");
        }
        setIsGettingSendToUserDetails(false);
        console.log(error);
      });
  };
  useEffect(() => {
    props.setActiveTab({
      transferPayment: "active",
    });
  }, []);
  let [email, setEmail] = useState();
  let [amount, setAmount] = useState();

  let [msg, setMsg] = useState();
  let [isTransfering, setIsTransfering] = useState(false);
  let [transferErrors, setTransferError] = useState("");
  let handleTransfer = (e) => {
    e.preventDefault();
    setTransferError("");
    setIsTransfering(true);
    if (
      amount === 0 ||
      amount === null ||
      amount === undefined ||
      amount === "" ||
      amount === "0"
    ) {
      console.log("It came here");
      setTransferError("Enter Valid Amount");
      setIsTransfering(false);
      return;
    }
    let data = {
      fromUserId: props.userData._id,
      toUserId: sendToUser._id,
      amount: amount,
    };
    axios
      .post(`/api/v1/mingacoin/transfer`, data, { withCredentials: true })
      .then((response) => {
        setSendToUser(response.data);
        setSendToUser("");
        setAmount("");
        setEmail("");
        setMsg("Amount Successfully Transfered");
        setIsTransfering(false);
        handleShowSuccessModal();
        console.log(response.data);
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.status === 400) {
            setTransferError("Invalid request body.");
          }
          if (error.response.status === 404) {
            setTransferError("User not found.");
          } else if (error.response.status === 500) {
            setTransferError("Internal Server Error.");
          } else if (error.response.status === 601) {
            setTransferError("Insufficient Balance Available.");
          } else {
            setTransferError("Unknown error occurred, try again.");
          }
        } else {
          setTransferError("Unknown error occurred, try again.");
        }
        setIsTransfering(false);
        console.log(error);
      });
  };

  return (
    <>
      <Route exact path={`${path}`}>
        <div className="card">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <FormattedMessage
                  id="user.transferPayment.dashboardBreadcrumb"
                  defaultMessage="Dashboard"
                />
              </a>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage
                id="user.transferPayment.transferPaymentBreadcrumb"
                defaultMessage="Transfer Payment"
              />
            </li>
          </ul>
          <div className="card-body">
            <h3>
              <FormattedMessage
                id="user.transferPayment.headingLabel"
                defaultMessage="Transfer Minga"
              />
            </h3>
            <br />
            {/* <div className="col-lg-12 col-md-6"> */}
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <FormattedMessage
                      id="user.transferPayment.fromAccount"
                      defaultMessage="From Account"
                    />
                  </label>
                </div>

                <div className="col-md-4">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    defaultValue={
                      props.userData.firstName + " " + props.userData.lastName
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-4">
                  <label>
                    <FormattedMessage
                      id="user.transferPayment.balance"
                      defaultMessage="Balance"
                    />
                  </label>
                </div>

                <div className="col-md-4">
                  <input
                    disabled
                    type="text"
                    className="form-control"
                    defaultValue={props.userData.mingaCoins}
                    style={{ textAlign: "right" }}
                  />
                </div>
              </div>
            </div>
            <form onSubmit={getSendToUserDetails}>
              <div className="form-group ">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      <FormattedMessage
                        id="user.transferPayment.toUser"
                        defaultMessage="To User (Quick Search)"
                      />
                    </label>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-focus focused">
                      <input
                        type="email"
                        className="form-control"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                      // defaultValue={props.userData.name}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="user.transferPayment.searchUser"
                          defaultMessage="Search user using email"
                        />
                      </label>
                    </div>
                    <div className="row no-gutters">
                      <p style={{ color: "red", paddingLeft: "3%" }}>
                        {searchError}
                      </p>
                    </div>
                    {sendToUser !== "" ? (
                      <>
                        <div className="form-group ">
                          <label className="form-label">
                            <FormattedMessage
                              id="user.transferPayment.name"
                              defaultMessage="Name"
                            />
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={
                              sendToUser.firstName + " " + sendToUser.lastName
                            }
                            disabled
                          />
                        </div>
                        <div className="form-group ">
                          <label className="form-label">
                            <FormattedMessage
                              id="user.transferPayment.mobile"
                              defaultMessage="Mobile"
                            />
                          </label>
                          <input
                            type="tel"
                            className="form-control"
                            disabled
                            defaultValue={sendToUser.mobile}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="col-sm-2" style={{ paddingTop: "4px" }}>
                    <label> </label>
                    {/* <button className="btn btn-primary"> */}
                    {isGettingSendToUserDetails ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "#00d0f1" }}
                      >
                        <span className="sr-only">
                          <FormattedMessage
                            id="user.transferPayment.loading"
                            defaultMessage="Loading..."
                          />
                        </span>
                      </Spinner>
                    ) : (
                        <>
                          <button className="btn" type="submit">
                            {" "}
                            {/* <i class="fa fa-search" aria-hidden="true"></i> */}
                            <FormattedMessage
                              id="user.getDetails.button"
                              defaultMessage="Get Details"
                            />
                          </button>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </form>

            <form onSubmit={handleTransfer}>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <label>
                      <FormattedMessage
                        id="user.transferPayment.amount"
                        defaultMessage="Amount"
                      />
                    </label>
                  </div>

                  <div className="col-md-4">
                    <div className="form-group form-focus focused">
                      <input
                        min="1"
                        type="number"
                        className="form-control"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                      <label className="focus-label">
                        <FormattedMessage
                          id="user.searchServices.amountPlaceholder"
                          defaultMessage="Enter amount"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              {sendToUser !== "" ? (
                <div className="text-center">
                  {isTransfering ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: "#00d0f1" }}
                    >
                      <span className="sr-only">
                        <FormattedMessage
                          id="user.transferPayment.loading"
                          defaultMessage="Loading..."
                        />
                      </span>
                    </Spinner>
                  ) : (
                      <>
                        <p
                          style={{
                            color: "red",

                            marginBottom: "3%",
                          }}
                        >
                          {transferErrors}
                        </p>

                        <button
                          type="submit"
                          // className="btn btn-primary"
                          className="btn"
                          style={{ width: "fit-content" }}
                        >
                          <FormattedMessage
                            id="user.transferPayment.submitButton"
                            defaultMessage=" Submit"
                          />
                        </button>
                      </>
                    )}
                </div>
              ) : null}
            </form>
          </div>
        </div>
        {/* </div> */}
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title>{msg}</Modal.Title>
          </Modal.Header>
        </Modal>
      </Route>
      {/* <Route path={`${path}/:companyId`}>
                <AccountApprovalDetails />
            </Route> */}
    </>
  );
}

export default TransferPayment;
