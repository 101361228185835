import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, Route, useRouteMatch } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";
import TicketDetailScreen from "../TicketDetailScreen";


function RequestAccountApproval(props) {
  let { path, url } = useRouteMatch();

  useEffect(() => {
    props.setActiveTab({
      requestAccountApproval: "active",
    });
    getTicket();
  }, []);

  let [ticket, setTicket] = useState("");
  let getTicket = () => {
    axios
      .get(`/api/v1/ticket/accountapproval/${props.userData._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        setTicket(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Route exact path={`${path}`}>
        {props.userData !== "" ? (
          <div className="card">
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <FormattedMessage
                    id="communityLeader.requestAccount.dashboardBreadcrumb"
                    defaultMessage="Dashboard"
                  />
                </a>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage
                  id="communityLeader.requestAccount.requestAccountBreadcrumb"
                  defaultMessage="Request Account Approval"
                />
              </li>
            </ul>
            <div className="card-body">
              {props.userData.accountStatus === "Pending" ? (
                <div
                  className="alert alert-warning alert-dismissible fade show"
                  role="alert"
                >
                  <strong></strong>  <FormattedMessage
                    id="communityLeader.requestAccount.approvalMessage"
                    defaultMessage="Account Approval is Pending"
                  />
                </div>
              ) : (
                  <></>
                )}

              {props.userData.accountStatus === "Rejected" ? (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  <strong><FormattedMessage
                    id="communityLeader.requestAccount.warningMessage"
                    defaultMessage="Warning!"
                  />
                  </strong>
                  <FormattedMessage
                    id="communityLeader.requestAccount.rejectMessage"
                    defaultMessage="Your account has been rejected by
                  the admin."
                  />
                </div>
              ) : (
                  <></>
                )}
            </div>

            <div className="card-body">
              <div className="table-responsive" style={{ paddingTop: "20px" }}>
                <caption><FormattedMessage
                  id="communityLeader.requestAccount.ticketIssueMessage"
                  defaultMessage="Ticket Issued"
                /></caption>
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th><FormattedMessage
                        id="communityLeader.mymingas.table.number"
                        defaultMessage="#" /></th>
                      <th><FormattedMessage
                        id="communityLeader.requestAccount.table.subjectTitle"
                        defaultMessage="Subject"
                      /></th>

                      <th className="text-right">
                        <FormattedMessage
                          id="admin.pendingRequests.actions"
                          defaultMessage="Actions"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ticket !== "" ? (
                      <tr>
                        <td>{1}</td>
                        <td>{ticket.subject}</td>

                        <td className="text-right">
                          <Link
                            to={`${url}/ticketdetail/${props.userData._id}`}
                            className="btn btn-sm bg-light"
                          >
                            <i className="fe fe-trash"></i>
                            <FormattedMessage
                              id="communityLeader.requestAccount.table.ticketDetail"
                              defaultMessage="Ticket Detail"
                            />
                          </Link>
                        </td>
                      </tr>
                    ) : (
                        <p><FormattedMessage
                          id="communityLeader.requestAccount.table.noTicketIssue"
                          defaultMessage="No ticket issued."
                        /></p>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </Route>
      <Route path={`${path}/ticketdetail/:communityleaderId`}>
        <TicketDetailScreen />
      </Route>
    </>
  );
}

export default RequestAccountApproval;
