import {createStore,applyMiddleware} from 'redux';
import {UserReducer,initialState} from './Reducers/UserReducer';
import thunk from "redux-thunk";
import createSagaMiddleWare from "redux-saga"
import {watchUserLogout} from './sagas/UserSaga'


// import {composeWithDevTools} from 'redux-devtools-extentions'

// const sagaMiddleWare=createSagaMiddleWare();


export const ConfigureStore =()=>{
    const store=createStore(
        UserReducer,initialState,applyMiddleware(thunk)
    );
    // sagaMiddleWare.run(watchUserLogout)
    return store;
}