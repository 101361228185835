import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link, Route, useRouteMatch } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import TicketDetailScreen from "./TicketDetailScreen";


function PendingRequests(props) {
  // let { companyId } = useParams();
  let { path, url } = useRouteMatch();
  let [showIssueTicketModal, setShowIssueTicketModal] = useState(false);
  const handleCloseIssueTicketModal = () => setShowIssueTicketModal(false);
  const handleShowIssueTicketModal = () => setShowIssueTicketModal(true);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const handleCloseSuccessModal = () => setShowSuccessModal(false);
  const handleShowSuccessModal = () => setShowSuccessModal(true);

  let [isError, setIsError] = useState(false);
  let [errorMsg, setErrorMsg] = useState();

  let [data, setData] = useState([]);
  let [filesTypes, setFilesTypes] = useState([]);
  let getPendingRequests = () => {
    axios
      .get(`/api/v1/communityleaders/pendingrequests`, {
        withCredentials: true,
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [ticketSubject, setTicketSubject] = useState();
  let [adminComment, setAdminComment] = useState();
  let [isIssuingTicket, setIsIssuingTicket] = useState();
  let [selectedCommunityLeader, setSelectedCommunityLeader] = useState();
  let issueTicket = () => {
    setIsIssuingTicket(true);
    let data = {
      subject: ticketSubject,
      msg: adminComment,
      ticketType: "AccountApproval",
    };

    axios
      .post(`/api/v1/ticket/issueticket/${selectedCommunityLeader}/`, data, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        getPendingRequests();
        setIsIssuingTicket(false);
        handleCloseIssueTicketModal();
        handleShowSuccessModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    props.setActiveTab({
      pendingRequests: "active",
    });
    getPendingRequests();
  }, []);

  let [isSaving, setIsSaving] = useState(false);
  let [isSaving1, setIsSaving1] = useState(false);
  let accountDecision = (decision, id) => {
    if (decision === "Approved") setIsSaving(true);
    else setIsSaving1(true);
    axios
      .post(
        `/api/v1/communityleaders/accountdecision/${id}`,
        {
          decision: decision,
        },
        { withCredentials: true }
      )
      .then((response) => {
        if (decision === "Approved") setIsSaving(false);
        else setIsSaving1(false);
        getPendingRequests();
      })
      .catch((error) => {
        if (decision === "Approved") setIsSaving(false);
        else setIsSaving1(false);
        console.log(error);
      });
  };

  return (
    <>
      <Route exact path={`${path}`}>
        <div className="card">
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">
                <FormattedMessage
                  id="admin.pendingRequests.dashboardBreadcrumb"
                  defaultMessage="Dashboard"
                />
              </a>
            </li>
            <li className="breadcrumb-item active">
              <FormattedMessage
                id="admin.pendingRequests.pendingRequestsBreadcrumb"
                defaultMessage="Pending Requests"
              />
            </li>
          </ul>
          <div className="card-body">
            <div className="table-responsive" style={{ paddingTop: "20px" }}>
              <table className="table table-hover table-center mb-0">
                <thead>
                  <tr>
                    <th><FormattedMessage
                      id="communityLeader.mymingas.table.number"
                      defaultMessage="#" /></th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.name"
                        defaultMessage="Name"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.email"
                        defaultMessage="Email"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.phone"
                        defaultMessage="Phone"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="admin.pendingRequests.description"
                        defaultMessage="Description"
                      />
                    </th>

                    <th className="text-right">
                      <FormattedMessage
                        id="admin.pendingRequests.actions"
                        defaultMessage="Actions"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((i, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {i.firstName} {i.lastName}
                      </td>
                      <td>{i.email}</td>
                      <td>{i.mobile}</td>
                      <td>{i.description}</td>
                      <td className="text-right">
                        {i.ticketStatus === "Issued" ? (
                          <Link
                            to={`${url}/ticketdetail/${i._id}`}
                            className="btn btn-sm bg-light"
                          >
                            <i className="fe fe-trash"></i>
                            <FormattedMessage
                              id="communityLeader.ticketDetails.ticketDetails"
                              defaultMessage="Ticket Details" />
                          </Link>
                        ) : (
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedCommunityLeader(i._id);
                                handleShowIssueTicketModal();
                              }}
                              className="btn btn-sm bg-light"
                            >
                              <i className="fe fe-trash"></i>
                              <FormattedMessage
                                id="admin.pendingRequest.issueTicket"
                                defaultMessage="Issue Ticket" />
                            </a>
                          )}
                        {isSaving ? (
                          <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            style={{
                              color: "#a1c57e",
                              marginRight: "10px",
                            }}
                          >
                            <span className="sr-only">
                              <FormattedMessage
                                id="loading"
                                defaultMessage="Loading..."
                              />
                            </span>
                          </Spinner>
                        ) : (
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                accountDecision("Approved", i._id);
                              }}
                              className="btn btn-sm bg-success-light"
                            >
                              <i className="fe fe-trash"></i><FormattedMessage
                                id="mingaCoinRequest.approveButton"
                                defaultMessage="Approve" />
                            </a>
                          )}
                        {isSaving1 ? (
                          <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            style={{
                              color: "#e75157",
                              marginRight: "10px",
                              marginLeft: "10px",
                            }}
                          >
                            <span className="sr-only">
                              <FormattedMessage
                                id="loading"
                                defaultMessage="Loading..."
                              />
                            </span>
                          </Spinner>
                        ) : (
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                accountDecision("Rejected", i._id);
                              }}
                              className="btn btn-sm bg-danger-light"
                            >
                              <i className="fe fe-trash"></i>
                              <FormattedMessage
                                id="mimgaCoinRequest.rejectButton"
                                defaultMessage="Reject" />
                            </a>
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <Modal show={showIssueTicketModal} onHide={handleCloseIssueTicketModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage
                id="admin.pendingRequest.issueTicket"
                defaultMessage="Issue Ticket" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <label><FormattedMessage
                id="communityLeader.requestAccount.table.subjectTitle"
                defaultMessage="Subject"
              /></label>
              <input
                type="text"
                required
                className="form-control"
                onChange={(e) => {
                  setTicketSubject(e.target.value);
                }}
              />

              <div className="form-group">
                <label><FormattedMessage
                  id="admin.pendingRequest.table.details"
                  defaultMessage="Details"
                /></label>
                <textarea
                  onChange={(e) => setAdminComment(e.target.value)}
                  className="form-control"
                  rows="12"
                />
              </div>

              {isIssuingTicket ? (
                <Spinner
                  animation="border"
                  role="status"
                  style={{
                    color: "#00d0f1",
                    marginRight: "10px",
                  }}
                >
                  <span className="sr-only">
                    <FormattedMessage
                      id="loading"
                      defaultMessage="Loading..."
                    />
                  </span>
                </Spinner>
              ) : (
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    float="right"
                    style={{ width: "fit-content" }}
                    onClick={(e) => {
                      e.preventDefault();
                      issueTicket();
                    }}
                  >
                    <FormattedMessage
                      id="admin.pendingRequest.modal.issue"
                      defaultMessage="Issue"
                    />
                  </button>
                )}
            </form>
          </Modal.Body>
        </Modal>

        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
          <Modal.Header closeButton>
            <Modal.Title><FormattedMessage
              id="admin.pendingRequest.modal.issueTicketTitle"
              defaultMessage="Successfully Issued Ticket"
            /></Modal.Title>
          </Modal.Header>
        </Modal>
      </Route>
      <Route exact path={`${path}/ticketdetail/:communityleaderId`}>
        <TicketDetailScreen />
      </Route>
    </>
  );
}

export default PendingRequests;
