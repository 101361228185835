import axios from "axios";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
// import "../../assets/css/adminStyle.css";
import "../../assets/css/adminStyle.css";
// import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";


function CommunityLeaderDashboardDefaultScreen(props) {
  let [projectCounts, setProjectCounts] = useState(0);


  let getCounts = () => {
    axios
      .get(`/api/v1/communityleaders/dashboardcounts/${props.userData._id}`, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setProjectCounts(response.data.projectCounts);
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
      });
  };

  useEffect(() => {
    if (props.userData !== "") {
      getCounts();
    }
  }, [props.userData]);

  useEffect(() => {
    props.setActiveTab({
      dashboard: "active",
    });
    // getCounts();
  }, []);

  return (
    <>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="page-title">
              <FormattedMessage
                id="communityLeader.defaultDashboard.heading"
                defaultMessage="Welcome Community Leader!"
              />
            </h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item active">
                <FormattedMessage
                  id="communityLeader.defaultDashboard.dashboardBreadcrumb"
                  defaultMessage="Dashboard" /></li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- /Page Header --> */}

      <div className="row">
        <div className="col-12 col-sm-3">
          {/* <Link to={`${props.match.url}/approvedcompanies`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon text-success">
                                        <i className="fa fa-building"></i>
                                    </span>
                                    <div className="dash-count">
                                        <h3>{approvedCompaniesCounts}</h3>
                                    </div>
                                </div>
                                <div className="dash-widget-info">
                                    <h6 className="text-muted">Total Approved Companies</h6>
                                    <div className="progress progress-sm">
                                        <div className="progress-bar bg-success w-100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link> */}
        </div>
        <div className="col-12 col-sm-3">
          {/* <Link to={`${props.match.url}/companyrequests`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon text-danger border-danger">
                                        <i className="fa fa-hourglass"></i>
                                    </span>
                                    <div className="dash-count">
                                        <h3>{pendingCompaniesCounts}</h3>
                                    </div>
                                </div>
                                <div className="dash-widget-info">
                                    <h6 className="text-muted">Total Pending Companies</h6>
                                    <div className="progress progress-sm">
                                        <div className="progress-bar bg-danger w-100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link> */}
        </div>
        <div className="col-12 col-sm-3">
          {/* <Link to={`${props.match.url}/rejectedcompanies`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon text-warning border-warning">
                                        <i className="fa fa-times-circle"></i>
                                    </span>
                                    <div className="dash-count">
                                        <h3>{rejectedCompaniesCounts}</h3>
                                    </div>
                                </div>
                                <div className="dash-widget-info">
                                    <h6 className="text-muted">Total Rejected Companies</h6>
                                    <div className="progress progress-sm">
                                        <div className="progress-bar bg-warning w-100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link> */}
        </div>
        <div className="col-12 col-sm-3">
          {/* <Link to={`${props.match.url}/reviewmoderations`}>
                        <div className="card">
                            <div className="card-body">
                                <div className="dash-widget-header">
                                    <span className="dash-widget-icon text-secondary border-secondary">
                                        <i className="fa fa-hourglass"></i>
                                    </span>
                                    <div className="dash-count">
                                        <h3>{pendingReviewCounts}</h3>
                                    </div>
                                </div>
                                <div className="dash-widget-info">
                                    <h6 className="text-muted">Total Pending Reviews</h6>
                                    <div className="progress progress-sm">
                                        <div className="progress-bar bg-secondary w-100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link> */}
        </div>
        <div className="col-12 col-sm-3">
          <div className="card">
            <div className="card-body">
              <div className="dash-widget-header">
                <span className="dash-widget-icon text-primary border-primary">
                  <i className="fa fa-user"></i>
                </span>
                <div className="dash-count">
                  <h3>{projectCounts}</h3>
                </div>
              </div>
              <div className="dash-widget-info">
                <h6 className="text-muted">
                  <FormattedMessage
                    id="communityLeader.defaultDashboard.totalProjects"
                    defaultMessage="Total Projects"
                  />{" "}
                </h6>
                <div className="progress progress-sm">
                  <div className="progress-bar bg-primary w-100"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CommunityLeaderDashboardDefaultScreen;
