import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";


function NoMatch() {
  return (
    <div className="error-page" style={{ height: "inherit", display: "grid" }}>
      <div className="main-wrapper" style={{ margin: "auto" }}>
        <div className="text-center">
          <div>
            <div className="error-box">
              <h1><FormattedMessage
                id="noMatch.heading1"
                defaultMessage="404" /></h1>
              <h3 className="h2 mb-3">
                <i className="fa fa-warning"></i>
                <FormattedMessage
                  id="noMatch.heading2"
                  defaultMessage="Oops! Page not found!" />
              </h3>
              <p className="h4 font-weight-normal">
                <FormattedMessage id="noMatch.pageMessage"
                  defaultMessage="The page you requested was not found." />

              </p>
              <Link to="/" className="btn btn-primary">
                <FormattedMessage id="noMatch.backToHomeButton1"
                  defaultMessage="Back to Home" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NoMatch;
