import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";

function ErrorScreen() {
  return (
    <div className="error-page" style={{ height: "inherit", display: "grid" }}>
      <div className="main-wrapper" style={{ margin: "auto" }}>
        <div className="text-center">
          <div>
            <div className="error-box">
              <h1><FormattedMessage
                id="errorScreen.heading1"
                defaultMessage="500" /></h1>
              <h3 class="h2 mb-3">
                <i class="fa fa-warning"></i>
                <FormattedMessage
                  id="errorScreen.heading2"
                  defaultMessage="Oops! Something went wrong" />
              </h3>
              <p class="h4 font-weight-normal">
                <FormattedMessage
                  id="errorScreen.pageMessage"
                  defaultMessage="The page you requested was not found." />

              </p>
              <Link to="/" className="btn btn-primary">
                <FormattedMessage
                  id="errorScreen.backToHomeButton1"
                  defaultMessage="Back to Home" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorScreen;
