
import React from 'react';
import { Button, Col, Form, FormControl, InputGroup } from 'react-bootstrap';

// import './Input.css';

const Input = ({ setMessage, sendMessage, message }) => (

    <Form>
        <Form.Row>
            <Col className="my-1">
                <Form.Label htmlFor="inlineFormInputGroupUsername" srOnly>
                    Type Here
            </Form.Label>
                <InputGroup>
                    <FormControl id="inlineFormInputGroupUsername"
                        className="agentinput"
                        type="text"
                        placeholder="Type a message..."
                        value={message}
                        onChange={({ target: { value } }) => setMessage(value)}
                        onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
                    />
                    <InputGroup.Prepend>
                        <Button onClick={e => sendMessage(e)}>Send</Button>
                        {/* <InputGroup.Text>@</InputGroup.Text> */}
                    </InputGroup.Prepend>
                </InputGroup>
            </Col>
        </Form.Row>
    </Form>

)

export default Input;