import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import "../../assets/css/adminStyle.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";


function AdvertisementCategoriesComponent(props) {
  let [isSaving, setIsSaving] = useState(false);

  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const handleCloseAddCategoryModal = () => {
    setEnglishName("");
    setShowAddCategoryModal(false);
  };
  const handleShowAddCategoryModal = (e) => {
    e.preventDefault();
    setShowAddCategoryModal(true);
  };

  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const handleCloseEditCategoryModal = () => setShowEditCategoryModal(false);
  const handleShowEditCategoryModal = (index) => {
    setEnglishName(data[index].englishName);
    setSpanishName(data[index].spanishName);
    setShowEditCategoryModal(true);
  };

  const [showDeleteCategoryModal, setShowDeleteCategoryModal] = useState(false);
  const handleCloseDeleteCategoryModal = () =>
    setShowDeleteCategoryModal(false);
  const handleShowDeleteCategoryModal = () => setShowDeleteCategoryModal(true);

  let [englishName, setEnglishName] = useState();
  let [spanishName, setSpanishName] = useState();
  let handleAddCategoryButton = (e) => {
    e.preventDefault();
    setIsSaving(true);

    axios
      .post(
        `/api/v1/advertisementcategories/`,
        {
          englishName: englishName,
          spanishName: spanishName,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        setIsSaving(false);
        handleCloseAddCategoryModal();
        getCategories();
      })
      .catch((error) => {
        setIsSaving(false);
        console.log(error);
      });
  };

  let handleDeleteCategoryButton = () => {
    axios
      .delete(`/api/v1/advertisementcategories/${selectedIndex}`, {
        withCredentials: true,
      })
      .then((response) => {
        handleCloseDeleteCategoryModal();
        getCategories();
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [data, setData] = useState([]);
  let getCategories = () => {
    axios
      .get("/api/v1/advertisementcategories", { withCredentials: true })
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  let [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    props.setActiveTab({
      advertisementcategories: "active",
    });
    getCategories();
  }, []);

  let handleEditCategoryButton = (e) => {
    e.preventDefault();
    setIsSaving(true);
    axios
      .put(
        `/api/v1/advertisementcategories/${selectedIndex}`,
        {
          englishName: englishName,
          spanishName: spanishName,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setIsSaving(false);
        handleCloseEditCategoryModal();
        getCategories();
        console.log(response.data);
      })
      .catch((error) => {
        setIsSaving(false);
        console.log(error);
      });
  };

  return (
    <>
      {/* <!-- Page Header --> */}
      <div className="page-header">
        <div className="row">
          <div className="col-sm-7 col-auto">
            <h3 className="page-title">
              <FormattedMessage
                id="admin.advertisementcategories.title"
                defaultMessage="Advertisement Categories"
              />
            </h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <FormattedMessage
                    id="admin.categories.dashboardBreadcrumb"
                    defaultMessage="Dashboard"
                  />
                </a>
              </li>
              <li className="breadcrumb-item active">
                <FormattedMessage
                  id="admin.advertisementcategories.categoriesBreadcrumb"
                  defaultMessage="Advertisement Categories"
                />
              </li>
            </ul>
          </div>
          <div className="col-sm-5 col">
            <a
              href="home.html"
              data-toggle="modal"
              // className="btn btn-primary float-right mt-2"
              className="btn float-right mt-2"
              onClick={handleShowAddCategoryModal}
              style={{ color: "white" }}
            >
              <FormattedMessage
                id="mingaCategories.addButton"
                defaultMessage="Add" />
            </a>
          </div>
        </div>
      </div>
      {/* <!-- /Page Header --> */}
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="table-responsive">
                <table className="datatable table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th><FormattedMessage
                        id="communityLeader.mymingas.table.number"
                        defaultMessage="#" /></th>
                      <th>
                        <FormattedMessage
                          id="admin.categories.englishName"
                          defaultMessage="englishName"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="admin.categories.spanishName"
                          defaultMessage="englishName"
                        />
                      </th>
                      <th className="text-right">
                        <FormattedMessage
                          id="admin.categories.actions"
                          defaultMessage="Actions"
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((i, index) => (
                      <tr key={i._id}>
                        <td>{index + 1}</td>

                        <td>{i.englishName}</td>
                        <td>{i.spanishName}</td>

                        <td className="text-right">
                          <div className="actions">
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedIndex(i._id);
                                handleShowEditCategoryModal(index);
                              }}
                              className="btn btn-sm bg-success-light"
                            >
                              <i className="fe fe-trash"></i>
                              <FormattedMessage
                                id="admin.categories.edit"
                                defaultMessage="Edit"
                              />
                            </a>
                            <a
                              href="home.html"
                              onClick={(e) => {
                                e.preventDefault();
                                setSelectedIndex(i._id);
                                handleShowDeleteCategoryModal();
                              }}
                              className="btn btn-sm bg-danger-light"
                            >
                              <i className="fe fe-trash"></i>
                              <FormattedMessage
                                id="admin.categories.delete"
                                defaultMessage="Delete"
                              />
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={showAddCategoryModal} onHide={handleCloseAddCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="admin.categories.modalAddTestimonial"
              defaultMessage="Add Testimonial"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row form-row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.categories.modalEnglishName"
                      defaultMessage="English Name"
                    />
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setEnglishName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.categories.modalSpanishName"
                      defaultMessage="Spanish Name"
                    />
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setSpanishName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            {isSaving ? (
              <div className="text-center">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "#00d0f1" }}
                >
                  <span className="sr-only">
                    <FormattedMessage
                      id="loading"
                      defaultMessage="Loading..."
                    />
                  </span>
                </Spinner>
              </div>
            ) : (
                <button
                  type="submit"
                  onClick={handleAddCategoryButton}
                  // className="btn btn-primary btn-block"
                  className="btn btn-block"
                >
                  <FormattedMessage
                    id="admin.categories.modalSaveChangesButton1"
                    defaultMessage="Save Changes"
                  />
                </button>
              )}
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showEditCategoryModal} onHide={handleCloseEditCategoryModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="admin.categories.modal.editCategories"
              defaultMessage="Edit Categories"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row form-row">
              <div className="col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.categories.modal.editCategoriesEnglishName"
                      defaultMessage="English Name"
                    />
                  </label>
                  <input
                    value={englishName}
                    type="text"
                    onChange={(e) => {
                      setEnglishName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="form-group">
                  <label>
                    <FormattedMessage
                      id="admin.categories.modal.editCategoriesSpanishName"
                      defaultMessage="Spanish Name"
                    />
                  </label>
                  <input
                    value={spanishName}
                    type="text"
                    onChange={(e) => {
                      setSpanishName(e.target.value);
                    }}
                    className="form-control"
                  />
                </div>
              </div>
            </div>
            {isSaving ? (
              <div className="text-center">
                <Spinner
                  animation="border"
                  role="status"
                  style={{ color: "#00d0f1" }}
                >
                  <span className="sr-only">
                    <FormattedMessage
                      id="loading"
                      defaultMessage="Loading..."
                    />
                  </span>
                </Spinner>
              </div>
            ) : (
                <button
                  type="submit"
                  onClick={handleEditCategoryButton}
                  // className="btn btn-primary btn-block"
                  className="btn btn-block"
                >
                  <FormattedMessage
                    id="admin.categories.modal.editCategoriesSaveButton"
                    defaultMessage="Save Changes"
                  />
                </button>
              )}
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteCategoryModal}
        onHide={handleCloseDeleteCategoryModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id="admin.categories.modalDelete"
              defaultMessage="Delete"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4">
            <FormattedMessage
              id="admin.categories.modalDelete.confirmMessage"
              defaultMessage="Are you sure want to delete?"
            />
          </p>
          <button
            type="button"
            onClick={handleDeleteCategoryButton}
            // className="btn btn-primary"
            className="btn"
          >
            <FormattedMessage
              id="admin.categories.modalDelete.saveButton"
              defaultMessage="Save"
            />{" "}
          </button>
          <button
            type="button"
            onClick={handleCloseDeleteCategoryModal}
            className="btn btn-danger"
            data-dismiss="modal"
          >
            <FormattedMessage
              id="admin.categories.modalDelete.closeButton"
              defaultMessage="Close"
            />
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdvertisementCategoriesComponent;
