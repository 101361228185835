import axios from "axios";
import React, { useEffect, useState } from "react";
// import AccountApprovalDetails from "./AccountApprovalDetails";
import { FormattedMessage } from "react-intl";
import { Route, useRouteMatch } from "react-router-dom";
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/plugins/fontawesome/css/all.min.css";
import "../../../assets/plugins/fontawesome/css/fontawesome.min.css";


function UserProjects(props) {
    let { path } = useRouteMatch();
    let [data, setData] = useState([]);
    let handleUserProjectInformation = () => {
        axios
          .get(`/api/v1/users/enrolledProjects/1`,{ withCredentials: true })
          .then((response) => {
              console.log("RES",response.data);
              if(response.data.seccess==true)
              {
                setData(response.data.data);
              }    
          })
          .catch((error) => {
            console.log(error);
          });
      };
    useEffect(() => {
        props.setActiveTab({
            userProjects: "active",
        });
        handleUserProjectInformation();
    }, []);
    // console.log("HELL",data);
    return (
        <>
            <Route exact path={`${path}`}>    
                <div className="card">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="/dashboard">
                                <FormattedMessage
                                    id="admin.totalCommunityLeaders.dashboardBreadcrumb"
                                    defaultMessage="Dashboard"
                                />
                            </a>
                        </li>
                        <li className="breadcrumb-item active">
                            <FormattedMessage
                                id="user.defaultDashboard.userProject"
                                defaultMessage="User Projects"
                            />
                        </li>
                    </ul>
                    <div className="card-body">
                        <div className="table-responsive" style={{ paddingTop: "20px" }}>
                            <table className="table table-hover table-center mb-0">
                                <thead>
                                    <tr>
                                        <th><FormattedMessage
                                            id="communityLeader.mymingas.table.number"
                                            defaultMessage="#" /></th>
                                        <th>
                                            <FormattedMessage
                                                id="user.userProjects.leaderName"
                                                defaultMessage="Leader Name"
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="user.userProjects.leaderEmail"
                                                defaultMessage="Leader Email"
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="user.userProjects.projectTitle"
                                                defaultMessage="Project Title"
                                            />
                                        </th>
                                        <th>
                                            <FormattedMessage
                                                id="user.userProjects.projectDescription"
                                                defaultMessage="Project Description"
                                            />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((i, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td> {i.communityleader.firstName} {i.communityleader.lastName}</td>
                                            <td>{i.communityleader.email}</td>
                                            <td>{i.projectName}</td>
                                            <td>{i.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Route>
        </>
    );
}
export default UserProjects;
