import React from "react";
import { Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import "../../assets/css/adminStyle.css";
import "../../assets/css/adminStyle.css";
// import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/style.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import { logoutUser } from "../../redux/ActionCreators/UserActionCreators";

function UserSidebar(props) {
  console.log("PROPS", props);
  let history = useHistory();
  let handleLogout = (e) => {
    // Cookies.remove("Authorization");
    // setTimeout(() => {}, 1);
    e.preventDefault();
    props.logoutUser(props, history);
    // axios
    //   .get("/api/v1/auth/user/logout", { withCredentials: true })
    //   .then((response) => {
    //     console.log(response);
    //     props.checkLoginStatus();
    //     history.push("/");
    //     // window.location.reload();
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  return (
    <div
      className="sidebar"
      id="sidebar"
      style={{ backgroundColor: "#07631F" }}
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>
                <FormattedMessage
                  id="user.sidebar.main"
                  defaultMessage="Main"
                />
              </span>
            </li>
            <li className={props.activeTab.dashboard}>
              <Link to={`${props.match.url}`}>
                <i className="fa fa-home"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.dashboard"
                    defaultMessage="Dashboard"
                  />
                </span>
              </Link>
            </li>

            <li className={props.activeTab.placead}>
              <Link to={`${props.match.url}/placead`}>
                <i className="far fa-credit-card"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.placead"
                    defaultMessage="Place Ad"
                  />
                </span>
              </Link>
            </li>
            {/* <li className={props.activeTab.searchServices}>
              <Link to={`${props.match.url}/searchservices`}>
                <i className="fa fa-hourglass"></i>{" "}
                <span>
                  <FormattedMessage
                    id="user.sidebar.searchServices"
                    defaultMessage="Search Services"
                  />
                </span>
              </Link>
            </li> */}
            <li className={props.activeTab.transferPayment}>
              <Link to={`${props.match.url}/transferpayment`}>
                <i className="fa fa-building"></i>{" "}
                <span>
                  <FormattedMessage
                    id="user.sidebar.transferPayment"
                    defaultMessage="Transfer Payment"
                  />
                </span>
              </Link>
            </li>

            <li>
              <Link to="/marketPlace">
                <i className="fa fa-archive" aria-hidden="true"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.marketPlace"
                    defaultMessage="Market Place"
                  />
                </span>
              </Link>
            </li>

            <li className={props.activeTab.userProjects}>
              <Link to="/dashboard/invitation">
                <i className="fa fa-tasks" aria-hidden="true"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.userProjects"
                    defaultMessage="User Projects"
                  />
                </span>
              </Link>
            </li>
            <li className={props.activeTab.myads}>
              <Link to="/dashboard/myads">
              <i className="fas fa-ad"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.myAds"
                    defaultMessage="My Ads"
                  />
                </span>
              </Link>
            </li>
            {/* <li className={props.activeTab.poolSignature}>
                            <Link to={`${props.match.url}/poolsignature`}>
                                <i className="fas fa-file-signature"></i>
                                {" "}
                                <span>Pool Signature</span>
                            </Link>
                        </li> */}
            <li className="menu-title">
              <span>
                <FormattedMessage
                  id="user.sidebar.settings"
                  defaultMessage="Settings"
                />
              </span>
            </li>

            {/* <li className={props.activeTab.contractDetails}>
                            <Link to={`${props.match.url}/smartcontractdetails`}>
                                <i className="fa fa-file-contract"></i>{" "}
                                <span>Smart Contract Details</span>
                            </Link>
                        </li> */}
            <li className={props.activeTab.settings}>
              <Link to={`${props.match.url}/profilesettings`}>
                <i className="fa fa-cog"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.profileSettings"
                    defaultMessage="Profile Settings"
                  />
                </span>
              </Link>
            </li>
            <li className={props.activeTab.changePassword}>
              <Link to={`${props.match.url}/changepassword`}>
                <i className="fa fa-key"></i>
                <span>
                  <FormattedMessage
                    id="user.sidebar.changePassword"
                    defaultMessage="Change Password"
                  />
                </span>
              </Link>
            </li>
            <li>
              <Link to={"/"} onClick={handleLogout}>
                <i className="fa fa-sign-out-alt"></i>
                {props.userLogoutLoading ? (
                  <span>
                    <FormattedMessage
                      id="user.sidebar.logout"
                      defaultMessage="Logout"
                    />
                    <Spinner animation="border" />
                  </span>
                ) : (
                    <span>
                      <FormattedMessage
                        id="user.sidebar.logout"
                        defaultMessage="Logout"
                      />
                    </span>)}

              </Link>
            </li>
            {/* <li className="hidden-container"> */}
            {/* <li>
              <Link to="/marketPlace">Market Place</Link>
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  console.log("STATE", state);
  return {
    userLogoutLoading: state.userLogoutLoading,
    userLogoutError: state.userLogoutError
  }
}
const mapDispatchToProps = dispatch => {
  return {
    logoutUser: (props, history) => dispatch(logoutUser(props, history))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserSidebar);
