import * as ActionTypes from '../ActionTypes/UserActionTypes';
import axios from 'axios';


//USER LOGOUT
export const logoutUserRequest = () => {
    return {
        type: ActionTypes.LOGOUT_USER_REQUEST,
    }
}
export const logoutUserSuccess = () => {
    return {
        type: ActionTypes.LOGOUT_USER_SUCCESS,
    }
}
export const logoutUserFailure = (error) => {
    return {
        type: ActionTypes.LOGOUT_USER_FAILURE,
        payload:error
    }
}
export const logoutUser = (props,history) => {
    console.log("LOGOUT USERR",props,history);
    return (dispatch)=>{
        dispatch(logoutUserRequest())
        axios
      .get("/api/v1/auth/user/logout", { withCredentials: true })
      .then((response) => {
        console.log(response);
        dispatch(logoutUserSuccess());
        props.checkLoginStatus();
        history.push("/");
        // window.location.reload();
      })
      .catch((error) => {
        // console.log(error);
        const errorMsg=error.message;
        dispatch(logoutUserFailure(errorMsg));
      });

    }
}

